export const data = [
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA TAFIRA",
    DOMICILIO: "CARRETERA DEL CENTRO 146",
    PROVINCIA: "LAS PALMAS",
    CP: 35300,
    lat: 28.04476,
    long: -15.47532,
  },
  {
    BANDERA: "PCAN",
    "NOMBRE TPV": "E.S. PCAN LAS CHAFIRAS",
    DOMICILIO: "AVDA. MODESTO HERNANDEZ GONZALEZ, P.I. C",
    PROVINCIA: "TENERIFE",
    CP: 38639,
    lat: 28.048857,
    long: -16.617732,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP OROTIANDA",
    DOMICILIO: "AUTOPISTA DEL SUR TF1, KM. 64,9",
    PROVINCIA: "TENERIFE",
    CP: 38620,
    lat: 28.05203,
    long: -16.636648,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ESTASUR CABO BLANCO",
    DOMICILIO: "CTRA. GRAL. GUAZA-VALLE S. LORENZO S/N",
    PROVINCIA: "TENERIFE",
    CP: 38626,
    lat: 28.0607,
    long: -16.66722,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ATOGO",
    DOMICILIO: "CRTA. ATOGO, S/N",
    PROVINCIA: "TENERIFE",
    CP: 38611,
    lat: 28.06105,
    long: -16.58178,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA SAN ISIDRO",
    DOMICILIO: "AVDA.SANTA CRUZ S/N",
    PROVINCIA: "TENERIFE",
    CP: 38611,
    lat: 28.07818,
    long: -16.55805,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. VALLE SAN LORENZO",
    DOMICILIO: "CTRA. GENERAL VALLE SAN LORENZO, 64",
    PROVINCIA: "TENERIFE",
    CP: 38626,
    lat: 28.091121,
    long: -16.659313,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEPSA V.S.LORENZO",
    DOMICILIO: "CTRA GRAL DEL SUR KM.84",
    PROVINCIA: "TENERIFE",
    CP: 38626,
    lat: 28.09115,
    long: -16.65934,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. TARAHALES I",
    DOMICILIO: "CTRA. DE LOS TARAHALES, KM-4,208",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.09617,
    long: -15.45121,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LA PATERNA",
    DOMICILIO: "CTRA.GENERAL DEL NORTE Nº185",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.09873,
    long: -15.44609,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA SAN MIGUEL",
    DOMICILIO: "CTRA. C-822 P.K. 87,6",
    PROVINCIA: "TENERIFE",
    CP: 38620,
    lat: 28.09962,
    long: -16.61567,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA BALLENA",
    DOMICILIO: "GREGORIO MARAÑON S/N",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.101146,
    long: -15.440609,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "ES DISA MILLER BAJO",
    DOMICILIO: "C/ LUIS CORREA MEDINA, 19",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.10198,
    long: -15.43731,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DRAGON",
    DOMICILIO: "MILLER BAJO S/N",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.10208,
    long: -15.43727,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. LAS REHOYAS",
    DOMICILIO: "CTRA.GENERAL DEL NORTE, 16",
    PROVINCIA: "LAS PALMAS",
    CP: 35013,
    lat: 28.10498,
    long: -15.43287,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. FAÐABE",
    DOMICILIO: "CTRA.GRAL FAÑABE-ADEJE",
    PROVINCIA: "TENERIFE",
    CP: 38670,
    lat: 28.10542,
    long: -16.73199,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "EE.SS. LAS TORRES",
    DOMICILIO: "URB.DIAZ CASANOVA, 2",
    PROVINCIA: "LAS PALMAS",
    CP: 35010,
    lat: 28.11704,
    long: -15.45517,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LOS OLIVOS",
    DOMICILIO: "AVENIDA AYYO,32",
    PROVINCIA: "TENERIFE",
    CP: 38670,
    lat: 28.12365,
    long: -16.73954,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ADEJE",
    DOMICILIO: "CTRA. GRAL. DEL SUR KM.113",
    PROVINCIA: "TENERIFE",
    CP: 38670,
    lat: 28.12496,
    long: -16.74109,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. CHIL",
    DOMICILIO: "PASEO DE CHIL, 303",
    PROVINCIA: "LAS PALMAS",
    CP: 35010,
    lat: 28.13305,
    long: -15.4351,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP PUERTO DE LA LUZ",
    DOMICILIO: "ATINDANA, 2",
    PROVINCIA: "LAS PALMAS",
    CP: 35008,
    lat: 28.15038,
    long: -15.42087,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "ES DISA PUERTO DE LA LUZ",
    DOMICILIO: "AVDA. DE LAS PETROLIFERAS S/N",
    PROVINCIA: "LAS PALMAS",
    CP: 35008,
    lat: 28.15207,
    long: -15.41609,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. SEBADAL",
    DOMICILIO: "C/ DOCTOR JUAN DOMINGUEZ PEREZ, 11",
    PROVINCIA: "LAS PALMAS",
    CP: 35008,
    lat: 28.15358,
    long: -15.41754,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA TIJOCO",
    DOMICILIO: "CTRA. DE ADEJE-GUIA DE ISORA KM 118.2",
    PROVINCIA: "TENERIFE",
    CP: 38670,
    lat: 28.15896,
    long: -16.76293,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA ARICO",
    DOMICILIO: "CRTA.GENERAL DEL SUR KM S/N, ARICO VIEJO",
    PROVINCIA: "TENERIFE",
    CP: 38589,
    lat: 28.182573,
    long: -16.480052,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA PLAYA SAN JUAN",
    DOMICILIO: "PLAZA LA IGLESIA",
    PROVINCIA: "TENERIFE",
    CP: 38687,
    lat: 28.19381,
    long: -16.82227,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL CARACOL",
    DOMICILIO: "CTRA. TARAJALEJO A JANDIA 16",
    PROVINCIA: "LAS PALMAS",
    CP: 35629,
    lat: 28.23173,
    long: -14.10373,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL GUIMAR",
    DOMICILIO: "C/RIGOBERTO DIAZ MELLERO S/N",
    PROVINCIA: "TENERIFE",
    CP: 38500,
    lat: 28.31298,
    long: -16.410989,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA GUIMAR",
    DOMICILIO: "AVDA. SANTA CRUZ 53",
    PROVINCIA: "TENERIFE",
    CP: 38500,
    lat: 28.319123,
    long: -16.408014,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "AREA DE SERVICIO SINDO",
    DOMICILIO: "LAS VICHAS Nº5",
    PROVINCIA: "TENERIFE",
    CP: 38530,
    lat: 28.35682,
    long: -16.37198,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. CALETILLAS",
    DOMICILIO: "AVDA. MARITIMA, Nº198",
    PROVINCIA: "TENERIFE",
    CP: 38530,
    lat: 28.382523,
    long: -16.362073,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEPSA LA OROTOVA",
    DOMICILIO: "C-821, KM-5",
    PROVINCIA: "TENERIFE",
    CP: 38300,
    lat: 28.38827,
    long: -16.51525,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL CALVARIO",
    DOMICILIO: "PLAZA DE LA PAZ, 1",
    PROVINCIA: "TENERIFE",
    CP: 38300,
    lat: 28.39439,
    long: -16.51994,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP EL RAMAL",
    DOMICILIO: "CALLE CARMENATY 14",
    PROVINCIA: "TENERIFE",
    CP: 38300,
    lat: 28.3979,
    long: -16.52351,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL RAMAL",
    DOMICILIO: "C/ EL RAMAL S/N",
    PROVINCIA: "TENERIFE",
    CP: 38300,
    lat: 28.39834,
    long: -16.5223,
  },
  {
    BANDERA: "PCAN",
    "NOMBRE TPV": "PCAN BARRANCO HONDO",
    DOMICILIO: "CTRA. GENERAL DEL SUR PK.9,9",
    PROVINCIA: "TENERIFE",
    CP: 38530,
    lat: 28.399488,
    long: -16.350883,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LA CAMPANA",
    DOMICILIO: "CTRA.GRAL.DEL SUR P.K-10 P.I.SAN ISIDRO",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.41961,
    long: -16.32011,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S.DISA EL CHORRILLO",
    DOMICILIO: "CTRA. GENERAL DEL SUR P.K. 9,2",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.425449,
    long: -16.317023,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA VICTORIA",
    DOMICILIO: "CRTRA. GENERAL DEL NORTE P.K. 27",
    PROVINCIA: "TENERIFE",
    CP: 38380,
    lat: 28.43606,
    long: -16.46669,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S.DISA BARRANCO GRANDE",
    DOMICILIO: "CTRA. GRAL. DEL SUR KM 7,5",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.43852,
    long: -16.30512,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "A LAVADO BARRANCO GRANDE",
    DOMICILIO: "C/ LAS VEREDILLAS Nº2",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.438972,
    long: -16.30485,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP TACO",
    DOMICILIO: "CR C-822 KM 6,9",
    PROVINCIA: "TENERIFE",
    CP: 38206,
    lat: 28.44023,
    long: -16.30357,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL SOBRADILLO",
    DOMICILIO: "AVDA. LOS MAJUELOS, S/N",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.44174,
    long: -16.31365,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S.DISA LAS CHUMBERAS",
    DOMICILIO: "AVDA LA LIBERTAD",
    PROVINCIA: "TENERIFE",
    CP: 38687,
    lat: 28.44183,
    long: -16.31363,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. TACO",
    DOMICILIO: "GENERAL DEL SUR P.K. 6,2",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.44503,
    long: -16.30041,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA TACO",
    DOMICILIO: "CTRA ROSARIO 186 PI TACO SALIDA 4 TF5",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.447066,
    long: -16.291705,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEPSA ANDENES",
    DOMICILIO: "AVDA LOS MAJUELOS, 108",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.44744,
    long: -16.30719,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "CARBURANTES DE TENERIFE",
    DOMICILIO: "CTRA. GENERAL LA CUESTA-TACO S/N",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.44938,
    long: -16.2948,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.AER.DE FUERTEVENTURA",
    DOMICILIO: "CARRETERA AEROPUERTO, S/N",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.451492,
    long: -13.869275,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA LOS MAJUELOS",
    DOMICILIO: "AVDA.LOS MAJUELOS, 26",
    PROVINCIA: "TENERIFE",
    CP: 38108,
    lat: 28.45466,
    long: -16.29667,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA RAVELO",
    DOMICILIO: "CAMINO REAL A LA OROTAVA-RAVELO",
    PROVINCIA: "TENERIFE",
    CP: 38359,
    lat: 28.46107,
    long: -16.41515,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA LAS BANDERAS",
    DOMICILIO: "AUTP. NORTE (MARGEN IZQ) P.K. 22.5",
    PROVINCIA: "TENERIFE",
    CP: 38360,
    lat: 28.464513,
    long: -16.438743,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA VISTABELLA",
    DOMICILIO: "CARRETERA GRAL. S/C LA LAGUNA 382",
    PROVINCIA: "TENERIFE",
    CP: 38320,
    lat: 28.466258,
    long: -16.283489,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. RIO CABRAS",
    DOMICILIO: "CTRA. PTO. ROSARIO-AEROPUERTO KM 3,5",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.46872,
    long: -13.86674,
  },
  {
    BANDERA: "PCAN",
    "NOMBRE TPV": "E.S.LA HIGUERITA, S.L.",
    DOMICILIO: "CTRA.GRAL. STA.CRUZ-LA LAGUNA KM.6",
    PROVINCIA: "TENERIFE",
    CP: 38320,
    lat: 28.47023,
    long: -16.29605,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL SAUZAL",
    DOMICILIO: "CTRA. C-820 P.K. 20,40",
    PROVINCIA: "TENERIFE",
    CP: 38360,
    lat: 28.473843,
    long: -16.421259,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LA PALMESA",
    DOMICILIO: "CRTA LOCAL, KM 1 EL SAUZAL",
    PROVINCIA: "TENERIFE",
    CP: 38360,
    lat: 28.4759,
    long: -16.43488,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP TACORONTE",
    DOMICILIO: "AAUTOPISTA DEL NORTE, 11",
    PROVINCIA: "TENERIFE",
    CP: 38350,
    lat: 28.48064,
    long: -16.39759,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP EL SAUZAL",
    DOMICILIO: "CTRA. GENERAL DEL NORTE KM.20,6",
    PROVINCIA: "TENERIFE",
    CP: 38206,
    lat: 28.48068,
    long: -16.39789,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA TACORONTE",
    DOMICILIO: "CARR. GRAL. DEL NTE., 253",
    PROVINCIA: "TENERIFE",
    CP: 38350,
    lat: 28.48228,
    long: -16.39742,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "EL PEÑON',
    DOMICILIO: "AUTOPISTA TF5 PK6",
    PROVINCIA: "TENERIFE",
    CP: 38350,
    lat: 28.4874,
    long: -16.383,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "MERCASOSA LAS ROTONDAS",
    DOMICILIO: "C/PROFESOR JUANTADEO CABRERA Nº20",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.4975,
    long: -13.86257,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "ES DISA SECUNDINO ALONSO",
    DOMICILIO: "C/ SECUNDINO ALONSO 26",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.498406,
    long: -13.861577,
  },
  {
    BANDERA: "PCAN",
    "NOMBRE TPV": "E.S. GASOLINERA GIL",
    DOMICILIO: "C/ ALMIRANTE CALLERMANO, 31",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.500743,
    long: -13.852416,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA EL CHARCO",
    DOMICILIO: "C/ ALFONSO XIII Nº 65",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.50635,
    long: -13.85054,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. MERCASOSA I",
    DOMICILIO: "CTRA. DE LA OLIVA,KM-2,7",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.51651,
    long: -13.87653,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PTO. DEL ROSARIO",
    DOMICILIO: "CF-600 KM.2",
    PROVINCIA: "LAS PALMAS",
    CP: 35600,
    lat: 28.520693,
    long: -13.846171,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "ES DISA NUEVO AEROPUERTO",
    DOMICILIO: "CTAR. ARRECIFE-AEROPUETO, KM 1,4",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.958282,
    long: -13.571678,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "DISA AEROPUERTO",
    DOMICILIO: "CR.ARRECIFE-AEROPUERTO, KM.1,6",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.958457,
    long: -13.575348,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "DISA ARRECIFE",
    DOMICILIO: "CL BLAS CABRERA ESQ GARCIA ESCA",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.96233,
    long: -13.55247,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL FLICK ARRECIFE",
    DOMICILIO: "CL BLAS CABRERA TOPHAN, 80",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.96453,
    long: -13.55719,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA VALTERRA",
    DOMICILIO: "C/PEREZ GALDOS, ESQ. C/LERIDA",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.9654,
    long: -13.54607,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. GRUPO COSCOFE",
    DOMICILIO: "CTRA. DE LOS MARMOLES, Nº2",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.968291,
    long: -13.54357,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ARRECIFE I",
    DOMICILIO: "C/ LEON Y CASTILLA, Nº194",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.97234,
    long: -13.54609,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. DISA LOS MARMOLES",
    DOMICILIO: "CRA MARMOLES, Nº 138",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.97422,
    long: -13.53445,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA BARAMBIO",
    DOMICILIO: "CTRA. SAN BARTOLOME, 44",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.976026,
    long: -13.570733,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "DISA TAXISTAS LANZAROTE",
    DOMICILIO: "CL IGUAZU S/N",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.97789,
    long: -13.558476,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. CIRCUNVALACIÓN I",
    DOMICILIO: "CTRA. CIRCUNV-GC570/PK370",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.979336,
    long: -13.536276,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S. CIRCUNVALACIÓN II",
    DOMICILIO: "CTRA. CIRCUNV-GC570/PK370",
    PROVINCIA: "LAS PALMAS",
    CP: 35500,
    lat: 28.979342,
    long: -13.536304,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LA CALLE DEL MOTOR",
    DOMICILIO: "AVDA DE LA UNION 21",
    PROVINCIA: "CADIZ",
    CP: 11206,
    lat: 36.113953,
    long: -5.470674,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LOS BARREROS",
    DOMICILIO: "AVDA GESTO POR LA PAZ",
    PROVINCIA: "CADIZ",
    CP: 11206,
    lat: 36.1243,
    long: -5.44912,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. NTRA.SRA.DEL CARMEN",
    DOMICILIO: "CTRA. NACIONAL 340, P.K.106",
    PROVINCIA: "CADIZ",
    CP: 11205,
    lat: 36.147609,
    long: -5.454819,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "VOSERIN SERVICIOS, S.L.",
    DOMICILIO: "CN-340 KM-107,5",
    PROVINCIA: "CADIZ",
    CP: 11205,
    lat: 36.15133,
    long: -5.455,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LA BALLENA ALGECIRAS",
    DOMICILIO: "AVDA. DEL ESTRECHO 7 P.I LA MENACHA",
    PROVINCIA: "CADIZ",
    CP: 11205,
    lat: 36.15997,
    long: -5.465117,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP AVDA. CARTAGENA",
    DOMICILIO: "AVDA. CARTAGENA ESQ. CAMINO TORRENUEVA",
    PROVINCIA: "CADIZ",
    CP: 11300,
    lat: 36.17649,
    long: -5.339075,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL LINEA CONCEPCI",
    DOMICILIO: "RONDA NORTE, 2",
    PROVINCIA: "CADIZ",
    CP: 11300,
    lat: 36.17709,
    long: -5.349075,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S EL HIGUERON, S.L.",
    DOMICILIO: "POL. IND. ZABAL BAJO, KM 6,3",
    PROVINCIA: "CADIZ",
    CP: 11300,
    lat: 36.186572,
    long: -5.349746,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA BARBATE",
    DOMICILIO: "PLAZA REYES CATOLICOS Nº 3",
    PROVINCIA: "CADIZ",
    CP: 11160,
    lat: 36.195149,
    long: -5.917562,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.NTRA.SRA.DE LA OLIVA",
    DOMICILIO: "CAP-2141,VEJER FRTERA-CAÑOS DE MECA,KM71",
    PROVINCIA: "CADIZ",
    CP: 11150,
    lat: 36.2222,
    long: -6.04105,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. GALP VEJER",
    DOMICILIO: "CN-340, KM-30,277",
    PROVINCIA: "CADIZ",
    CP: 11150,
    lat: 36.2534,
    long: -6.02541,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CONIL",
    DOMICILIO: "C/ GONZALO SANCHEZ  FUENTES 1",
    PROVINCIA: "CADIZ",
    CP: 11140,
    lat: 36.281199,
    long: -6.089939,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "MONTILLA',
    DOMICILIO: "CN-340 KM.134",
    PROVINCIA: "CADIZ",
    CP: 11312,
    lat: 36.29675,
    long: -5.28163,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "CONIL 001 SENAL",
    DOMICILIO: "AUTOVIA A-48 PK 24,400",
    PROVINCIA: "CADIZ",
    CP: 11140,
    lat: 36.310197,
    long: -6.056975,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "CAMPSARED 5411 CABO ROCH",
    DOMICILIO: "CR. N-340  KM 16.7",
    PROVINCIA: "CADIZ",
    CP: 11140,
    lat: 36.33987,
    long: -6.093641,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ES SANTA ANA DE LA JANDA",
    DOMICILIO: "CTRA A-2225 KM 11",
    PROVINCIA: "CADIZ",
    CP: 11190,
    lat: 36.348419,
    long: -5.81609,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. GALAZAVA S.L.",
    DOMICILIO: "POL. IND. ZAHORI-64",
    PROVINCIA: "CADIZ",
    CP: 11190,
    lat: 36.3492,
    long: -5.82029,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA"LAS GALERAS',
    DOMICILIO: "N-IVA , KM 654",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.353779,
    long: -6.132266,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP EL FLORIN",
    DOMICILIO: "CRTA. FUENTEAMARGA 151",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.394136,
    long: -6.146653,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "CAMPSARED 31076 LA BARRO",
    DOMICILIO: "AVDA REYES CATOLICOS S/N",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.4154,
    long: -6.143,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP PUENTE LA BANDA",
    DOMICILIO: "CTRA MEDINA 71",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.416173,
    long: -6.133771,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL GUADALOBON",
    DOMICILIO: "AVDA. DEL CARMEN, S/N",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.41724,
    long: -5.17592,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S GALP ESTEPONA CENTRO",
    DOMICILIO: "C/ DOCTOR CRISTOBAL RUIZ MENDEZ, Nº75",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.423239,
    long: -5.15566,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP URBISUR",
    DOMICILIO: "AV LOS DESCUBRIMIENTOS 1-6",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.425403,
    long: -6.152792,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "GASOLINERAS CLC",
    DOMICILIO: "SEVERO OCHOA, 24",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.42667,
    long: -6.15416,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA LAS BODEGAS",
    DOMICILIO: "C/ALAMEDA SOLANO, 18",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.426833,
    long: -6.143894,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP JUAN CARLOS I",
    DOMICILIO: "AVD JUAN CARLOS I 139",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.42826,
    long: -5.15655,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES BP LA BAHIA",
    DOMICILIO: "PG INDUSTRIAL EL TORNO II PAC 9",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.429367,
    long: -6.14353,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LOS REALES",
    DOMICILIO: "AV LITORAL S/N",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.42943,
    long: -5.12957,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL EL MARQUESADO",
    DOMICILIO: "CTRA. N-340, P.K. 5,0",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.43036,
    long: -6.14251,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ESTEPONA",
    DOMICILIO: "AVDA.JOSE MARTIN MENDEZ, S/N POL.IND.",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.43164,
    long: -5.17124,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP RIO PADRON",
    DOMICILIO: "UENC11 COMERCIAL EL PADRON PK 159,",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.4378,
    long: -5.10687,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.P. DE LOS FRANCESE",
    DOMICILIO: "AVDA. DEL MUEBLE, 1",
    PROVINCIA: "CADIZ",
    CP: 11130,
    lat: 36.441436,
    long: -6.143329,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ESTEPONA PLAYA",
    DOMICILIO: "CTRA. N-340 (CADIZ-BARCELONA), KM. 163",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.45319,
    long: -5.07524,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CALLE REAL",
    DOMICILIO: "CALLE REAL 228",
    PROVINCIA: "CADIZ",
    CP: 11100,
    lat: 36.4566,
    long: -6.20845,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MEDINA SIDONIA",
    DOMICILIO: "PLAZA DEL PUNTO S/N",
    PROVINCIA: "CADIZ",
    CP: 11170,
    lat: 36.460882,
    long: -5.929456,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EL SALADILLO",
    DOMICILIO: "CN-340 KM.166 (URB.EL SALADILLO)",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.46219,
    long: -5.0421,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ESTEPONA",
    DOMICILIO: "CN-340 (CADIZ -BARCELONA) KM.173",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.46363,
    long: -5.03876,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RIO CASTOR I",
    DOMICILIO: "AUTOPISTA A-7 KM.161 (MARGEN IZQ)",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.46429,
    long: -5.11636,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RIO CASTOR II",
    DOMICILIO: "AUTOPISTA A-7 KM.161",
    PROVINCIA: "MALAGA",
    CP: 29680,
    lat: 36.46483,
    long: -5.11502,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "N.S.DE LA PALMA',
    DOMICILIO: "AVDA.PUENTE ZUAZO S/N",
    PROVINCIA: "CADIZ",
    CP: 11100,
    lat: 36.46849,
    long: -6.1894,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA LA ARDILA",
    DOMICILIO: "N-IV A, PK 680",
    PROVINCIA: "CADIZ",
    CP: 11100,
    lat: 36.468575,
    long: -6.189326,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. TRES CAMINOS",
    DOMICILIO: "POL.IND.TRES CAMINOS-AVDA.MOJARRA, Nº5",
    PROVINCIA: "CADIZ",
    CP: 11510,
    lat: 36.46932,
    long: -6.17413,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROL AND GO 3 CAMINOS",
    DOMICILIO: "POL.IND.TRES CAMINOS C/ DE LA MOJARRA,16",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.469435,
    long: -6.173514,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. PRADO DE LA FERIA",
    DOMICILIO: "PRADO DE LA FERIA C/AUSTRIA, 1",
    PROVINCIA: "CADIZ",
    CP: 11170,
    lat: 36.47061,
    long: -5.943915,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP SAN FERNANDO",
    DOMICILIO: "AV  PERY JUNQUERA S/N",
    PROVINCIA: "CADIZ",
    CP: 11100,
    lat: 36.471839,
    long: -6.203252,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "ASA S.PEDRO',
    DOMICILIO: "CN-340 KM.177,7",
    PROVINCIA: "MALAGA",
    CP: 29670,
    lat: 36.47979,
    long: -4.99268,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL CABO PINO",
    DOMICILIO: "A-7, KM-194 OESTE",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.48817,
    long: -4.7415,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PUERTO REAL II",
    DOMICILIO: "CN-IV KM.668,600",
    PROVINCIA: "CADIZ",
    CP: 11510,
    lat: 36.48867,
    long: -6.14337,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "LAS MEDRANAS",
    DOMICILIO: "URB LAS MEDRANAS C/ DEL P.A.S.P, 8 S/N",
    PROVINCIA: "MALAGA",
    CP: 29670,
    lat: 36.49397,
    long: 4.99308,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. ARCO DE SAN PEDRO",
    DOMICILIO: "C/HELSINKI S/N PARCELA 40",
    PROVINCIA: "MALAGA",
    CP: 29670,
    lat: 36.49511,
    long: -4.992377,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S ARCO DE MARBELLA",
    DOMICILIO: "N-340A PL 182,500(JUAN DE LA CIERVA S/N)",
    PROVINCIA: "MALAGA",
    CP: 29603,
    lat: 36.50867,
    long: -4.864772,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. NAGUELES",
    DOMICILIO: "CN-340 KM.179",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.50918,
    long: -4.91201,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "ASA MARBELLA',
    DOMICILIO: "CN-340 KM.188,8",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.50928,
    long: -4.87383,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S MARBELLA",
    DOMICILIO: "CR N-340 KM 178,87",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.509396,
    long: -4.908786,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ARCO MARBELLA",
    DOMICILIO: "CN-340 KM.182,5",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.50945,
    long: -4.86412,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S.EL PASO GALP LA CALA",
    DOMICILIO: "FINCA EL PASO",
    PROVINCIA: "MALAGA",
    CP: 29649,
    lat: 36.509484,
    long: -4.687376,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. SIEBLA 2",
    DOMICILIO: "C/ALUMINIO, S/N",
    PROVINCIA: "MALAGA",
    CP: 29603,
    lat: 36.51361,
    long: -4.87097,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ALTOS DE MARBELLA",
    DOMICILIO: "AUTOPISTA A-7 KM.187",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.519323,
    long: -4.831021,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "ALTOS DE MARBELLA II",
    DOMICILIO: "AUTOPISTA A-7 KM.187",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.519372,
    long: -4.830986,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TRAPICHE",
    DOMICILIO: "AVDA. TRAPICHE (JUNTO EST. AUTOBUSES)",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.51995,
    long: -4.89187,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL CASTILLO, S.A.",
    DOMICILIO: "CN-340 KM.209",
    PROVINCIA: "MALAGA",
    CP: 29640,
    lat: 36.52024,
    long: -4.63042,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP OJEN",
    DOMICILIO: "CR MARBELLA-OJEN.  KM 1",
    PROVINCIA: "MALAGA",
    CP: 29600,
    lat: 36.52174,
    long: -4.88,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CUATRO OLIVOS, S.L.",
    DOMICILIO: "CTRA. MEDINA KM.3",
    PROVINCIA: "CADIZ",
    CP: 11510,
    lat: 36.52607,
    long: -6.14761,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "LA ESTRELLA DE SEVILLA",
    DOMICILIO: "C/CARRETONES, S/N",
    PROVINCIA: "CADIZ",
    CP: 11510,
    lat: 36.53142,
    long: -6.1946,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP EL TEJAR",
    DOMICILIO: "AVDA ALCALDE CLEMENTE DIAZ RUIZ S/N",
    PROVINCIA: "MALAGA",
    CP: 29640,
    lat: 36.54825,
    long: -4.62638,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA CAÑADA",
    DOMICILIO: "CTRA. DE MIJAS KM.6",
    PROVINCIA: "MALAGA",
    CP: 29640,
    lat: 36.552168,
    long: -4.627728,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 FUENGIROLA",
    DOMICILIO: "CTRA. MIJAS - FUENGIROLA esq. C/ VIRIATO",
    PROVINCIA: "MALAGA",
    CP: 29014,
    lat: 36.556574,
    long: -4.629311,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CENTRO",
    DOMICILIO: "AVD LAS GAVIOTAS S/N",
    PROVINCIA: "MALAGA",
    CP: 29640,
    lat: 36.55769,
    long: -4.61131,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "ES SHELL CABALLO BLANCO",
    DOMICILIO: "N-IV, P.K. 654,7",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.5837,
    long: -6.21715,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL PTO STA MARIA",
    DOMICILIO: "P.I.LA ISLETA S/N N-IV KM 654 PK 654",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.586648,
    long: -6.219356,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LONJA GASOLINERA",
    DOMICILIO: "CRTA. N IV MUELLE PESQUERO S/N",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.5949,
    long: -6.2238,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PTO.STA.MARIA",
    DOMICILIO: "AVDA.FUENTEBRAVIA, S/N",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.59896,
    long: -6.2402,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VISTAHERMOSA",
    DOMICILIO: "AVDA.FUENTEBRAVIA, S/N",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.59939,
    long: -6.24029,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROMARKT COSTA SOL",
    DOMICILIO: "C/ LA SIDRA, 4",
    PROVINCIA: "MALAGA",
    CP: 29631,
    lat: 36.606378,
    long: -4.533133,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP MIRAMAR BENALMAD",
    DOMICILIO: "AV. DEL COSMOS ESQ.C/ARROYO DE LA MIEL",
    PROVINCIA: "MALAGA",
    CP: 29631,
    lat: 36.60752,
    long: -4.54002,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ARANCIA",
    DOMICILIO: "CA-602 KM.2,05",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.60828,
    long: -6.23611,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "GASOLINERAS CLC",
    DOMICILIO: "C ALFRED NOBEL 9",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.60836,
    long: -6.20437,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S BP LA VICTORIA",
    DOMICILIO: "CR N IV KM 651.",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.6089,
    long: -6.21676,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LAS SALINAS",
    DOMICILIO: "ALBERT EINSTEIN (POLIG. LAS SALI 16",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.610766,
    long: -6.195817,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP RIO SAN PEDRO",
    DOMICILIO: "AVDA PORTUGAL P.I EL TROCADERO",
    PROVINCIA: "CADIZ",
    CP: 11510,
    lat: 36.610766,
    long: -6.195817,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SHELL SALINAS",
    DOMICILIO: "AVDA. INGENIERO FELIX SANCHO, 19",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.61302,
    long: -6.19591,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 PTO. SANTA MARIA",
    DOMICILIO: "C/ EL PALMAR, P.I. EL PALMAR, PARCELA 3",
    PROVINCIA: "CADIZ",
    CP: 11500,
    lat: 36.61531,
    long: -6.21081,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA VENTILLA",
    DOMICILIO: "CTRA A-387 KM.4",
    PROVINCIA: "MALAGA",
    CP: 29120,
    lat: 36.61922,
    long: -4.70727,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA PEÑITA 1",
    DOMICILIO: "CAMINO DE MALAGA",
    PROVINCIA: "MALAGA",
    CP: 29120,
    lat: 36.64693,
    long: -4.67646,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LA PEÑITA 2",
    DOMICILIO: "CTRA. DE CARTAMA KM.1",
    PROVINCIA: "MALAGA",
    CP: 29120,
    lat: 36.65286,
    long: -4.67896,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "PLAZA MAYOR",
    DOMICILIO: "AV.ALFONSO PONCE DE LEÓN, 3-C.O.PL.MAYOR",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.65455,
    long: -4.47985,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.ALHAURIN, S.L.",
    DOMICILIO: "CC-344 KM.83",
    PROVINCIA: "MALAGA",
    CP: 29130,
    lat: 36.65902,
    long: -4.57076,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL COIN",
    DOMICILIO: "AVDA. REINA SOFIA 48",
    PROVINCIA: "MALAGA",
    CP: 29100,
    lat: 36.6628,
    long: -4.75068,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. ALHAURÍN",
    DOMICILIO: "MA-307 KM.2",
    PROVINCIA: "MALAGA",
    CP: 29130,
    lat: 36.66844,
    long: -4.548,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MONTEALEGRE",
    DOMICILIO: "CC-440 KM.2",
    PROVINCIA: "CADIZ",
    CP: 11406,
    lat: 36.67122,
    long: -6.12188,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S LAS DELICIAS",
    DOMICILIO: "PASEO DE LAS DELICIAS Nº1",
    PROVINCIA: "CADIZ",
    CP: 11406,
    lat: 36.681085,
    long: -6.125239,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA AZUCARERA",
    DOMICILIO: "AVDA. DE VELAZQUEZ Nº 208",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.68234,
    long: -4.4683,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "AZUCARERA",
    DOMICILIO: "AVDA.VELAZQUEZ ESQ.BENAQUE-PI.VELAZQUEZ-",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.68399,
    long: -4.46668,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SOLGALOPE",
    DOMICILIO: "CTRA. N-IV, KM. 639",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.684355,
    long: -6.153191,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. VULCANO",
    DOMICILIO: "AV. DE LA UNIVERSIDAD, S/N",
    PROVINCIA: "CADIZ",
    CP: 11405,
    lat: 36.6864,
    long: -6.1178,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "GALP GUADAHORCE",
    DOMICILIO: "C/ HERMAN HESSE ESQ. GERALD BRENAN",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.687987,
    long: -4.471055,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA MALAGA",
    DOMICILIO: "AVD. VELAZQUEZ 113",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.68835,
    long: -4.45962,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.JEREZ",
    DOMICILIO: "C/ MARTIN FERRADOR S/N",
    PROVINCIA: "CADIZ",
    CP: 11405,
    lat: 36.69011,
    long: -6.12745,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA LUZ",
    DOMICILIO: "AVENIDA DE VELAZQUEZ, Nº104",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.69146,
    long: -4.45678,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA VALDICIO",
    DOMICILIO: "ISAAC PERAL, 39",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.69251,
    long: -4.45926,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S SHELL GUADALHORCE",
    DOMICILIO: "C/ E.SALAZAR CHAPELA,18 ESQ.C/C. ARENAL",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.692524,
    long: -4.480055,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. GUADALHORCE",
    DOMICILIO: "ESTEBAN SALAZAR CHAPELA ESQ. CONCEPCION",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.69371,
    long: -4.47754,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA LA MARQUESA",
    DOMICILIO: "AVDA DE ARCOS, S/N ESQUINA C/ LAGUNA",
    PROVINCIA: "CADIZ",
    CP: 11406,
    lat: 36.694032,
    long: -6.100554,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. INTELHORCE",
    DOMICILIO: "GTA. CONCEPCION ARENAL S/N(ESQ.CTRA.AZU)",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 36.695432,
    long: -4.494122,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "LOS PRADOS ( MAYORAL )",
    DOMICILIO: "C/ DE LOS PRADOS ESQ. C/ OROTAVA",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.69943,
    long: -4.46806,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CAULINA",
    DOMICILIO: "COLONIA CAULINA SN",
    PROVINCIA: "CADIZ",
    CP: 11405,
    lat: 36.702188,
    long: -6.084894,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TARAJAL",
    DOMICILIO: "AVDA. JOSE ORTEGA Y CASSET Nº154",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.70598,
    long: -4.50548,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "COMERCIAL AVENIDA",
    DOMICILIO: "AVDA ANDALUCÍA 105 GASOLINERA",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.70607,
    long: -6.11704,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S JEREZ ALTO I IZQ",
    DOMICILIO: "C/ PRESIDENTE ADOLFO SUAREZ N 3 Y 4",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.706684,
    long: -6.125013,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. EL VISO",
    DOMICILIO: "AVDA. ORTEGA Y GASSET Nº234",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.707066,
    long: -4.471996,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S JEREZ ALTO II",
    DOMICILIO: "C/ PRESIDENTE ADOLFO SUAREZ N 3 Y 4",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.70708,
    long: -6.125051,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "SUPERMERCADO COVIRAN GUA",
    DOMICILIO: "CTRA. AZUCARERA-INTELHORCE, ESQ. C/RUSIA",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.707434,
    long: -4.493061,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. POLIGONO ALAMEDA",
    DOMICILIO: "C/ ROSAMUNDA",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.70977,
    long: -4.4719,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. UNIVERSIDAD",
    DOMICILIO: "C/ MARISMA 48",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.713048,
    long: -4.472675,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S EL CONSUL",
    DOMICILIO: "C/DEMOSTENES S/N",
    PROVINCIA: "MALAGA",
    CP: 29010,
    lat: 36.718491,
    long: -4.484405,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CARTAMA",
    DOMICILIO: "AUTOVIA DEL GUADALHORCE SALIDA 57",
    PROVINCIA: "MALAGA",
    CP: 29570,
    lat: 36.72017,
    long: -4.59204,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. RIO GRANDE",
    DOMICILIO: "CTRA. CAMPILLOS-MALAGA KM.47 (A-357)",
    PROVINCIA: "MALAGA",
    CP: 29570,
    lat: 36.72031,
    long: -4.701533,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "BAHIA DE ALMERIMAR S.L.",
    DOMICILIO: "CTRA. ALMERIMAR PARC. 407",
    PROVINCIA: "ALMERIA",
    CP: "04700",
    lat: 36.72068,
    long: -2.80002,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LAS CHAPAS",
    DOMICILIO: "CL MARTINEZ MALDONADO 62",
    PROVINCIA: "MALAGA",
    CP: 29010,
    lat: 36.72167,
    long: -4.43934,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CAÑADA ANCHA",
    DOMICILIO: "CN-IV KM.632",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.72696,
    long: -6.09533,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CAMINO SUAREZ",
    DOMICILIO: "AV VALLE INCLAN S/N",
    PROVINCIA: "MALAGA",
    CP: 29010,
    lat: 36.72962,
    long: -4.44503,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "SAN FRANCISCO',
    DOMICILIO: "C/ MARBELLA, 31",
    PROVINCIA: "MALAGA",
    CP: 29400,
    lat: 36.73435,
    long: -5.16062,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. EL PEÑASCO",
    DOMICILIO: "AVDA.VALLE INCLAN, S/N",
    PROVINCIA: "MALAGA",
    CP: 29011,
    lat: 36.73622,
    long: -4.43447,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SAN ELOY, S.L.",
    DOMICILIO: "AVDA DE LA DIPUTACION",
    PROVINCIA: "CADIZ",
    CP: 11550,
    lat: 36.73882,
    long: -6.43284,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. OJEVAL, S.L.",
    DOMICILIO: "CTRA.FARO SABINAL S/N",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.74144,
    long: -2.61701,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CIUDAD JARDIN",
    DOMICILIO: "AV RAMON Y CAJAL 102",
    PROVINCIA: "MALAGA",
    CP: 29014,
    lat: 36.74335,
    long: 4.42245,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "EASY GAS VELEZ",
    DOMICILIO: "AUTOVIA DEL MEDITERRANEO SALIDA 265",
    PROVINCIA: "MALAGA",
    CP: 29792,
    lat: 36.743493,
    long: -4.180063,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'ES.CEDIPSA "S.CRISTOBAL',
    DOMICILIO: "CC-341 KM.57,4",
    PROVINCIA: "MALAGA",
    CP: 29400,
    lat: 36.74774,
    long: -5.1563,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES ROQUETAS DE MAR",
    DOMICILIO: "PARAJE ALGARROBO,CARRETERA A-1051 P.K.10",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.749347,
    long: -2.637272,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "EL TAJO',
    DOMICILIO: "CC-341 KM.114,8",
    PROVINCIA: "MALAGA",
    CP: 29400,
    lat: 36.75249,
    long: -5.16997,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL RONDA",
    DOMICILIO: "C/ GENERAL Nº 31",
    PROVINCIA: "MALAGA",
    CP: 29400,
    lat: 36.753,
    long: -5.1561,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CIUDAD JARDIN",
    DOMICILIO: "AVDA. JACINTO BENAVENTE S/N",
    PROVINCIA: "MALAGA",
    CP: 29014,
    lat: 36.753527,
    long: -4.425677,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SAN PABLO",
    DOMICILIO: "CTRA. N-IV KM 537,6 (M.IZD)",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 36.753527,
    long: -5.956168,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LAS NORIAS, S.L.",
    DOMICILIO: "CTRA. LA MOJONERA, Nº155",
    PROVINCIA: "ALMERIA",
    CP: "04716",
    lat: 36.75752,
    long: -2.74827,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "BDMED CIM EL EJIDO",
    DOMICILIO: "C/ JUAN DE LA CIERVA, 1",
    PROVINCIA: "ALMERIA",
    CP: "04700",
    lat: 36.758154,
    long: -2.800344,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA VOLTASUR",
    DOMICILIO: "CTRA. A-376 KM. 120,7",
    PROVINCIA: "MALAGA",
    CP: 29400,
    lat: 36.759394,
    long: -5.16256,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SAN ANTONIO",
    DOMICILIO: "CARRTERA LA MOJONERA Nº 384",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.75945,
    long: -2.64564,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VELEZ MALAGA",
    DOMICILIO: "ROTONDA DE LA MATA S/N",
    PROVINCIA: "MALAGA",
    CP: 29740,
    lat: 36.76079,
    long: -4.1049,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "HROS.SALVADOR REINA, S.L",
    DOMICILIO: "CC-337 KM.28,9",
    PROVINCIA: "MALAGA",
    CP: 29560,
    lat: 36.76386,
    long: -4.71036,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "AREA SUR",
    DOMICILIO: "AUTOVIA A7, PK. 391, S/N",
    PROVINCIA: "ALMERIA",
    CP: "04770",
    lat: 36.76542,
    long: -2.98546,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EJIDO OESTE",
    DOMICILIO: "C/ LA PARRA S/N",
    PROVINCIA: "ALMERIA",
    CP: "04700",
    lat: 36.76816,
    long: -2.82862,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LA TANA",
    DOMICILIO: "CTRA. SAN JOSE 107",
    PROVINCIA: "MALAGA",
    CP: 29014,
    lat: 36.76857,
    long: -4.42433,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. OJEVAL, S.L.",
    DOMICILIO: "CTRA. DE ALICUN, 1",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.77035,
    long: -2.61293,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. COSTA EJIDO",
    DOMICILIO: "CTRA. MALAGA 74",
    PROVINCIA: "ALMERIA",
    CP: "04700",
    lat: 36.77103,
    long: -2.82617,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LOS REMEDIOS",
    DOMICILIO: "AVDA. VIVAR TELLEZ,Nº26",
    PROVINCIA: "MALAGA",
    CP: 29700,
    lat: 36.776402,
    long: -4.100375,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "S.S. LA INMACULADA",
    DOMICILIO: "CTRA. A 335 KM. 1,300",
    PROVINCIA: "MALAGA",
    CP: 29700,
    lat: 36.7787,
    long: -4.112458,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP AP-4 CUADRAJON 2",
    DOMICILIO: "CARRETERA AP-4, PK 69,500",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.781079,
    long: -6.042888,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP AP-4 CUADREJON 1",
    DOMICILIO: "CARRETERA AP-4, PK 69,500",
    PROVINCIA: "CADIZ",
    CP: 11407,
    lat: 36.781583,
    long: -6.0444,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. SAN SEBASTIAN",
    DOMICILIO: "C/ LAUREANO CASQUERO, S/N",
    PROVINCIA: "MALAGA",
    CP: 29700,
    lat: 36.782782,
    long: -4.109952,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SAN NICOLAS ME",
    DOMICILIO: "CN-340 KM.417,800",
    PROVINCIA: "ALMERIA",
    CP: "04738",
    lat: 36.79076,
    long: -2.7064,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. OJEVAL, S.L.",
    DOMICILIO: "CTRA.DE ALICUN KM.5,5",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.79862,
    long: -2.60114,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.OJEVAL, S.L.",
    DOMICILIO: "CTRA.DE ALICUN KM.5,5",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.79878,
    long: -2.6021,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VIA PARK",
    DOMICILIO: "LG PARQUE COM VIAPARK PARC. 4",
    PROVINCIA: "ALMERIA",
    CP: "04738",
    lat: 36.816459,
    long: -2.600214,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ROQUETAS",
    DOMICILIO: "SANTIAGO DE SANTIAGO Y CHILLIDA P-3 S-40",
    PROVINCIA: "ALMERIA",
    CP: "04740",
    lat: 36.819176,
    long: -2.597457,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA MARTIN",
    DOMICILIO: "AVDA.CABO DE GATA, 4",
    PROVINCIA: "ALMERIA",
    CP: "04007",
    lat: 36.83257,
    long: -2.45998,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. AVDA.MEDITERRANEO",
    DOMICILIO: "C/ ALPUJARRA (ESQ.AVDA. MEDITERRANEO)",
    PROVINCIA: "ALMERIA",
    CP: "04007",
    lat: 36.834998,
    long: -2.44736,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.ALMERIA",
    DOMICILIO: "PADRE MENDEZ S/N",
    PROVINCIA: "ALMERIA",
    CP: "04006",
    lat: 36.83949,
    long: -2.44695,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "OIL ES GALEX SL",
    DOMICILIO: "CARRETERA SIERRA ALHAMILLA S/N",
    PROVINCIA: "ALMERIA",
    CP: "04007",
    lat: 36.84117,
    long: -2.43958,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S LA TORRE",
    DOMICILIO: "CARRETERA DE LA SIERRA ALHAMILLA,310",
    PROVINCIA: "ALMERIA",
    CP: "04007",
    lat: 36.841989,
    long: -2.439112,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. AGLA-EL CUERVO",
    DOMICILIO: "CTRA. NACIONAL IV, KM 613.7",
    PROVINCIA: "SEVILLA",
    CP: 41749,
    lat: 36.86725,
    long: -6.02977,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "TRANSPORTES GARRUCHOSA",
    DOMICILIO: "CTRA. 343 KM. 21 UTRERA BARBATE",
    PROVINCIA: "CADIZ",
    CP: 11648,
    lat: 36.86874,
    long: -5.80612,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. HUERCAL",
    DOMICILIO: "CN-340 KM.446",
    PROVINCIA: "ALMERIA",
    CP: "04230",
    lat: 36.87492,
    long: -2.44477,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP TEATINOS",
    DOMICILIO: "AVDA MARIA ZAMBRANO/ALFR.C",
    PROVINCIA: "MALAGA",
    CP: 29006,
    lat: 36.87842,
    long: -2.42317,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "GESPETROMED SHELL JUAIDA",
    DOMICILIO: "C/SIERRA CONTROVERSIA ESQ SIERRA CAZORLA",
    PROVINCIA: "ALMERIA",
    CP: "04240",
    lat: 36.883392,
    long: -2.420805,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "REPSOL ATENOIL VIATOR",
    DOMICILIO: "C/ SIERRA DE CAZORLA, 23",
    PROVINCIA: "ALMERIA",
    CP: "04240",
    lat: 36.88474,
    long: -2.41898,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "COSTA ANDARAX",
    DOMICILIO: "PARQUE COMERCIAL LOS NARANJOS. SECTOR SL",
    PROVINCIA: "ALMERIA",
    CP: "04230",
    lat: 36.898922,
    long: -2.445186,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "ES HUERCAL",
    DOMICILIO: "POLIGONO DE LOS NARANJOS SECTOR 4",
    PROVINCIA: "ALMERIA",
    CP: "04230",
    lat: 36.89894,
    long: -2.44251,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.ALPUJARRA-ORGIVA",
    DOMICILIO: "CC-333 KM.16,145",
    PROVINCIA: "GRANADA",
    CP: 18400,
    lat: 36.90134,
    long: -3.42963,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP SAN JAVIER",
    DOMICILIO: "AUTOVIA DE MALAGA A44",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 36.92418,
    long: -4.44846,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "REPSOL ATENOIL  LEBRIJA",
    DOMICILIO: "AVDA. ANTONIO CALVO RUIZ",
    PROVINCIA: "SEVILLA",
    CP: 41740,
    lat: 36.9274,
    long: -6.07111,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP ATENOIL CAMPOHERMOSO",
    DOMICILIO: "AVDA PRINCIPE DE ASTURIAS, 1",
    PROVINCIA: "ALMERIA",
    CP: "04110",
    lat: 36.940495,
    long: -2.138671,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LAS PEDRIZAS M.D.",
    DOMICILIO: "AUTOVIA AP-46, KM. 3.300",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 36.980688,
    long: -4.455762,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LAS PEDRIZAS IZQ.",
    DOMICILIO: "AUTOPISTA AP-46, KM. 3,300",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 36.986838,
    long: -4.464302,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. INVERSIONES LAROLES",
    DOMICILIO: "CTRA. PUERTO KM 9,200",
    PROVINCIA: "GRANADA",
    CP: 18194,
    lat: 37.0084,
    long: -3.01113,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "LA ALAMEDA",
    DOMICILIO: "ALAMEDA DE ANDALUCIA, 42",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.021839,
    long: -4.565366,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.SUC.DIAZ INIGUEZ,S.L",
    DOMICILIO: "ALAMEDA, 42",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.02185,
    long: -4.56542,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "POLIGONO ANTEQUERA",
    DOMICILIO: "C/ELFEBO 1 (POLIGONO INDUSTRIAL)",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.02995,
    long: -4.53467,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ANTEQUERA",
    DOMICILIO: "PARQUE EMPRESARIAL CRUCE DEL ROMERA",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.03435,
    long: -4.52807,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ANTEQUERA",
    DOMICILIO: "CN-331 KM.124,420",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.0449,
    long: -4.51214,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL CANAL",
    DOMICILIO: "CC. A-383 KM.185",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 37.05181,
    long: -4.65802,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. SAN RAFAEL",
    DOMICILIO: "CARRETERA JEREZ-CARTAGENA KM. 136",
    PROVINCIA: "MALAGA",
    CP: 29320,
    lat: 37.052049,
    long: -4.8597,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. ALHENDIN - OTURA",
    DOMICILIO: "AVDA. DE ANDALUCIA, 31",
    PROVINCIA: "GRANADA",
    CP: 18620,
    lat: 37.10349,
    long: -3.67349,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ALHENDIN",
    DOMICILIO: "AVDA. ANDALUCIA S/N",
    PROVINCIA: "GRANADA",
    CP: 18620,
    lat: 37.10429,
    long: -3.64011,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL FONTANAL, S.L.",
    DOMICILIO: "CTRA.MORON-SEVILLA KM.1,200",
    PROVINCIA: "SEVILLA",
    CP: 41530,
    lat: 37.12037,
    long: -5.48451,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. GALP MORON DLF",
    DOMICILIO: "C/ DE LA TRILLA, Nº1",
    PROVINCIA: "SEVILLA",
    CP: 41530,
    lat: 37.12704,
    long: -5.46027,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.REYES HITOS HERMANOS",
    DOMICILIO: "CR. CAJAR-MONACHIL, PK. 0,80",
    PROVINCIA: "GRANADA",
    CP: 18198,
    lat: 37.14477,
    long: -3.57858,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ASC ARMILLA 2",
    DOMICILIO: "AVDA FERNANDO DE LOS RIOS 41",
    PROVINCIA: "GRANADA",
    CP: 18100,
    lat: 37.149056,
    long: -3.618584,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CRISTOBAL COLON",
    DOMICILIO: "AVDA. CRISTOBAL COLON, 72",
    PROVINCIA: "GRANADA",
    CP: 18194,
    lat: 37.15096,
    long: -3.64413,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "ARMILLA",
    DOMICILIO: "CTRA. GRANADA-ARMILLA KM.1,8",
    PROVINCIA: "GRANADA",
    CP: 18006,
    lat: 37.15149,
    long: -3.61469,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ASC S. CRISTOBAL ARMILLA",
    DOMICILIO: "CAMINO BAJO ESQUINA C/VENECIA",
    PROVINCIA: "GRANADA",
    CP: 18100,
    lat: 37.15205,
    long: -3.621712,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA ARMILLA",
    DOMICILIO: "CMNO.VIEJO ARMILLA,ESQ. AV.SAN RAFAEL SN",
    PROVINCIA: "GRANADA",
    CP: 18100,
    lat: 37.153287,
    long: -3.621188,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "GALP NEVADA EL TORREON",
    DOMICILIO: "CARRETERA GRANADA ARMILLA KM 134.25",
    PROVINCIA: "GRANADA",
    CP: 18100,
    lat: 37.15411,
    long: -3.61195,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 ARMILLA",
    DOMICILIO: "AVDA. FERNANDO DE LOS RÍOS, 60",
    PROVINCIA: "GRANADA",
    CP: 18006,
    lat: 37.154357,
    long: -3.611147,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SHELL VEGAS DEL GENIL",
    DOMICILIO: "CTRA GRANADA-LAS GABIAS, KM.2,9",
    PROVINCIA: "GRANADA",
    CP: 18194,
    lat: 37.157815,
    long: -3.641597,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA EMILIO",
    DOMICILIO: "CTRA. A-362, PK.4,2",
    PROVINCIA: "SEVILLA",
    CP: 41710,
    lat: 37.178953,
    long: -5.849781,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.MULTIPETROLEOS, S.L.",
    DOMICILIO: "AVDA. DE MADRID, 19",
    PROVINCIA: "GRANADA",
    CP: 18014,
    lat: 37.18993,
    long: -3.60638,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP AUTOPISTA",
    DOMICILIO: "ADA. ANDALUCIA, 46",
    PROVINCIA: "GRANADA",
    CP: 18015,
    lat: 37.19217,
    long: -3.62041,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA AURORA",
    DOMICILIO: "GENERAL GIRALDES, Nº16",
    PROVINCIA: "SEVILLA",
    CP: 41710,
    lat: 37.19226,
    long: -5.79165,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES.ROTONDA SANTA FE S.L",
    DOMICILIO: "AVDA. PALOS DE LA FRONTERA S/N",
    PROVINCIA: "GRANADA",
    CP: 18320,
    lat: 37.192529,
    long: -3.708926,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. SANTA FE",
    DOMICILIO: "CTRA.A-329, KM-3,60",
    PROVINCIA: "GRANADA",
    CP: 18320,
    lat: 37.19279,
    long: -3.69923,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROUYA",
    DOMICILIO: "CTRA. UTRERA - SEVILLA KM 1 PARC. 3A",
    PROVINCIA: "SEVILLA",
    CP: 41710,
    lat: 37.19361,
    long: -5.793367,
  },
  {
    BANDERA: "TAMOIL",
    "NOMBRE TPV": "TAMOIL GRANADA",
    DOMICILIO: "AVDA. ANDALUCIA S/N POL. EL FLORIDO",
    PROVINCIA: "GRANADA",
    CP: 18015,
    lat: 37.19567,
    long: -3.63271,
  },
  {
    BANDERA: "TAMOIL",
    "NOMBRE TPV": "E.S. TAMOIL UTRERA",
    DOMICILIO: "CTRA. A-394 P.K. 16",
    PROVINCIA: "SEVILLA",
    CP: 41710,
    lat: 37.20139,
    long: -5.74084,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "GREEN PET ROLEUM",
    DOMICILIO: "ACEQUIA DE LA MADRAZA S/N",
    PROVINCIA: "GRANADA",
    CP: 18015,
    lat: 37.204379,
    long: -3.655338,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "ES CEDIPSA MERCAGRANADA",
    DOMICILIO: "CTRA. NACIONA 432 KM. 431",
    PROVINCIA: "GRANADA",
    CP: 18015,
    lat: 37.205141,
    long: -3.664626,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. CANGES DEL HOYO",
    DOMICILIO: "AUTOVIA, 376 KM. 22,3 IZQUIERDO",
    PROVINCIA: "SEVILLA",
    CP: 41710,
    lat: 37.209191,
    long: -5.800086,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "GASOLINERA JUNCARIL,S.L.",
    DOMICILIO: "CTRA.MARACENA-ALBOLOTE PARCELA 114-115",
    PROVINCIA: "GRANADA",
    CP: 18220,
    lat: 37.22343,
    long: -3.6462,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "SUR-OIL",
    DOMICILIO: "CALLE LOJA, PARC. 120",
    PROVINCIA: "GRANADA",
    CP: 18210,
    lat: 37.22868,
    long: -3.641003,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA SAN MANUEL",
    DOMICILIO: "AVDA. REINA SOFIA, S/N",
    PROVINCIA: "GRANADA",
    CP: 18210,
    lat: 37.229784,
    long: -3.63722,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LOW COST TIBUROMOIL",
    DOMICILIO: "C/ BAZA PARC 7 POL IND JUNCARIL",
    PROVINCIA: "GRANADA",
    CP: 18220,
    lat: 37.23013,
    long: -3.64571,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "A.S.EL TORREON - SEVILLA",
    DOMICILIO: "AUTOVIA A-92 KM.239",
    PROVINCIA: "GRANADA",
    CP: 18220,
    lat: 37.23153,
    long: -3.66851,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ALBOLOTE",
    DOMICILIO: "PASEO COLON SN",
    PROVINCIA: "GRANADA",
    CP: 18220,
    lat: 37.232829,
    long: -3.64921,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LOMA VERDE",
    DOMICILIO: "CTRA. Nº323 P.K. 121",
    PROVINCIA: "GRANADA",
    CP: 18220,
    lat: 37.24755,
    long: -3.65485,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ARAHAL",
    DOMICILIO: "CTRA. VILLAMARTIN S/N",
    PROVINCIA: "SEVILLA",
    CP: 41600,
    lat: 37.251912,
    long: -5.550038,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ARAHAL (IZDO)",
    DOMICILIO: "A-92 KM.44",
    PROVINCIA: "SEVILLA",
    CP: 41600,
    lat: 37.254978,
    long: -5.565927,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. TYC, S.A.",
    DOMICILIO: "AVDA.TRAFICO PESADO KM.9,5",
    PROVINCIA: "HUELVA",
    CP: 21007,
    lat: 37.26342,
    long: -6.91965,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. MIRASIERRA",
    DOMICILIO: "CRTA RUTE-ENCINAS REALES KM. 0,200",
    PROVINCIA: "CORDOBA",
    CP: 14960,
    lat: 37.268702,
    long: -4.412686,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "TYCSA",
    DOMICILIO: "RONDA EXTERIOR NORTE S/N",
    PROVINCIA: "HUELVA",
    CP: 21007,
    lat: 37.272563,
    long: -6.917252,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ESTEPA",
    DOMICILIO: "AUTOV. A-92 P.K. 113,7",
    PROVINCIA: "SEVILLA",
    CP: 41560,
    lat: 37.275739,
    long: -4.801181,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. VENTA ÁLVAREZ",
    DOMICILIO: "A-472 KM 85",
    PROVINCIA: "HUELVA",
    CP: 21007,
    lat: 37.292763,
    long: -6.898503,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "OCTANOS",
    DOMICILIO: "CRTA LA ISLA C/RIO VIEJO ESQ EL COPERO",
    PROVINCIA: "SEVILLA",
    CP: 41703,
    lat: 37.293221,
    long: -5.988651,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. DE LA VEGA",
    DOMICILIO: "CTRA.ALCALA-MORON KM.4,400",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.2957,
    long: -5.78963,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. MERCAOIL, S.L.",
    DOMICILIO: "C/ VIANA 2",
    PROVINCIA: "SEVILLA",
    CP: 41560,
    lat: 37.296511,
    long: -4.882608,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. FUEL MARKT REPSOL",
    DOMICILIO: "AVDA. BADIA POLESINE, 49",
    PROVINCIA: "SEVILLA",
    CP: 41560,
    lat: 37.299362,
    long: -4.883734,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ESTEPA MD",
    DOMICILIO: "CTRA A-92, PK 105",
    PROVINCIA: "SEVILLA",
    CP: 41560,
    lat: 37.302119,
    long: -4.880161,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ESTEPA MI",
    DOMICILIO: "CTRA A-92, PK 105,2",
    PROVINCIA: "SEVILLA",
    CP: 41560,
    lat: 37.303047,
    long: -4.876819,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. ARROYO ALCARAYON",
    DOMICILIO: "AVDA ALJARAFE 98-100",
    PROVINCIA: "SEVILLA",
    CP: 41840,
    lat: 37.30326,
    long: -6.28807,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LA RED -3",
    DOMICILIO: "CC-432 KM.8,700",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.30892,
    long: -5.88965,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. CRUZ ALTERA",
    DOMICILIO: "CTRA. ILLORA KM 2",
    PROVINCIA: "GRANADA",
    CP: 18270,
    lat: 37.32486,
    long: -3.98792,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ALCALA PUEBLO",
    DOMICILIO: "CTRA LLERENA-UTRERA KM.157",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.32658,
    long: -5.85289,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP RIO FARDES",
    DOMICILIO: "CTRA. A92 SEVILLA - BAZA DRCHA., KM. 286",
    PROVINCIA: "GRANADA",
    CP: 18519,
    lat: 37.32898,
    long: -3.2167,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA EL PUNTO",
    DOMICILIO: "C/ORELLANA S/N",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.336714,
    long: -5.853476,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "YH OIL SERV.-SUM.DEL SUR",
    DOMICILIO: "CTRA. SEVILLA-MALAGA KM 11,9 A-92",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.34431,
    long: -5.8209,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "MONTECARMELO',
    DOMICILIO: "CTRA SEVILLA-MALAGA KM.10,800",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.34629,
    long: -5.82732,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. MONTEQUINTO",
    DOMICILIO: "P.I. FUENTE QUINTILLO, C/ VIA APIA,60",
    PROVINCIA: "SEVILLA",
    CP: 41089,
    lat: 37.34755,
    long: -5.93804,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ALCALA DE G.",
    DOMICILIO: "CL. MAR CANTABRICO Nº13",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.348092,
    long: -5.848581,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CIUDAD EXPO",
    DOMICILIO: "AVDA. DE CUBA, S/N",
    PROVINCIA: "SEVILLA",
    CP: 41927,
    lat: 37.352146,
    long: -6.047495,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S PISA",
    DOMICILIO: "C/ EXPOSICIÓN S/N PARQUE IND. PISA",
    PROVINCIA: "SEVILLA",
    CP: 41927,
    lat: 37.352241,
    long: -6.056081,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ASC MAIRENA",
    DOMICILIO: "CALLE ARTESANIA 11",
    PROVINCIA: "SEVILLA",
    CP: 41927,
    lat: 37.3569,
    long: -6.05796,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA LIEBRE",
    DOMICILIO: "CTRA. N-334, PKM. 8",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.35702,
    long: -5.86839,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "GASOLINERA LA RED,S.A.-2",
    DOMICILIO: "CN-334 KM.7,8",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.35734,
    long: -5.86697,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. PUERTO SEVILLA",
    DOMICILIO: "SE-30; PK. 12",
    PROVINCIA: "SEVILLA",
    CP: 41011,
    lat: 37.36261,
    long: -6.001414,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EL ZACATIN S.L.",
    DOMICILIO: "CTRA SEVILLA-MALAGA KM.11",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.36287,
    long: -5.87522,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA NEGRILLA",
    DOMICILIO: "SE-21, KM. 0,800",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.368396,
    long: -5.935501,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL PALILLOS",
    DOMICILIO: "A-92 SEVILLA - MALAGA P.K. 6",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.37016,
    long: -5.88003,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ISBILYA PARQUE PRINCIPES",
    DOMICILIO: "AV. FLOTA DE INDIAS, S/N",
    PROVINCIA: "SEVILLA",
    CP: 41011,
    lat: 37.37198,
    long: -6.00611,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL PALMETE",
    DOMICILIO: "SE-021 PKM.2,25",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.372792,
    long: -5.92177,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LAYGAR S.L.",
    DOMICILIO: "CTRA. SEVILLA MALAGA KM 4,7",
    PROVINCIA: "SEVILLA",
    CP: 41500,
    lat: 37.37641,
    long: -5.89031,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LA VARIANTE, S.A.L.",
    DOMICILIO: "CTRA. ESTEPA-GUADIX KM.37,650",
    PROVINCIA: "CORDOBA",
    CP: 14500,
    lat: 37.37787,
    long: -4.79354,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. GINES",
    DOMICILIO: "CTRA. SE-625 KM 0,75",
    PROVINCIA: "SEVILLA",
    CP: 41960,
    lat: 37.37801,
    long: -6.082027,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL EL QUEMA",
    DOMICILIO: "AV. EUROPA Nº 1",
    PROVINCIA: "SEVILLA",
    CP: 41960,
    lat: 37.38344,
    long: -6.08584,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "GINES",
    DOMICILIO: "C/ GENERAL FRANCO, 98",
    PROVINCIA: "SEVILLA",
    CP: 41960,
    lat: 37.384523,
    long: -6.066089,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROAMARILLA SEVILLA",
    DOMICILIO: "AVDA DE LA PRENSA Nº30 P.I CRTA AMARILLA",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.389399,
    long: -5.950075,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 MONTESIERRA",
    DOMICILIO: "AVDA. DE LA PRENSA 1",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.390121,
    long: -5.95781,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "LA BARCA EESS",
    DOMICILIO: "CALLE SAN SEBASTIAN,2",
    PROVINCIA: "CORDOBA",
    CP: 14500,
    lat: 37.39073,
    long: -4.77901,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "CARBURANTES SANTA JUSTA",
    DOMICILIO: "AVENIDA KANSAS CITY S/N",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.39087,
    long: -5.9741,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MONTE SIERRA",
    DOMICILIO: "AVDA.MONTE SIERRA S/N",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.39306,
    long: -5.95253,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SANTA CLARA",
    DOMICILIO: "CTRA. N-IV KM 537,6 M.DER.",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.393162,
    long: -5.952965,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. EFESO",
    DOMICILIO: "C/EFESO INT. C/TARSO",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.39374,
    long: -5.96274,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA EL PILAR",
    DOMICILIO: "AUTOVIA A-92, P.K. 1,3",
    PROVINCIA: "SEVILLA",
    CP: 41016,
    lat: 37.394187,
    long: -5.880236,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PETROSOL, S.L.",
    DOMICILIO: "P.I.HUERTO DEL FRANCES, MEMBRILLERAS, 5",
    PROVINCIA: "CORDOBA",
    CP: 14500,
    lat: 37.395349,
    long: -4.764877,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PETROGENIL",
    DOMICILIO: "POL.IND. HUERTO DEL FRANCES M6, PARC.14",
    PROVINCIA: "CORDOBA",
    CP: 14500,
    lat: 37.40136,
    long: -4.7585,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "PALACIO DE CONGRESOS",
    DOMICILIO: "AVDA. ALCALDE LUIS URUÑUELA S/N",
    PROVINCIA: "SEVILLA",
    CP: 41007,
    lat: 37.4051,
    long: -5.93498,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.VIRGEN DEL LORETO,SL",
    DOMICILIO: "AVENIDA DE LA GUARDIA CIVIL 9",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.4052,
    long: -4.49402,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 LUCENA",
    DOMICILIO: "AVENIDA DE MIGUEL CUENCA VALDIVIA, 16",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.414074,
    long: -4.49892,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. LUCENA",
    DOMICILIO: "RONDA DE SAN FRANCISCO 27",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.414594,
    long: -4.486911,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LUCENA",
    DOMICILIO: "CTRA. CABRAN,31",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.41972,
    long: -4.48252,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ALCALA LA REAL",
    DOMICILIO: "CTRA. N-432 KM. 393,5",
    PROVINCIA: "JAEN",
    CP: 23680,
    lat: 37.4231,
    long: -3.87244,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "B.P LOS BOMBEROS",
    DOMICILIO: "CTRA CABRA KM 1,5",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.43093,
    long: -4.47498,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "EASYGAS AEROP. SEVILLA",
    DOMICILIO: "CTRA N-IV KM 529",
    PROVINCIA: "SEVILLA",
    CP: 41309,
    lat: 37.432369,
    long: -5.874442,
  },
  {
    BANDERA: "DISA",
    "NOMBRE TPV": "E.S BUENAVISTA",
    DOMICILIO: "CTRA. ALGARINEJO, 13",
    PROVINCIA: "CORDOBA",
    CP: 14800,
    lat: 37.432846,
    long: -4.194037,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LUCENA AUTOP. DER",
    DOMICILIO: "AUTOPISTA A 45 KM 51",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.440099,
    long: -4.552746,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LUCENA AUTOP. IZD",
    DOMICILIO: "AUTOPISTA A 45 KM 51",
    PROVINCIA: "CORDOBA",
    CP: 14900,
    lat: 37.440521,
    long: -4.550819,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA PRIEGO II",
    DOMICILIO: "AVDA. GRANADA S/N (DIRECCION GRANADA)",
    PROVINCIA: "CORDOBA",
    CP: 14800,
    lat: 37.441687,
    long: -4.191814,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "PRIEGO',
    DOMICILIO: "AVDA. DE GRANADA S/N",
    PROVINCIA: "CORDOBA",
    CP: 14800,
    lat: 37.441979,
    long: -4.191851,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "NTRA. SRA. REMEDIOS",
    DOMICILIO: "CTRA. A-8006 PK 22",
    PROVINCIA: "SEVILLA",
    CP: 41309,
    lat: 37.448833,
    long: -5.997139,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "ES PUERTA DE ALCALA SHEL",
    DOMICILIO: "CR.MONTEFRIO CRUCE CR.NACIONAL 432 AC 78",
    PROVINCIA: "JAEN",
    CP: 23680,
    lat: 37.45256,
    long: -3.93536,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LA CATRIA",
    DOMICILIO: "CTRA. SEVILLA-LA RINCONADA KM 6,1",
    PROVINCIA: "SEVILLA",
    CP: 41309,
    lat: 37.458866,
    long: -5.974245,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 LAS MERCEDES",
    DOMICILIO: "C/ Carretera de JaEn, 8",
    PROVINCIA: "JAEN",
    CP: 23680,
    lat: 37.467667,
    long: -3.927611,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.ATALAYA",
    DOMICILIO: "AVDA.DE BELEN S/N",
    PROVINCIA: "CORDOBA",
    CP: 14940,
    lat: 37.48143,
    long: -4.43805,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. GENIL",
    DOMICILIO: "CR A-.31 P KM 69",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.491198,
    long: -5.162714,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. VILLANUEVA",
    DOMICILIO: "C/DEHESA DE LA YEGUAS, 4. P.E. SEDESA",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.519912,
    long: -5.158886,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CAMPINA",
    DOMICILIO: "NACIONAL A-4 460",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.52305,
    long: -5.155477,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S SANTIAGO LA CHOZA",
    DOMICILIO: "CRTA NIV MADRID CADIZ KM 455,6",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.529547,
    long: -5.098877,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S SANTIAGO DE ECIJA",
    DOMICILIO: "AVENIDA DEL GENIL, 18",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.534233,
    long: -5.079334,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL BARRERO",
    DOMICILIO: "C/ ARTESANOS, P.I. EL BARRERO",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.54734,
    long: -5.09046,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ASTIGI-ECIJA",
    DOMICILIO: "A4 KM 450",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.5485,
    long: -5.045077,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SANTA ANA",
    DOMICILIO: "CN-IV KM.441,500",
    PROVINCIA: "SEVILLA",
    CP: 41400,
    lat: 37.602218,
    long: -4.971276,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SHELL EL TAMBOR",
    DOMICILIO: "AVENIDA DE CARLOS DE CHILE, 55",
    PROVINCIA: "CORDOBA",
    CP: 14850,
    lat: 37.623286,
    long: -4.323439,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. VILLAR",
    DOMICILIO: "CRTA ECIJA-POSADAS KM 8,8",
    PROVINCIA: "CORDOBA",
    CP: 14120,
    lat: 37.628513,
    long: -5.038846,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CEDIPSA LOS CABALLOS",
    DOMICILIO: "AVDA.JUAN CARLOS I S/N",
    PROVINCIA: "CORDOBA",
    CP: 14520,
    lat: 37.66728,
    long: -4.72745,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. TADISUR S.L.",
    DOMICILIO: "C/ ARTESANIA 2",
    PROVINCIA: "CORDOBA",
    CP: 14100,
    lat: 37.675965,
    long: -4.927654,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA SANTA ANA",
    DOMICILIO: "AVDA. SANTA ANA, 83",
    PROVINCIA: "CORDOBA",
    CP: 14700,
    lat: 37.703076,
    long: 4.28265,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S SANTIAGO EN FUENTE",
    DOMICILIO: "PASEO BLAS INFANTE, 37",
    PROVINCIA: "CORDOBA",
    CP: 14120,
    lat: 37.708146,
    long: -5.098004,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.E.S.S. EL MAJUELO",
    DOMICILIO: "P.I. EL MAJUELO PARCELA 47",
    PROVINCIA: "SEVILLA",
    CP: 41470,
    lat: 37.708467,
    long: -5.355544,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. NUEVO MARTOS",
    DOMICILIO: "C/ BAILEN, 2 POLIGONO INDUSTRIAL MARTOS",
    PROVINCIA: "JAEN",
    CP: 23600,
    lat: 37.70849,
    long: -3.97064,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MARTOS",
    DOMICILIO: "C/TENIENTE GRAL. CHAMORRO MARTINEZ, 142",
    PROVINCIA: "JAEN",
    CP: 23600,
    lat: 37.727182,
    long: -3.974939,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S.MIRABUENO-GALP",
    DOMICILIO: "AVDA.DE JAEN, S/N",
    PROVINCIA: "JAEN",
    CP: 23650,
    lat: 37.76266,
    long: -3.93536,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SAN ANDRES",
    DOMICILIO: "C/ SAN BARTOLOME, S/N",
    PROVINCIA: "JAEN",
    CP: 23640,
    lat: 37.77389,
    long: -3.89935,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TORREDELCAMPO",
    DOMICILIO: "CRTRA. N-321 P.K. 68,5",
    PROVINCIA: "JAEN",
    CP: 23640,
    lat: 37.77667,
    long: -3.88886,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "UNIVERSIDAD JAEN",
    DOMICILIO: "CTRA.DE TORREQUEBRADILLA S/N",
    PROVINCIA: "JAEN",
    CP: 23009,
    lat: 37.78475,
    long: -3.77636,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "ELICHE",
    DOMICILIO: "RONDA DE LOS OLIVARES,71 P.I PARCE 14-10",
    PROVINCIA: "JAEN",
    CP: 23009,
    lat: 37.79975,
    long: -3.790778,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SHELL SAN LUCAS",
    DOMICILIO: "AUTOVIA A-44 (BAILEN-MOTRIL), KM 36",
    PROVINCIA: "JAEN",
    CP: 23009,
    lat: 37.81723,
    long: -3.77129,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.ALMODOVAR",
    DOMICILIO: "CC-431 KM.21",
    PROVINCIA: "CORDOBA",
    CP: 14720,
    lat: 37.82132,
    long: -5.00473,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "P3 PETROLEOS",
    DOMICILIO: "AVDA. LA TORRECILLA, 41-43",
    PROVINCIA: "CORDOBA",
    CP: 14013,
    lat: 37.853665,
    long: -4.796717,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. EL ARCANGEL",
    DOMICILIO: "AVDA.EL ARCANGEL S/N (C.C.EROSKI)",
    PROVINCIA: "CORDOBA",
    CP: 14010,
    lat: 37.87697,
    long: -4.7662,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PARQUE LOGISTICO",
    DOMICILIO: "CTRA.PALMA DEL RIO KM 4L HIGUERON",
    PROVINCIA: "CORDOBA",
    CP: 14005,
    lat: 37.877095,
    long: -4.841183,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. DELICIAS",
    DOMICILIO: "CRTA. PALMA DEL RIO KM 2",
    PROVINCIA: "CORDOBA",
    CP: 14005,
    lat: 37.883348,
    long: -4.805303,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SAN ALVARO",
    DOMICILIO: "AVDA. REPUBLICA ARGENTINA S/N",
    PROVINCIA: "CORDOBA",
    CP: 14005,
    lat: 37.886028,
    long: -4.786129,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EL GRANADAL",
    DOMICILIO: "P.I.EL GRANADAL,AVDA.AZADACHE ESQ PLATA",
    PROVINCIA: "CORDOBA",
    CP: 14014,
    lat: 37.88783,
    long: -4.74936,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA CAÑERO",
    DOMICILIO: "AVD. DE LIBIA Nº 27",
    PROVINCIA: "CORDOBA",
    CP: 14007,
    lat: 37.890803,
    long: -4.75875,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROIL ENERGY",
    DOMICILIO: "C/ AMATISTA, 4",
    PROVINCIA: "CORDOBA",
    CP: 14014,
    lat: 37.891756,
    long: -4.748272,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PETROIL ENERGY",
    DOMICILIO: "C/TURQUESA Nº2 PG GRANADAL",
    PROVINCIA: "CORDOBA",
    CP: 14014,
    lat: 37.891822,
    long: -4.747795,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA LA MEZQUITA",
    DOMICILIO: "CTRA. NACIONAL A-4, KM.393,5",
    PROVINCIA: "CORDOBA",
    CP: 14013,
    lat: 37.893539,
    long: 4.689837,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROL AND GO CORDOBA",
    DOMICILIO: "C/ ARTESANOS, 7A POL. IND. PEDROCHES",
    PROVINCIA: "CORDOBA",
    CP: 14014,
    lat: 37.904651,
    long: -4.759471,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. QUEMADAS",
    DOMICILIO: "PG QUEMADAS C/ SIMON CARPINTERO PAR 29 B",
    PROVINCIA: "CORDOBA",
    CP: 14014,
    lat: 37.90658,
    long: -4.718194,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LA PAZ",
    DOMICILIO: "CR N-340 KM. 648.2",
    PROVINCIA: "MURCIA",
    CP: 30820,
    lat: 37.9528,
    long: -1.25923,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "SAN GINES',
    DOMICILIO: "CTRA.MURCIA KM.3",
    PROVINCIA: "MURCIA",
    CP: 30169,
    lat: 37.95881,
    long: -1.14475,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.MENGIBAR",
    DOMICILIO: "CN-323 KM.13",
    PROVINCIA: "JAEN",
    CP: 23620,
    lat: 37.97024,
    long: -3.79796,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. BAÑOS-ALCANTARILLA",
    DOMICILIO: "CTRA. MULA KM.1,5",
    PROVINCIA: "MURCIA",
    CP: 30820,
    lat: 37.97466,
    long: -1.22934,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LOS CABALLOS -D-",
    DOMICILIO: "CN-IV KM.346,6",
    PROVINCIA: "JAEN",
    CP: 23700,
    lat: 37.98927,
    long: -4.26052,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LOS CABALLOS, 2",
    DOMICILIO: "CTRA. N-IV KM 346,6 M.D.",
    PROVINCIA: "CORDOBA",
    CP: 14640,
    lat: 37.98998,
    long: -4.26133,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL BAEZA",
    DOMICILIO: "C/GUADALQUIVIR (P.I. LA DEHESILLA) Nº1",
    PROVINCIA: "JAEN",
    CP: 23440,
    lat: 37.9922,
    long: -3.47959,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": 'E.S.RUTAMUR S.A. "ICA',
    DOMICILIO: "AVDA MIGUEL DE CERVANTES, 50",
    PROVINCIA: "MURCIA",
    CP: 30100,
    lat: 37.99636,
    long: -1.14253,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BAEZA",
    DOMICILIO: "AVDA PUCHE PARDO S/N",
    PROVINCIA: "JAEN",
    CP: 23440,
    lat: 37.99708,
    long: -3.457504,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES NTRA SRA D LOS DOLORE",
    DOMICILIO: "CARRETERA 326 BAEZA-IBROS KM 12,1",
    PROVINCIA: "JAEN",
    CP: 23440,
    lat: 38.002512,
    long: -3.480111,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ESPINARDO",
    DOMICILIO: "DESVIO DE ESPINARDO, S/N",
    PROVINCIA: "MURCIA",
    CP: 30100,
    lat: 38.00556,
    long: -1.14449,
  },
  {
    BANDERA: "TAMOIL",
    "NOMBRE TPV": "E.S. TAMOIL-MARMOLEJO",
    DOMICILIO: "CTRA. N-IV, KM 339",
    PROVINCIA: "JAEN",
    CP: 23770,
    lat: 38.00563,
    long: -4.19286,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. Mª AUXILIADORA",
    DOMICILIO: "N-IV KM. 358,5",
    PROVINCIA: "CORDOBA",
    CP: 14600,
    lat: 38.008254,
    long: -4.385599,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.RED AZUL, S.A.-UBEDA",
    DOMICILIO: "PASEO DEL LEON S/N",
    PROVINCIA: "JAEN",
    CP: 23400,
    lat: 38.01436,
    long: -3.38404,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. SAN MIGUEL",
    DOMICILIO: "AVDA. LIBERTAD, 76",
    PROVINCIA: "JAEN",
    CP: 23400,
    lat: 38.016138,
    long: -3.363927,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "PETROPAY',
    DOMICILIO: "P.I. EL TIRO PARCELA 72 NAVE 1-2",
    PROVINCIA: "MURCIA",
    CP: 30100,
    lat: 38.0205,
    long: 1.1638,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. VEGAS DE TRIANA",
    DOMICILIO: "PLAZA VEGAS DE TRIANA M-1 PAR 11",
    PROVINCIA: "JAEN",
    CP: 23740,
    lat: 38.021638,
    long: -4.053587,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP UBEDA",
    DOMICILIO: "CN 323 CORDOBA VALENCIA ESQ C/LEON XIII",
    PROVINCIA: "JAEN",
    CP: 23400,
    lat: 38.021918,
    long: -3.367918,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LOS OLIVOS",
    DOMICILIO: "CN-323 KM.11,9",
    PROVINCIA: "JAEN",
    CP: 23009,
    lat: 38.02362,
    long: -3.77978,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA CHURRA",
    DOMICILIO: "AVDA. JUAN DE BORBÓN, 230",
    PROVINCIA: "MURCIA",
    CP: 30110,
    lat: 38.02488,
    long: -1.143242,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "GASOLINERA LEO CEPSA",
    DOMICILIO: "AUTOVIA A 66, KM 730",
    PROVINCIA: "BADAJOZ",
    CP: "06260",
    lat: 38.028187,
    long: -6.220492,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "Q8 MARMOLEJO",
    DOMICILIO: "AVENIDA DE AFRICA ,104",
    PROVINCIA: "JAEN",
    CP: 23770,
    lat: 38.029072,
    long: -4.166381,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MONESTERIO AUTOVI",
    DOMICILIO: "CTRA. A-66 KM. 727 MARGEN IZDO.",
    PROVINCIA: "BADAJOZ",
    CP: "06260",
    lat: 38.02955,
    long: -6.22059,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EUROIL LA RECTA",
    DOMICILIO: "CTRA. N-322, PK 158,100",
    PROVINCIA: "JAEN",
    CP: 23400,
    lat: 38.031322,
    long: -3.33368,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SANTA URSULA",
    DOMICILIO: "POLIGONO LA CECA PARC. 23",
    PROVINCIA: "JAEN",
    CP: 23740,
    lat: 38.033942,
    long: -4.056219,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.ANDUJAR",
    DOMICILIO: "PASEO DE COLON S/N",
    PROVINCIA: "JAEN",
    CP: 23740,
    lat: 38.03554,
    long: -4.05682,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 ANDUJAR",
    DOMICILIO: "AVDA. BLAS INFANTE, S/N",
    PROVINCIA: "JAEN",
    CP: 23740,
    lat: 38.039361,
    long: -4.039583,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S ILITURGI",
    DOMICILIO: "C/ CAMINO CEMENTERIO, Nº 5",
    PROVINCIA: "JAEN",
    CP: 23740,
    lat: 38.044054,
    long: -4.057517,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MONESTERIO",
    DOMICILIO: "PASEO DE EXTREMADURA, 294",
    PROVINCIA: "BADAJOZ",
    CP: "06260",
    lat: 38.08388,
    long: -6.26861,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CASTULO",
    DOMICILIO: "CTRA JABALQUINTO S/N",
    PROVINCIA: "JAEN",
    CP: 23700,
    lat: 38.08637,
    long: -3.64045,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.BAILEN",
    DOMICILIO: "CN-IV KM.296",
    PROVINCIA: "JAEN",
    CP: 23710,
    lat: 38.08858,
    long: -3.77871,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. REVISOIL S.L.",
    DOMICILIO: "CTRA. ORIHUELA ALMORADI KM 8,2",
    PROVINCIA: "ALICANTE",
    CP: "03300",
    lat: 38.08957,
    long: -0.84902,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LINARES",
    DOMICILIO: "AVDA.ESPAÑA, 3",
    PROVINCIA: "JAEN",
    CP: 23700,
    lat: 38.09027,
    long: -3.62612,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LINARES",
    DOMICILIO: "CR. N 322 KM. 119",
    PROVINCIA: "JAEN",
    CP: 23700,
    lat: 38.0914,
    long: -3.65034,
  },
  {
    BANDERA: "VALCARCE",
    "NOMBRE TPV": "A. DE S. EL DELFIN AZUL",
    DOMICILIO: "CTRA. ANTIGUA MADRID-CADIZ KM. 295",
    PROVINCIA: "JAEN",
    CP: 23710,
    lat: 38.09358,
    long: -3.76876,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "EL PASO',
    DOMICILIO: "CN-IV KM.254,300",
    PROVINCIA: "JAEN",
    CP: 23710,
    lat: 38.0985,
    long: -3.9659,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. SAN GONZALO",
    DOMICILIO: "CTRA.POZO ANCHO S/N",
    PROVINCIA: "JAEN",
    CP: 23700,
    lat: 38.1116,
    long: -3.63805,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP ABADES BAILEN",
    DOMICILIO: "AUTOVIA A-4 KM. 288",
    PROVINCIA: "JAEN",
    CP: 23710,
    lat: 38.14372,
    long: -3.72854,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VILLANUEVA ARZOBI",
    DOMICILIO: "AVDA. DE VALENCIA S/N",
    PROVINCIA: "JAEN",
    CP: 23330,
    lat: 38.17259,
    long: -3.00395,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ELCHE",
    DOMICILIO: "CL SOR JOSEFA ALCORTA 71",
    PROVINCIA: "ALICANTE",
    CP: "03206",
    lat: 38.2525,
    long: -0.70306,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CARRUS-ELX",
    DOMICILIO: "AVDA.NOVELDA, 128",
    PROVINCIA: "ALICANTE",
    CP: "03206",
    lat: 38.27745,
    long: -0.71626,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ES VIBAL",
    DOMICILIO: "AVENIDA ANDALUCIA S/N",
    PROVINCIA: "CORDOBA",
    CP: 14200,
    lat: 38.296417,
    long: -5.261361,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.EL DESVIO",
    DOMICILIO: "CTRA.CIRCUNVALACION ELCHE CRUCE DOLORES",
    PROVINCIA: "ALICANTE",
    CP: "03206",
    lat: 38.29709,
    long: -0.61551,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA PALMERES",
    DOMICILIO: "C/ CTRA.OCAÑA, 78",
    PROVINCIA: "ALICANTE",
    CP: "03006",
    lat: 38.34404,
    long: -0.53832,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ORIHUELA",
    DOMICILIO: "CTRA. DE OCAÑA Nº2",
    PROVINCIA: "ALICANTE",
    CP: "03006",
    lat: 38.34555,
    long: -0.51776,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S PETROALACANT SL-I",
    DOMICILIO: "POL.PLA DE LA VALLONGA C/8 PARC.15",
    PROVINCIA: "ALICANTE",
    CP: "03006",
    lat: 38.34825,
    long: -0.55539,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. POZOBLANCO",
    DOMICILIO: "CC-420 KM.131,4",
    PROVINCIA: "CORDOBA",
    CP: 14400,
    lat: 38.37968,
    long: -4.85994,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PINOSO",
    DOMICILIO: "CTRA. JUMILLA, 58",
    PROVINCIA: "ALICANTE",
    CP: "03012",
    lat: 38.40232,
    long: -1.04661,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.ZAFRA",
    DOMICILIO: "C/CERVANTES, 1",
    PROVINCIA: "BADAJOZ",
    CP: "06300",
    lat: 38.42251,
    long: -6.4142,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.ZAFRA",
    DOMICILIO: "CN-432 KM.72",
    PROVINCIA: "BADAJOZ",
    CP: "06300",
    lat: 38.43044,
    long: -6.41717,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LOS PEDROCHES SRL",
    DOMICILIO: "CTRA.AÑORA-DOS TORRES KM.2",
    PROVINCIA: "CORDOBA",
    CP: 14450,
    lat: 38.43139,
    long: -4.89619,
  },
  {
    BANDERA: "MEROIL",
    "NOMBRE TPV": "E.S. LA TRINIDAD",
    DOMICILIO: "CC-420 KM.102",
    PROVINCIA: "CORDOBA",
    CP: 14270,
    lat: 38.49831,
    long: -5.13994,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ALFAL DEL PI",
    DOMICILIO: "CL MALLORCA, 1 - CTRA CV-763",
    PROVINCIA: "ALICANTE",
    CP: "03580",
    lat: 38.571101,
    long: -0.095276,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. ALFAZ DEL PI",
    DOMICILIO: "AV. DEL PAÍS VALENCIANO, 41",
    PROVINCIA: "ALICANTE",
    CP: "03580",
    lat: 38.58138,
    long: -0.10557,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. LAS MARINAS-ALTEA",
    DOMICILIO: "CARRER LA MAR 137",
    PROVINCIA: "ALICANTE",
    CP: "03590",
    lat: 38.59322,
    long: -0.05492,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ALGAR",
    DOMICILIO: "PARTIDA GARGANES /CAMI ALGAR Nº42",
    PROVINCIA: "ALICANTE",
    CP: "03590",
    lat: 38.60483,
    long: -0.04885,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "COSINTRA SCOOP",
    DOMICILIO: "VARIANTE DEL MINERO KM 11,500",
    PROVINCIA: "CIUDAD REAL",
    CP: 13500,
    lat: 38.684947,
    long: -4.066782,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. PUERTOLLANO II",
    DOMICILIO: "CN-420 KM.160",
    PROVINCIA: "CIUDAD REAL",
    CP: 13500,
    lat: 38.69542,
    long: -4.10987,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.CRED POL.ESCAPARAT",
    DOMICILIO: "CTRA.N 420 KM 163,500",
    PROVINCIA: "CIUDAD REAL",
    CP: 13500,
    lat: 38.696323,
    long: -4.107517,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "JOSE LUIS HOLGUIN TENA",
    DOMICILIO: "AVDA. EXTREMADURA S/N",
    PROVINCIA: "BADAJOZ",
    CP: "06420",
    lat: 38.72946,
    long: -5.54576,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "U.S. ARMENTERA, S.L.",
    DOMICILIO: "CRTA. PUEBLA DE ALCOCER KM 0,150",
    PROVINCIA: "BADAJOZ",
    CP: "06600",
    lat: 38.729647,
    long: -5.222857,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL MERIDA",
    DOMICILIO: "CRTRA. A66 ENLACE N630 P.K. 5,8",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.858445,
    long: -6.363921,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CTRA OLIVENZA",
    DOMICILIO: "CRTA OLIVENZA, KM 2",
    PROVINCIA: "BADAJOZ",
    CP: "06011",
    lat: 38.858547,
    long: -6.99013,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. PEDRERO FERNANDEZ",
    DOMICILIO: "CN-V KM.402",
    PROVINCIA: "BADAJOZ",
    CP: "06008",
    lat: 38.872776,
    long: -6.95986,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP VEGA DE ANTEQUERA",
    DOMICILIO: "AV. DE LA CRUZ BLANCA S/N",
    PROVINCIA: "MALAGA",
    CP: 29200,
    lat: 38.885965,
    long: 1.392306,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "ERRASTI SUR',
    DOMICILIO: "CN-V KM.340",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.91525,
    long: -6.33201,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. VIA DE LA PLATA",
    DOMICILIO: "POL. IND. EL PRADO SER 1-A",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.92203,
    long: -6.37545,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MERIDA AUTOVIA IZQ",
    DOMICILIO: "A-5, KM-341",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.937834,
    long: -6.362314,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MERIDA AUTOVIA DCH",
    DOMICILIO: "A-5, KM-341",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.938436,
    long: -6.365129,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MERIDA III",
    DOMICILIO: "CN-630 KM.618.5",
    PROVINCIA: "BADAJOZ",
    CP: "06800",
    lat: 38.93864,
    long: -6.34428,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CRISTO DE LA MISERIC",
    DOMICILIO: "CRTA CIUDAD REAL - VALDEPEÑAS",
    PROVINCIA: "CIUDAD REAL",
    CP: 13170,
    lat: 38.956111,
    long: -3.885472,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "HNOS GARCIA',
    DOMICILIO: "EX-206, P.K. 96,5",
    PROVINCIA: "BADAJOZ",
    CP: "06400",
    lat: 38.9581,
    long: -5.8837,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LAS CUMBRES",
    DOMICILIO: "CL. LEONARDO DA VINCI ESQ. CL EDISON",
    PROVINCIA: "BADAJOZ",
    CP: "06400",
    lat: 38.959277,
    long: -5.880701,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.HNOS.GARCIA CAMACHO",
    DOMICILIO: "EX-106 KM.1",
    PROVINCIA: "BADAJOZ",
    CP: "06400",
    lat: 38.96563,
    long: -5.85583,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LA SIRENA",
    DOMICILIO: "AVDA DE CHILE, 54",
    PROVINCIA: "BADAJOZ",
    CP: "06700",
    lat: 38.970953,
    long: -5.785584,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.BECARES",
    DOMICILIO: "CN-430 KM.305",
    PROVINCIA: "CIUDAD REAL",
    CP: 13005,
    lat: 38.975881,
    long: -3.947332,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.LARACHE",
    DOMICILIO: "CTRA.FUENSANTA S/N",
    PROVINCIA: "CIUDAD REAL",
    CP: 13005,
    lat: 38.97885,
    long: -3.92864,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SOTOS S.L.",
    DOMICILIO: "HERMANOS FALCO, 2",
    PROVINCIA: "ALBACETE",
    CP: "02002",
    lat: 38.98933,
    long: -1.84906,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.PLAZA TOROS-XATIVA",
    DOMICILIO: "PLAZA D  ESPAÑA Nº1",
    PROVINCIA: "VALENCIA",
    CP: 46800,
    lat: 38.99058,
    long: -0.51331,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. STA.GERTRUDIS, S.L.",
    DOMICILIO: "CTRA.IBIZA - SAN MIGUEL, KM- 4,9",
    PROVINCIA: "BALEARES",
    CP: "07814",
    lat: 38.99147,
    long: 1.43394,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL RONDALEGRE",
    DOMICILIO: "RONDA DE TOLEDO, Nº 21",
    PROVINCIA: "CIUDAD REAL",
    CP: 13005,
    lat: 38.994631,
    long: -3.924812,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "INPEALSA FERIA",
    DOMICILIO: "AVDA. DE LOS TOREROS S/N",
    PROVINCIA: "ALBACETE",
    CP: "02005",
    lat: 38.9983,
    long: -1.8699,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. P  DE LA CUBA",
    DOMICILIO: "PASEO DE LA CUBA, 21",
    PROVINCIA: "ALBACETE",
    CP: "02005",
    lat: 38.99956,
    long: -1.85442,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. MONEDERO ACERO S.L.",
    DOMICILIO: "CRTA EX-351 KM 4,5",
    PROVINCIA: "BADAJOZ",
    CP: "06700",
    lat: 38.999595,
    long: -5.799287,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.ESCALA EN VERDE,S.L.",
    DOMICILIO: "CEX-106 KM.5",
    PROVINCIA: "BADAJOZ",
    CP: "06400",
    lat: 39.01386,
    long: -5.86953,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 DON BENITO",
    DOMICILIO: "N-430, P.K. 105,500",
    PROVINCIA: "BADAJOZ",
    CP: "06400",
    lat: 39.031,
    long: -5.855444,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ROMICA",
    DOMICILIO: "POLÍGONO INDUSTRIAL ROMICA.",
    PROVINCIA: "ALBACETE",
    CP: "02005",
    lat: 39.054531,
    long: -1.832078,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "RED AZUL, S.A. -CRUCE-",
    DOMICILIO: "CC-3322 KM.19,650",
    PROVINCIA: "VALENCIA",
    CP: 46600,
    lat: 39.14097,
    long: -0.42498,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. RODA I",
    DOMICILIO: "AUTOVIA A-31 KM. 50,50",
    PROVINCIA: "ALBACETE",
    CP: "02630",
    lat: 39.144115,
    long: -2.051183,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "RED AZUL,S.A.-ABASTOS-",
    DOMICILIO: "MERCADO DE ABASTOS",
    PROVINCIA: "VALENCIA",
    CP: 46600,
    lat: 39.1619,
    long: -0.4459,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.TOMELLOSO II",
    DOMICILIO: "C/ SOCUELLAMOS, 92",
    PROVINCIA: "CIUDAD REAL",
    CP: 13700,
    lat: 39.16378,
    long: -3.01642,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S SOCUELLAMOS",
    DOMICILIO: "CRTA. N 310 KM. 104,70",
    PROVINCIA: "CIUDAD REAL",
    CP: 13630,
    lat: 39.164184,
    long: -2.884799,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL TOMELLOSO",
    DOMICILIO: "C/ ZURBARÁN, 19",
    PROVINCIA: "CIUDAD REAL",
    CP: 13700,
    lat: 39.166426,
    long: -3.028308,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S TORRESBLANCAS",
    DOMICILIO: "CM-3111 P.K. 13 ENLACE A-43",
    PROVINCIA: "ALBACETE",
    CP: "02600",
    lat: 39.204735,
    long: -2.708195,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA LA RODA",
    DOMICILIO: "CARRETERA N 301, KM 208,50",
    PROVINCIA: "ALBACETE",
    CP: "02630",
    lat: 39.205963,
    long: -2.158592,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA RODA AUTOVIA",
    DOMICILIO: "AUTOVIA A-31 SALIDA 40",
    PROVINCIA: "ALBACETE",
    CP: "02630",
    lat: 39.21569,
    long: -2.15475,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "ADENOR VILLARRUBIA",
    DOMICILIO: "CRTA. FUENTE EL FRESNO PK. 15",
    PROVINCIA: "CIUDAD REAL",
    CP: 13670,
    lat: 39.216434,
    long: -3.616326,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S LA RODA 2",
    DOMICILIO: "AUTOVIA A-31 KM. 34,60",
    PROVINCIA: "ALBACETE",
    CP: "02630",
    lat: 39.225045,
    long: -2.186065,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S TORRES MARQUEZ",
    DOMICILIO: "AVDA. REYES CATÓLICOS, 26",
    PROVINCIA: "ALBACETE",
    CP: "02600",
    lat: 39.261522,
    long: -2.599331,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "VILLARROBLEDO',
    DOMICILIO: "CN-310 KM 120",
    PROVINCIA: "ALBACETE",
    CP: "02600",
    lat: 39.27472,
    long: -2.59106,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VILLAROBLEDO",
    DOMICILIO: "AVDA REYES CATOLICOS 33",
    PROVINCIA: "ALBACETE",
    CP: "02600",
    lat: 39.276957,
    long: -5.591621,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.CARMONED, S.L.",
    DOMICILIO: "CTRA. ALBACETE, 94",
    PROVINCIA: "ALBACETE",
    CP: "02200",
    lat: 39.2837,
    long: -1.4783,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CRISTO DE LA VEGA",
    DOMICILIO: "C/ MANIGUA 4",
    PROVINCIA: "CIUDAD REAL",
    CP: 13630,
    lat: 39.292812,
    long: -2.805728,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. JUANITO, S.L.",
    DOMICILIO: "CRTA. MADRID-CARTAGENA, KM 209",
    PROVINCIA: "ALBACETE",
    CP: "02630",
    lat: 39.31823,
    long: -2.18565,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL BENIPARRELL",
    DOMICILIO: "CTRA. V-302 KM.233,84",
    PROVINCIA: "VALENCIA",
    CP: 46469,
    lat: 39.380168,
    long: -0.408622,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.ALCAZAR DE SAN JUA",
    DOMICILIO: "CN-420 KM.286,400",
    PROVINCIA: "CIUDAD REAL",
    CP: 13600,
    lat: 39.38688,
    long: -3.23137,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.VILLANUEVA, C.B.-1",
    DOMICILIO: "CN-332 KM.254",
    PROVINCIA: "VALENCIA",
    CP: 46470,
    lat: 39.39706,
    long: -0.38971,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA CONSUMAL",
    DOMICILIO: "AVD VOMPS, 2",
    PROVINCIA: "CIUDAD REAL",
    CP: 13600,
    lat: 39.400432,
    long: -3.216632,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. DARIANA",
    DOMICILIO: "CTRA. N-420 KM 292,700",
    PROVINCIA: "CIUDAD REAL",
    CP: 13610,
    lat: 39.401018,
    long: -3.149095,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PEYMON OIL, S.L.",
    DOMICILIO: "RONDA NORTE INDUSTRIAL, PARC. 65A",
    PROVINCIA: "VALENCIA",
    CP: 46470,
    lat: 39.40212,
    long: -0.40262,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PEYMON OIL SL 1",
    DOMICILIO: "C/ RAMON Y CAJAL, 97",
    PROVINCIA: "VALENCIA",
    CP: 46470,
    lat: 39.40785,
    long: -0.40168,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. HORTETA",
    DOMICILIO: "AVDA.GENERALITAT VALENCIANA, Nº20",
    PROVINCIA: "VALENCIA",
    CP: 46470,
    lat: 39.41055,
    long: -0.40751,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 TRUJILLO",
    DOMICILIO: "Crta. N-V, Salida 259",
    PROVINCIA: "CACERES",
    CP: 10200,
    lat: 39.410944,
    long: -5.886556,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. MARI",
    DOMICILIO: "CTRA.TORRENTE-MONTROY, PK5",
    PROVINCIA: "VALENCIA",
    CP: 46900,
    lat: 39.41637,
    long: -0.50979,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TORRENT",
    DOMICILIO: "CV 05; TOLL.L'ALBERCA",
    PROVINCIA: "VALENCIA",
    CP: 46900,
    lat: 39.422285,
    long: -0.509944,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA SAN PASCUAL",
    DOMICILIO: "CARRETERA SANTA ANA, 19",
    PROVINCIA: "VALENCIA",
    CP: 46210,
    lat: 39.42261,
    long: -0.42102,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.FORN D'ALCEDO, S.L.",
    DOMICILIO: "C/GUADALQUIVIR, 73",
    PROVINCIA: "VALENCIA",
    CP: 46026,
    lat: 39.42873,
    long: -0.37734,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "PEREZ CARVAJAL CB",
    DOMICILIO: "CRTA ANTIGUA N-V KM 123",
    PROVINCIA: "TOLEDO",
    CP: 45600,
    lat: 39.4347,
    long: -6.64039,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.FAITANAR",
    DOMICILIO: "AVDA.DE FAITANAR S/N",
    PROVINCIA: "VALENCIA",
    CP: 46210,
    lat: 39.43606,
    long: -0.42747,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.TORRENTE",
    DOMICILIO: "C/ PICANYA, 25",
    PROVINCIA: "VALENCIA",
    CP: 46900,
    lat: 39.43945,
    long: -0.45701,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PICANYA",
    DOMICILIO: "CTRA. TORRENTE-VALENCIA S/N",
    PROVINCIA: "VALENCIA",
    CP: 46210,
    lat: 39.44081,
    long: -0.42723,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. PROVALEST",
    DOMICILIO: "AVENIDA AUSIAS MARCH, 118",
    PROVINCIA: "VALENCIA",
    CP: 46026,
    lat: 39.44505,
    long: -0.36981,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "G.AUSIAS MARCH-VALENCIA",
    DOMICILIO: "AUSIAS MARCH, 118",
    PROVINCIA: "VALENCIA",
    CP: 46026,
    lat: 39.44507,
    long: -0.36981,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "AMERICANPETROL-ALAQUAS",
    DOMICILIO: "C/ALADRES Nº16 P.I. ELS MOLLONS",
    PROVINCIA: "VALENCIA",
    CP: 46970,
    lat: 39.448643,
    long: -0.473202,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "RED AZUL, S.A.-ALACUAS",
    DOMICILIO: "AVDA.PABLO IGLESIAS, 69",
    PROVINCIA: "VALENCIA",
    CP: 46970,
    lat: 39.44899,
    long: -0.46071,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. SUR-1",
    DOMICILIO: "AVENIDA AUSIAS MARCH Nº 44",
    PROVINCIA: "VALENCIA",
    CP: 46026,
    lat: 39.45247,
    long: -0.37323,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL LAS TORRES",
    DOMICILIO: "EX-206 KM.2",
    PROVINCIA: "CACERES",
    CP: 10005,
    lat: 39.45332,
    long: -6.36564,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S.SOMOVA, S.L.",
    DOMICILIO: "C/ CUENCA, 59",
    PROVINCIA: "VALENCIA",
    CP: 46970,
    lat: 39.45683,
    long: -0.46758,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. BULEVAR SUR",
    DOMICILIO: "AVENIDA TRES CRUCES, 109",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.457009,
    long: -0.401324,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "COOP. VALENCIA TAXISTA V",
    DOMICILIO: "DELS GREMIS Nº10 POL.1 IND.VARA DE QUART",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.45798,
    long: -0.40927,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "COOP. VALENCIA TAXISTAS",
    DOMICILIO: "DELS GREMIS Nº10 POL.1 IND.VARA DE QUART",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.45798,
    long: -0.40927,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "RED AZUL, S.A.- VARA",
    DOMICILIO: "C/ FOGAINER S/N POLIG.IND.VARA DE QUART",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.45943,
    long: -0.40519,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP FUSA",
    DOMICILIO: "AVDA. TRES CRUCES, 48",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.45949,
    long: -0.40361,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. MIRAT COMB. S.L.U.",
    DOMICILIO: "CN-V KM.253 (CALVO SOTELO S/N),",
    PROVINCIA: "CACERES",
    CP: 10200,
    lat: 39.46028,
    long: -5.87087,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. GASTIR OIL",
    DOMICILIO: "AVENIDA DEL PUERTO 263",
    PROVINCIA: "VALENCIA",
    CP: 46011,
    lat: 39.460928,
    long: -0.335833,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ARCHIDUQUE CARLOS",
    DOMICILIO: "C/ ARCHIDUQUE CARLOS, 70",
    PROVINCIA: "VALENCIA",
    CP: 46018,
    lat: 39.46097,
    long: -0.39941,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S AVDA ALEMANIA",
    DOMICILIO: "AVDA ALEMANIA SN",
    PROVINCIA: "CACERES",
    CP: 10005,
    lat: 39.462168,
    long: -6.383563,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA DILUEX",
    DOMICILIO: "AVDA. ANTIGUO REINO DE VALENCIA, 83",
    PROVINCIA: "VALENCIA",
    CP: 46005,
    lat: 39.462772,
    long: -0.362433,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VARA DE QUART",
    DOMICILIO: "C/TRAGINERS, 2",
    PROVINCIA: "VALENCIA",
    CP: 46014,
    lat: 39.46291,
    long: -0.4063,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.GEBE, S.L.-1",
    DOMICILIO: "AVDA. ARAGON Nº 6",
    PROVINCIA: "VALENCIA",
    CP: 46021,
    lat: 39.4688,
    long: -0.35978,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA LA SENYERA II",
    DOMICILIO: "A-3 P.K. 332,9 DIRECCION MADRID",
    PROVINCIA: "VALENCIA",
    CP: 46930,
    lat: 39.47336,
    long: -0.49099,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. XUQUER",
    DOMICILIO: "C/CLARIANOS, 3",
    PROVINCIA: "VALENCIA",
    CP: 46021,
    lat: 39.47616,
    long: -0.35221,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "A.S.G. - LA REVA",
    DOMICILIO: "POL.IND.REUS, SECTOR 12, PARCELA C",
    PROVINCIA: "VALENCIA",
    CP: 46190,
    lat: 39.478639,
    long: -0.569355,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CAMPANAR",
    DOMICILIO: "AVDA.MAESTRO RODRIGO, N 31",
    PROVINCIA: "VALENCIA",
    CP: 46015,
    lat: 39.47902,
    long: -0.39881,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "OIL QUART",
    DOMICILIO: "CARRE MARQUES DE TURIA, 9",
    PROVINCIA: "VALENCIA",
    CP: 46930,
    lat: 39.47908,
    long: -0.44401,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S  EFIGEST",
    DOMICILIO: "CONDE RODEZNO 13",
    PROVINCIA: "VALENCIA",
    CP: 46930,
    lat: 39.480299,
    long: -0.447135,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.RIPOLL-VIVEROS 1",
    DOMICILIO: "AVDA.PRIMADO REIG, 76",
    PROVINCIA: "VALENCIA",
    CP: 46019,
    lat: 39.48354,
    long: -0.3627,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "E.S. NAVARRO-REQUENA",
    DOMICILIO: "CN 111 (MADRID-VALENCIA) KM 276",
    PROVINCIA: "VALENCIA",
    CP: 46340,
    lat: 39.48395,
    long: -1.09521,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ISABEL NAVARRO",
    DOMICILIO: "CR NAC MADRID-VALENCIA KM 276",
    PROVINCIA: "VALENCIA",
    CP: 46340,
    lat: 39.48397,
    long: -1.09526,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. REQUENA",
    DOMICILIO: "CN-III, KM. 275",
    PROVINCIA: "VALENCIA",
    CP: 46340,
    lat: 39.48405,
    long: -1.10613,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BENIFERRI",
    DOMICILIO: "AVDA. DE LES CORTS VALENCIANES, S/N",
    PROVINCIA: "VALENCIA",
    CP: 46015,
    lat: 39.48804,
    long: -0.39828,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ARNAU",
    DOMICILIO: "AV MAESTRO RODRIGO 76",
    PROVINCIA: "VALENCIA",
    CP: 46015,
    lat: 39.488871,
    long: -0.40568,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "S8 MEJOSTILLA",
    DOMICILIO: "C/ LAS ÁGUILAS, 34",
    PROVINCIA: "CACERES",
    CP: 10004,
    lat: 39.48934,
    long: -6.37708,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETROGOLD, S.L.",
    DOMICILIO: "PLAZA JUAN RAMÓN JIMÉNEZ S/N",
    PROVINCIA: "CACERES",
    CP: 10004,
    lat: 39.49377,
    long: -6.37043,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "RIPOLL-EMILIO BARO",
    DOMICILIO: "EMILIO BARO, 85",
    PROVINCIA: "VALENCIA",
    CP: 46020,
    lat: 39.49442,
    long: -0.3576,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. JUAN XXIII-PAQUEVI",
    DOMICILIO: "AVDA. JUAN XXIII, 64",
    PROVINCIA: "VALENCIA",
    CP: 46025,
    lat: 39.49559,
    long: -0.38377,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP RIU TURIA",
    DOMICILIO: "C/L'ALITRA, S/N ((FRENTE HOSPITAL M",
    PROVINCIA: "VALENCIA",
    CP: 46930,
    lat: 39.50475,
    long: -0.4998,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. OLLER",
    DOMICILIO: "C/ OLLER, 1",
    PROVINCIA: "VALENCIA",
    CP: 46988,
    lat: 39.51759,
    long: -0.45543,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. FUENTE DEL JARRO",
    DOMICILIO: "POL. F.DEL JARRO-CIUDAD DE LIRIA ,15",
    PROVINCIA: "VALENCIA",
    CP: 46988,
    lat: 39.51835,
    long: -0.46021,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "TRUJILLO I',
    DOMICILIO: "CN-V KM.245,200",
    PROVINCIA: "CACERES",
    CP: 10200,
    lat: 39.51853,
    long: -5.83554,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "CHEMA BALLESTER",
    DOMICILIO: "POL.40 PARC.7 PARTIDA VALENCIA LA VELLA",
    PROVINCIA: "VALENCIA",
    CP: 46190,
    lat: 39.52111,
    long: -0.529684,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "TRUJILLO II',
    DOMICILIO: "CN-V KM.244,550",
    PROVINCIA: "CACERES",
    CP: 10200,
    lat: 39.52616,
    long: -5.83343,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "DISTRIBUCIONES VELENOIL",
    DOMICILIO: "A3 KM 268",
    PROVINCIA: "VALENCIA",
    CP: 46340,
    lat: 39.5307,
    long: -1.1655,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.HOFIRA, S.L.",
    DOMICILIO: "AVDA.SEMINARIO, 30",
    PROVINCIA: "VALENCIA",
    CP: 46113,
    lat: 39.54543,
    long: -0.38783,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "AUTO SERVICIO ROMERO -D-",
    DOMICILIO: "ALTO SAN AGUSTIN S/N",
    PROVINCIA: "VALENCIA",
    CP: 46300,
    lat: 39.56289,
    long: -1.21377,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. RIPOLL - UTIEL - 1",
    DOMICILIO: "CN-III KM. 262,5",
    PROVINCIA: "VALENCIA",
    CP: 46300,
    lat: 39.56355,
    long: -1.19712,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "A.S  MOTILLANA S.A.",
    DOMICILIO: "CTRA. ALBACETE N-320 KM.69",
    PROVINCIA: "CUENCA",
    CP: 16200,
    lat: 39.5654,
    long: -1.91342,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "TAROIL MONCADA",
    DOMICILIO: "CTRA. MONCADA-NAQUERA KM. 3,400",
    PROVINCIA: "VALENCIA",
    CP: 46113,
    lat: 39.57208,
    long: -0.39917,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL BETERA",
    DOMICILIO: "CTRA. SAN ANTONIO BENAGEBER KM 3,5",
    PROVINCIA: "VALENCIA",
    CP: 46117,
    lat: 39.582705,
    long: -0.4698,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.LA POBLA DE VALLBONA",
    DOMICILIO: "PARTIDA DE LA MALLETA S/N",
    PROVINCIA: "VALENCIA",
    CP: 46185,
    lat: 39.583714,
    long: -0.531872,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES SON OLIVA",
    DOMICILIO: "C/MUSICO ISAAC ALBENIZ S/N",
    PROVINCIA: "BALEARES",
    CP: "07009",
    lat: 39.58632,
    long: 2.66194,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.MOTILLA",
    DOMICILIO: "CN-III KM.197",
    PROVINCIA: "CUENCA",
    CP: 16200,
    lat: 39.58983,
    long: -1.91244,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL PALMA MALLORCA",
    DOMICILIO: "CARRER TER ESQUINA CARRER GUADIANA",
    PROVINCIA: "BALEARES",
    CP: "07009",
    lat: 39.591543,
    long: 2.674012,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "MALLORCA 2',
    DOMICILIO: "CAMINO DE LOS REYES, 201",
    PROVINCIA: "BALEARES",
    CP: "07010",
    lat: 39.59302,
    long: 2.63366,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. SON CASTELLO",
    DOMICILIO: "AVDA. GRAN VIA ASIMA POL. SON CASTELLO",
    PROVINCIA: "BALEARES",
    CP: "07009",
    lat: 39.605798,
    long: 2.669534,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "GESTIESTACION S.L.",
    DOMICILIO: "CAMI DE LA MAR Nº1",
    PROVINCIA: "VALENCIA",
    CP: 46530,
    lat: 39.61297,
    long: -0.28572,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.LLIRIA",
    DOMICILIO: "C/LLANO DEL ARCO S/N",
    PROVINCIA: "VALENCIA",
    CP: 46160,
    lat: 39.63569,
    long: -0.59007,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": 'E.S.FARRUCO II " LA PUEB',
    DOMICILIO: "CC-502 KM.163",
    PROVINCIA: "TOLEDO",
    CP: 45516,
    lat: 39.85575,
    long: -4.35925,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ES.SA TOLEDO",
    DOMICILIO: "AV. BOLADIEZ S/N, C.COMERCIAL LUZ D TAJO",
    PROVINCIA: "TOLEDO",
    CP: 45007,
    lat: 39.86299,
    long: -3.97301,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TOLEDO",
    DOMICILIO: "AVDA.RIO MARCHES 8 ESQ. C/ RIO ESTENILLA",
    PROVINCIA: "TOLEDO",
    CP: 45007,
    lat: 39.86417,
    long: -3.96989,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. TALAVERA",
    DOMICILIO: "CTRA. DE MADRID, KM. 113,670",
    PROVINCIA: "TOLEDO",
    CP: 45600,
    lat: 39.947615,
    long: -4.831121,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA TORREHIERRO",
    DOMICILIO: "AVDA. DE PORTUGAL, 66",
    PROVINCIA: "TOLEDO",
    CP: 45600,
    lat: 39.95756,
    long: -4.84981,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. EDAN, S.A.",
    DOMICILIO: "AVDA. EXTREMADURA, 25",
    PROVINCIA: "TOLEDO",
    CP: 45600,
    lat: 39.9617,
    long: -4.82131,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.TALVAR, S.L.",
    DOMICILIO: "AVDA.EXTREMADURA,1",
    PROVINCIA: "TOLEDO",
    CP: 45600,
    lat: 39.96173,
    long: -4.82552,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "CIUDAD REAL",
    DOMICILIO: "POL. IND. LARACHE, PARCELA 93-95 S/N",
    PROVINCIA: "CIUDAD REAL",
    CP: 13005,
    lat: 39.97,
    long: -3.96,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. ESTAGRAO, S.L.",
    DOMICILIO: "AVDA. HNOS BOU S/N",
    PROVINCIA: "CASTELLON",
    CP: 12100,
    lat: 39.97267,
    long: 0.00852,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "EE.SS. TORRIJOS S.L.",
    DOMICILIO: "AVDA.TOLEDO, Nº39",
    PROVINCIA: "TOLEDO",
    CP: 45500,
    lat: 39.97869,
    long: -4.26879,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "AREA DE SERVICIO MARBEL",
    DOMICILIO: "AVDA. TOLEDO, 2",
    PROVINCIA: "TOLEDO",
    CP: 45500,
    lat: 39.97995,
    long: -4.27455,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP-ALOD",
    DOMICILIO: "TRAVESIA DEL DESVIO 19",
    PROVINCIA: "TOLEDO",
    CP: 45250,
    lat: 39.9847,
    long: -3.7554,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.BP VILLANUEVA MALAGA",
    DOMICILIO: "AVDA DE VELAZQUEZ, 290",
    PROVINCIA: "MALAGA",
    CP: 29004,
    lat: 39.99268,
    long: -0.05494,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ROTONDA, S.L.",
    DOMICILIO: "CTRA.MADRID-VALENCIA, KM-81",
    PROVINCIA: "CUENCA",
    CP: 16400,
    lat: 39.99972,
    long: -3.03912,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.FARRUCO VIII",
    DOMICILIO: "CTRA.TO-453 KM.14",
    PROVINCIA: "TOLEDO",
    CP: 45500,
    lat: 40.00365,
    long: -4.27794,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. AREA 62 EL PUEYO",
    DOMICILIO: "AUTOVIA A - 22   PK. 62",
    PROVINCIA: "HUESCA",
    CP: 22300,
    lat: 40.02616,
    long: 0.05325,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LOS PALANCARES",
    DOMICILIO: "CTRA DE VALENCIA KM.85,400",
    PROVINCIA: "CUENCA",
    CP: 16004,
    lat: 40.05512,
    long: -2.12598,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "CUENCA',
    DOMICILIO: "C/FERMIN CABALLERO, 19",
    PROVINCIA: "CUENCA",
    CP: 16004,
    lat: 40.0675,
    long: -2.13505,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SESENA DCHO",
    DOMICILIO: "AUTOVIA A-4 KM.36 (M/DCHO)",
    PROVINCIA: "TOLEDO",
    CP: 45223,
    lat: 40.104961,
    long: -3.645929,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SESEÑA IZQ.",
    DOMICILIO: "AUTOVIA A-4 KM.36",
    PROVINCIA: "TOLEDO",
    CP: 45223,
    lat: 40.105364,
    long: -3.645105,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL SESEÑA",
    DOMICILIO: "CTRA. CUESTA REINA, N19",
    PROVINCIA: "TOLEDO",
    CP: 45223,
    lat: 40.10537,
    long: -3.682786,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP REOCIN",
    DOMICILIO: "CTRA. CM 4010 KM 2 P.I. LOS PRADILLOS",
    PROVINCIA: "TOLEDO",
    CP: 45200,
    lat: 40.13088,
    long: -3.82211,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ILLESCAS",
    DOMICILIO: "POL.IND.BOADILLA, PARCELAS 2,3,4 DE M8",
    PROVINCIA: "TOLEDO",
    CP: 45200,
    lat: 40.13397,
    long: -3.83443,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ILLESCAS",
    DOMICILIO: "CN-401 KM.32",
    PROVINCIA: "TOLEDO",
    CP: 45200,
    lat: 40.15082,
    long: -3.82333,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL PARLA",
    DOMICILIO: "AVENIDA DE LAS LAGUNAS, Nº1",
    PROVINCIA: "MADRID",
    CP: 28950,
    lat: 40.243548,
    long: -3.777784,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MORALEJA DE ENMEDIO",
    DOMICILIO: "AVDA FUENLABRADA. 32",
    PROVINCIA: "MADRID",
    CP: 28950,
    lat: 40.261525,
    long: -3.849855,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "M-50",
    DOMICILIO: "C/ RIO ODIEL ESQ. AVDA.GUADALQUIVIR",
    PROVINCIA: "MADRID",
    CP: 28906,
    lat: 40.271307,
    long: -3.701448,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "COSTCO WHOLESALE GETAFE",
    DOMICILIO: "AGUSTIN DE BETANCOURT, 17",
    PROVINCIA: "MADRID",
    CP: 28906,
    lat: 40.283312,
    long: -3.689361,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "LOS ANGELES',
    DOMICILIO: "CN-IV KM.14,3",
    PROVINCIA: "MADRID",
    CP: 28906,
    lat: 40.296761,
    long: -3.692297,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LOS ANGELES II",
    DOMICILIO: "A-4 P.K. 14,3 (DIRECC: CADIZ-MADRID)M.IZ",
    PROVINCIA: "MADRID",
    CP: 28906,
    lat: 40.29699,
    long: -3.690945,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ARGANDA",
    DOMICILIO: "AV DEL EJERCITO, 44",
    PROVINCIA: "MADRID",
    CP: 28500,
    lat: 40.30333,
    long: -3.448524,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP GETAFE",
    DOMICILIO: "CL DIESEL C/V P JOHN LENNON, S.N.",
    PROVINCIA: "MADRID",
    CP: 28906,
    lat: 40.303934,
    long: -3.702421,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LA ARGANDEÑA",
    DOMICILIO: "AVD DE MADRID Nº 37",
    PROVINCIA: "MADRID",
    CP: 28500,
    lat: 40.305964,
    long: -3.463204,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MAGILSA, S.L.",
    DOMICILIO: "CALLE E, 14",
    PROVINCIA: "MADRID",
    CP: 28935,
    lat: 40.308753,
    long: -3.873753,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. MOVILOIL",
    DOMICILIO: "C/ E, 7 POLIGONO Nº 1",
    PROVINCIA: "MADRID",
    CP: 28938,
    lat: 40.309022,
    long: -3.873811,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "REPSOLATENOIL ARGAND 103",
    DOMICILIO: "AVD DE MADRID 103",
    PROVINCIA: "MADRID",
    CP: 28500,
    lat: 40.309779,
    long: -3.480424,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ATENOILARGANDA AV.MAD126",
    DOMICILIO: "AVD DE MADRID 126",
    PROVINCIA: "MADRID",
    CP: 28500,
    lat: 40.309824,
    long: -3.480493,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA ARGANDA",
    DOMICILIO: "AVDA. VALDEARGANDA Nº 10",
    PROVINCIA: "MADRID",
    CP: 28500,
    lat: 40.310178,
    long: -3.45002,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP XANADU",
    DOMICILIO: "AVDA. MORALEJA DEL MEDIO, 3",
    PROVINCIA: "MADRID",
    CP: 28938,
    lat: 40.313817,
    long: -3.869203,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MOSTOLES IZQDO",
    DOMICILIO: "CN-V KM.20,900",
    PROVINCIA: "MADRID",
    CP: 28935,
    lat: 40.316495,
    long: -3.896885,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.MOSTOLES DCHO",
    DOMICILIO: "CN-V KM.20,900",
    PROVINCIA: "MADRID",
    CP: 28935,
    lat: 40.31661,
    long: -3.898172,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA AIDATER I",
    DOMICILIO: "N VA, PK.19.2, MRG DERECHO (DIR.BADAJOZ)",
    PROVINCIA: "MADRID",
    CP: 28935,
    lat: 40.320177,
    long: -3.876307,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL AV. ANDALUCIA",
    DOMICILIO: "AV. ANDALUCÍA Nº83",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.32763,
    long: -3.692043,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL VILLAVERDE",
    DOMICILIO: "CM-403, KM-2 (GETAFE-VILLAVERDE)",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.329117,
    long: -3.71593,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S OIL 42",
    DOMICILIO: "AVDA. PRINCESA JUANA DE AUTRIA, 91",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.333994,
    long: -3.724105,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LOS ROBLES",
    DOMICILIO: "AVDA. REAL DE PINTO, 106",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.339152,
    long: -3.709452,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "BUTARQUE A. DE SERVICIO",
    DOMICILIO: "C/HULLA, 22",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.340392,
    long: -3.676365,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA ALCORCON",
    DOMICILIO: "CALLE LAGUNA CRUCE CARRETERA LEGANÉS",
    PROVINCIA: "MADRID",
    CP: 28923,
    lat: 40.340906,
    long: -3.802224,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "URTINSA",
    DOMICILIO: "C/ EBANISTAS, 2/AVDA.LEGANES P.I.URTINSA",
    PROVINCIA: "MADRID",
    CP: 28923,
    lat: 40.342166,
    long: -3.805246,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. OLIMPICA",
    DOMICILIO: "C/ FRAGUAS, Nº 1",
    PROVINCIA: "MADRID",
    CP: 28923,
    lat: 40.342408,
    long: -3.814238,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.AVDA.DE LOS ROSALES",
    DOMICILIO: "CTRA. M-301, 147",
    PROVINCIA: "MADRID",
    CP: 28021,
    lat: 40.34916,
    long: -3.66944,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "REPSOL  RIVAS AV.TECNICA",
    DOMICILIO: "AV. LOS ALMENDROS, S/N,",
    PROVINCIA: "MADRID",
    CP: 28522,
    lat: 40.35423,
    long: -3.54963,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CHEYPER",
    DOMICILIO: "CR A-42 MADRID - TOLEDO KM 5.9",
    PROVINCIA: "MADRID",
    CP: 28025,
    lat: 40.36832,
    long: 3.720767,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "CARABANCHEL',
    DOMICILIO: "CTRA.ORCASITAS-CARABANCHEL, KM.0,5",
    PROVINCIA: "MADRID",
    CP: 28025,
    lat: 40.374667,
    long: -3.374667,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA MAFA",
    DOMICILIO: "CALLE MONTE IGUELDO Nº 108",
    PROVINCIA: "MADRID",
    CP: 28053,
    lat: 40.390405,
    long: -3.671095,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LEGAZPI",
    DOMICILIO: "PLAZA DE LEGAZPI, 9",
    PROVINCIA: "MADRID",
    CP: 28045,
    lat: 40.391655,
    long: -3.695413,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. PUENTE DE PRAGA",
    DOMICILIO: "PASEO SANTA MARIA DE LA CABEZA, 90",
    PROVINCIA: "MADRID",
    CP: 28045,
    lat: 40.397815,
    long: -3.702551,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "E.S. MACE, S.L.",
    DOMICILIO: "STA. MARIA CABEZA, 18",
    PROVINCIA: "MADRID",
    CP: 28045,
    lat: 40.40596,
    long: -3.695199,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "PRADO DEL REY ( Somosagu",
    DOMICILIO: "VIA SERVICIO M.502 PK 3,600",
    PROVINCIA: "MADRID",
    CP: 28223,
    lat: 40.413153,
    long: -3.788481,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP SOMOSAGUAS",
    DOMICILIO: "AV. DE RADIO TV ESPAÑOLA 1",
    PROVINCIA: "MADRID",
    CP: 28223,
    lat: 40.413862,
    long: -3.788923,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. ALFRESNO 23",
    DOMICILIO: "C/ BARLOVENTO, 1 -C.C.ZOCO POZUELO-",
    PROVINCIA: "MADRID",
    CP: 28223,
    lat: 40.420104,
    long: -3.798206,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "AYESSA",
    DOMICILIO: "AVENIDA DE JOSE GARATE Nº 2",
    PROVINCIA: "MADRID",
    CP: 28823,
    lat: 40.426961,
    long: -3.5515,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CEDIPSA ALCONERA",
    DOMICILIO: "CALLE POBLADURA DEL VALLE Nº 31",
    PROVINCIA: "MADRID",
    CP: 28037,
    lat: 40.427218,
    long: -3.617774,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP POZUELO",
    DOMICILIO: "CM-503 KM.6,085",
    PROVINCIA: "MADRID",
    CP: 28223,
    lat: 40.427451,
    long: -3.822513,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA GANOSA",
    DOMICILIO: "HERMANOS GARCIA NOBLEJAS, 35",
    PROVINCIA: "MADRID",
    CP: 28037,
    lat: 40.432837,
    long: -3.634671,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CEA BERMUDEZ",
    DOMICILIO: "CEA BERMUDEZ,32",
    PROVINCIA: "MADRID",
    CP: 28003,
    lat: 40.439023,
    long: -3.709736,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP SANTILLANA",
    DOMICILIO: "CTRA.TORREJON-LOECHES KM.1",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.442081,
    long: -3.46374,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LAS MONJAS",
    DOMICILIO: "AVDA. DE LAS MONJAS, S/N",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.446131,
    long: -3.483267,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "TORREJON DE ARDOZ",
    DOMICILIO: "AVDA DEL SOL S/N, ESQ. AVDA.DE LA LUNA",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.447361,
    long: -3.48133,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. SUDEL, S.A.",
    DOMICILIO: "AVDA. GENERAL MOLA S/N",
    PROVINCIA: "MADRID",
    CP: 28224,
    lat: 40.44914,
    long: -3.799236,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. JUANJO",
    DOMICILIO: "CN-403 KM-102",
    PROVINCIA: "AVILA",
    CP: "05110",
    lat: 40.450719,
    long: -4.582094,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA YUCATAN",
    DOMICILIO: "GLORIETA YUCATAN S/N",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.451003,
    long: -3.626816,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. NARESCAR, S.L.",
    DOMICILIO: "AVDA.CASTILLA, 4",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.452442,
    long: -3.509477,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PETROKAL",
    DOMICILIO: "C/SOLANA 17",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.454762,
    long: -3.467171,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "ES.CERRO DE LOS GAMOS II",
    DOMICILIO: "C/ MANUEL DE FALLA, 2",
    PROVINCIA: "MADRID",
    CP: 28224,
    lat: 40.455728,
    long: -3.811837,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.CERRO DE LOS GAMOS I",
    DOMICILIO: "C/ ENRIQUE GRANADOS, 1",
    PROVINCIA: "MADRID",
    CP: 28224,
    lat: 40.45625,
    long: -3.810877,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP SARDINERO",
    DOMICILIO: "AVDA.FRONTERA ESQ.SAN ISIDRO",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.457644,
    long: -3.482945,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA CAMPO NACIONES",
    DOMICILIO: "AVENIDA CONSEJO DE EUROPA , 2",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.458369,
    long: -3.616379,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. GASOLEOS TORREJON",
    DOMICILIO: "C/ LISBOA ESQ LONDRES 30",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.460785,
    long: -3.468686,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "REPSOL ATENOIL SILVANO",
    DOMICILIO: "C/SILVANO 88-90 ESQUINA C/ALGABEÑO",
    PROVINCIA: "MADRID",
    CP: 28033,
    lat: 40.462155,
    long: -3.638545,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA BARAJAS",
    DOMICILIO: "AVDA. LOGROÑO, 233",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.463755,
    long: -3.587402,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. T2 TIERRA",
    DOMICILIO: "AEROP.BARAJAS, TERMINAL 2 (MARGEN DCHO.)",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.46396,
    long: -3.57554,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. T4 TIERRA",
    DOMICILIO: "AVDA.HISPANIDAD,44(M.DCHO)T4 AER.BARAJAS",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.4642,
    long: -3.57531,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. PROINPI, S.L.",
    DOMICILIO: "C/ EXTRAMUROS",
    PROVINCIA: "AVILA",
    CP: "05500",
    lat: 40.46486,
    long: -5.32526,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL SOTO DE HENARE",
    DOMICILIO: "AVDA. DE LA CONSTITUCION, ESQ. VIAL 23",
    PROVINCIA: "MADRID",
    CP: 28850,
    lat: 40.466229,
    long: -3.438485,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL M40",
    DOMICILIO: "TOMAS REDONDO, 10 (SALIDA 5 M40)",
    PROVINCIA: "MADRID",
    CP: 28033,
    lat: 40.46936,
    long: -3.627586,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "RIO CORVO",
    DOMICILIO: "CTRA M300 KM 26",
    PROVINCIA: "MADRID",
    CP: 28805,
    lat: 40.47055,
    long: -3.4125,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP RIO CORVO",
    DOMICILIO: "CTRA M300 KM 26",
    PROVINCIA: "MADRID",
    CP: 28805,
    lat: 40.47055,
    long: -3.4125,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.EL PILAR, S.L.",
    DOMICILIO: "AVDA.GENERAL, 22",
    PROVINCIA: "MADRID",
    CP: 28042,
    lat: 40.471058,
    long: -3.580289,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP PUENTE ARCE",
    DOMICILIO: "CTRA M300 KM 29,6",
    PROVINCIA: "MADRID",
    CP: 28805,
    lat: 40.474016,
    long: -3.40338,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MONFORTE DE LEMOS",
    DOMICILIO: "AVENIDA MONFORTE DE LEMOS 7",
    PROVINCIA: "MADRID",
    CP: 28029,
    lat: 40.477541,
    long: -3.691181,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VIA COMPLUTENSE",
    DOMICILIO: "AVENIDA VIA COMPLUTENSE 165",
    PROVINCIA: "MADRID",
    CP: 28805,
    lat: 40.497013,
    long: -3.343282,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL ALCOBENDAS",
    DOMICILIO: "CTRA.M-603 (FUENCARRAL-ALCOBENDAS) KM 15",
    PROVINCIA: "MADRID",
    CP: 28108,
    lat: 40.52883,
    long: -3.651549,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. JUNCAL",
    DOMICILIO: "AV. DE BARAJAS S/N",
    PROVINCIA: "MADRID",
    CP: 28108,
    lat: 40.533878,
    long: -3.61948,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ONGI ETORRI",
    DOMICILIO: "BULEVAR SALVADOR ALLENDE, Nº12",
    PROVINCIA: "MADRID",
    CP: 28108,
    lat: 40.537463,
    long: -3.63407,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "XELARRIZ",
    DOMICILIO: "AVENIDA EINSTEIN, 5",
    PROVINCIA: "MADRID",
    CP: 28703,
    lat: 40.544399,
    long: -3.610526,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. NAVALCARRO",
    DOMICILIO: "CC-607 KM.17,800",
    PROVINCIA: "MADRID",
    CP: 28760,
    lat: 40.562321,
    long: -3.710589,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.TRES CANTOS",
    DOMICILIO: "AVDA. DE LA INDUSTRIA N 2",
    PROVINCIA: "MADRID",
    CP: 28760,
    lat: 40.594053,
    long: -3.694518,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. TRES CANTOS",
    DOMICILIO: "AVDA. DE LA INDUSTRIA, 33",
    PROVINCIA: "MADRID",
    CP: 28760,
    lat: 40.613146,
    long: -3.700842,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEPSA TRES CANTOS",
    DOMICILIO: "PLAZA ABACO S/N",
    PROVINCIA: "MADRID",
    CP: 28760,
    lat: 40.61436,
    long: -3.70294,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. TRESCANTOS",
    DOMICILIO: "AVENIDA DE LA INDUSTRIA, Nº58",
    PROVINCIA: "MADRID",
    CP: 28760,
    lat: 40.615019,
    long: -3.702886,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.VILLALBA, S.A.-I",
    DOMICILIO: "CN-VI KM.38",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.625451,
    long: -3.99031,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CARVISA",
    DOMICILIO: "POL. IND. P-29. CALLE ESCOFINA, 17",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.628175,
    long: -4.018732,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.VILLALBA, S.A.-II",
    DOMICILIO: "CN-VI KM.39",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.628822,
    long: -3.99514,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. PETROLSAZ",
    DOMICILIO: "CAMINO DE VALDETORRES, S/N",
    PROVINCIA: "MADRID",
    CP: 28140,
    lat: 40.636428,
    long: -3.515408,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. COLMENAR",
    DOMICILIO: "CC-607 KM.28,200",
    PROVINCIA: "MADRID",
    CP: 28770,
    lat: 40.639749,
    long: -3.73076,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. FUENTE EL SAZ, S.L.",
    DOMICILIO: "C/TORRELAGUNA, 78",
    PROVINCIA: "MADRID",
    CP: 28140,
    lat: 40.641556,
    long: -3.510593,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL VILLALBA",
    DOMICILIO: "C/ ESCOFINA - POL. IND. P-29",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.642397,
    long: -4.038232,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "VILLALBA II',
    DOMICILIO: "A-6 KM.42,300",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.642533,
    long: -4.039214,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL AVILA",
    DOMICILIO: "AV.DERECHOS HUMANOS ESQ.DOCTOR CONS.BENI",
    PROVINCIA: "AVILA",
    CP: "05003",
    lat: 40.643518,
    long: -4.685449,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "VILLALBA I',
    DOMICILIO: "A-6 KM.42,300",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.643583,
    long: -4.038291,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL FUENTE EL SAZ",
    DOMICILIO: "CTRA. M-117 KM 3,100",
    PROVINCIA: "MADRID",
    CP: 28140,
    lat: 40.647792,
    long: -3.501232,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. COLLADO II",
    DOMICILIO: "CTRA. DE NAVACERRADA (M-601) KM. 1,333",
    PROVINCIA: "MADRID",
    CP: 28400,
    lat: 40.648634,
    long: -4.008003,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA SONSOLES",
    DOMICILIO: "CARRETERA BURGOHONDO, PK. 1.100",
    PROVINCIA: "AVILA",
    CP: "05002",
    lat: 40.649222,
    long: -4.703861,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL COLMENAR",
    DOMICILIO: "AVDA. DE LA LIBERTAD,62",
    PROVINCIA: "MADRID",
    CP: 28770,
    lat: 40.658479,
    long: -3.75991,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CUATRO POSTES",
    DOMICILIO: "CTRA. SALAMANCA, Nº 21",
    PROVINCIA: "AVILA",
    CP: "05002",
    lat: 40.661444,
    long: -4.711657,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. MERIODO S.L.",
    DOMICILIO: "C/ CERRO SAN PEDRO, 4",
    PROVINCIA: "MADRID",
    CP: 28770,
    lat: 40.662671,
    long: -3.759545,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL PINAR DE AVILA",
    DOMICILIO: "JORGE DE SANTAYANA, S/N P.I HERVENCIAS",
    PROVINCIA: "AVILA",
    CP: "05004",
    lat: 40.667548,
    long: -4.685516,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL PINAR DE AVILA",
    DOMICILIO: "JORGE DE SANTAYANA, S/N P.I HERVENCIAS",
    PROVINCIA: "AVILA",
    CP: "05004",
    lat: 40.667548,
    long: -4.685516,
  },
  {
    BANDERA: "VALCARCE",
    "NOMBRE TPV": "DST AVILA",
    DOMICILIO: "C/ RIO PISUERGA 21-22",
    PROVINCIA: "AVILA",
    CP: "05004",
    lat: 40.668652,
    long: -4.684698,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA  AMPOSTA",
    DOMICILIO: "CN-340 KM.1078",
    PROVINCIA: "TARRAGONA",
    CP: 43870,
    lat: 40.680589,
    long: 0.584126,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. AMPOSTA",
    DOMICILIO: "SANT CRISTOFOL, S/N. PARKING EROSKI",
    PROVINCIA: "TARRAGONA",
    CP: 43870,
    lat: 40.703167,
    long: 0.585667,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RIU EBRE",
    DOMICILIO: "AVDA. SAN JAIME S/N",
    PROVINCIA: "TARRAGONA",
    CP: 43870,
    lat: 40.71092,
    long: 0.58575,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S MONTSIA",
    DOMICILIO: "C/ GRAU (BAIXADA) S/N",
    PROVINCIA: "TARRAGONA",
    CP: 43870,
    lat: 40.716821,
    long: 0.574759,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA CAMINREAL",
    DOMICILIO: "P.I EL GANCHO PARCELAS 6, 7 Y 8",
    PROVINCIA: "TERUEL",
    CP: 44350,
    lat: 40.837611,
    long: -1.305361,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ALAS EE.SS., S.L.",
    DOMICILIO: "CN-340 KM.1110",
    PROVINCIA: "TARRAGONA",
    CP: 43860,
    lat: 40.89651,
    long: 0.74799,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA LASTRILLA",
    DOMICILIO: "CTRA. VALLADOLID - SEGOVIA P.K. 105",
    PROVINCIA: "SEGOVIA",
    CP: 40196,
    lat: 40.965364,
    long: -4.106138,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 LA LASTRILLA",
    DOMICILIO: "Ctra. N-110 a Soria Km. 189 - Parking Su",
    PROVINCIA: "SEGOVIA",
    CP: 40196,
    lat: 40.965917,
    long: -4.098417,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S.A.C.M., S.L.-ANDORRA",
    DOMICILIO: "POL.IND.LA UMBRIA, Nº33",
    PROVINCIA: "TERUEL",
    CP: 44500,
    lat: 40.98228,
    long: -0.44783,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S.SHELL DORADA MONTAÑA",
    DOMICILIO: "CTRA. N-340 VALENCIA-BARCELONA 1141",
    PROVINCIA: "TARRAGONA",
    CP: 43850,
    lat: 41.063124,
    long: 1.023408,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL CAMBRILS",
    DOMICILIO: "CTRA.N-340 VALENCIA-BARCELONA KM 1144,75",
    PROVINCIA: "TARRAGONA",
    CP: 43850,
    lat: 41.075844,
    long: 1.059276,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "AREA CAMBRILS COOPERATIV",
    DOMICILIO: "CTRA. MONTBRIO EL CAMP (T-312) KM 2,2",
    PROVINCIA: "TARRAGONA",
    CP: 43850,
    lat: 41.08559,
    long: 1.03727,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL FRANCOLI",
    DOMICILIO: "A-1, KM-3",
    PROVINCIA: "TARRAGONA",
    CP: 43006,
    lat: 41.104355,
    long: 1.196382,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP TARRAGONA CITY",
    DOMICILIO: "CRTA. VELLA DE VALENCIA 5",
    PROVINCIA: "TARRAGONA",
    CP: 43006,
    lat: 41.116155,
    long: -1.232207,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. TARRAGONA",
    DOMICILIO: "CN-340 KM.248",
    PROVINCIA: "TARRAGONA",
    CP: 43006,
    lat: 41.116586,
    long: 1.228095,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA TORREFORTA",
    DOMICILIO: "C/ FRANCOLÓ Nº 75",
    PROVINCIA: "TARRAGONA",
    CP: 43006,
    lat: 41.120229,
    long: 1.219229,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "ES LA ROUREDA",
    DOMICILIO: "CR N420 P.K. 876.84 I",
    PROVINCIA: "TARRAGONA",
    CP: 43204,
    lat: 41.12429,
    long: 1.21245,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "TARRAGONA',
    DOMICILIO: "AUTOVIA TARRAGONA-REUS KM.3,7",
    PROVINCIA: "TARRAGONA",
    CP: 43006,
    lat: 41.124586,
    long: 1.214011,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA RIUDARENES",
    DOMICILIO: "CTRA. TV-7211 D, KM. 9,43",
    PROVINCIA: "TARRAGONA",
    CP: 43120,
    lat: 41.148193,
    long: 1.214796,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 HIJAR",
    DOMICILIO: "N-232, KM. 164,400",
    PROVINCIA: "TERUEL",
    CP: 44530,
    lat: 41.170611,
    long: -0.442194,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 EL VENDRELL 2",
    DOMICILIO: "Ctra. C-246 Km 61,970 - Junto Supermerca",
    PROVINCIA: "TARRAGONA",
    CP: 43700,
    lat: 41.215389,
    long: 1.546472,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VILANOVA I",
    DOMICILIO: "AV JAUME BALMES 35",
    PROVINCIA: "BARCELONA",
    CP: "08800",
    lat: 41.21933,
    long: 1.721817,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.MONSERRAT CIRBIAN RO",
    DOMICILIO: "JAIME CARNER S/N",
    PROVINCIA: "TARRAGONA",
    CP: 43700,
    lat: 41.219611,
    long: 1.536683,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. EL VENDRELL",
    DOMICILIO: "C/ JAUME CARNER ESQ. C/ DEL MAR",
    PROVINCIA: "TARRAGONA",
    CP: 43700,
    lat: 41.21983,
    long: 1.5369,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. EL VENDRELL EXPRESS",
    DOMICILIO: "C/ LAS MATAS, POL.IND.LAS MATAS",
    PROVINCIA: "TARRAGONA",
    CP: 43700,
    lat: 41.22492,
    long: 1.54425,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA VILANOVA 4",
    DOMICILIO: "RONDA EUROPA S/N",
    PROVINCIA: "BARCELONA",
    CP: "08800",
    lat: 41.227439,
    long: 1.739927,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "CES.VILANOVA I LA GELTRU",
    DOMICILIO: "RONDA EUROPA Nº58",
    PROVINCIA: "BARCELONA",
    CP: "08800",
    lat: 41.22915,
    long: 1.73834,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VILANOVA II",
    DOMICILIO: "AV. EDUARDO TOLDRA 68",
    PROVINCIA: "BARCELONA",
    CP: "08800",
    lat: 41.230105,
    long: 1.739179,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.GASFELS, S.L.",
    DOMICILIO: "AVDA.CONSTITUCION, 364",
    PROVINCIA: "BARCELONA",
    CP: "08860",
    lat: 41.267186,
    long: 1.953775,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "CASTELLDEFELS',
    DOMICILIO: "C/ GRANADA, 20-24",
    PROVINCIA: "BARCELONA",
    CP: "08860",
    lat: 41.27057,
    long: 1.97988,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VILADECANS",
    DOMICILIO: "CTRA. C-32, KM. 186,58",
    PROVINCIA: "BARCELONA",
    CP: "08840",
    lat: 41.275121,
    long: 2.048097,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VILADECANS II",
    DOMICILIO: "CC-246 KM.12,5",
    PROVINCIA: "BARCELONA",
    CP: "08840",
    lat: 41.275404,
    long: 1.974202,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. RAMIRO",
    DOMICILIO: "CTRA. A-6 KM 154",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.275646,
    long: -4.887918,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CASTELLDEFELS",
    DOMICILIO: "C/ AVENIDA, 303",
    PROVINCIA: "BARCELONA",
    CP: "08860",
    lat: 41.27807,
    long: 1.96824,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CASTELLDEFELS II",
    DOMICILIO: "AVDA. CONSTITUCION, Nº99",
    PROVINCIA: "BARCELONA",
    CP: "08860",
    lat: 41.282852,
    long: 1.979532,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. EL PRAT LLOBREGAT",
    DOMICILIO: "AEROPUERTO DEL PRAT JUNTO PARRILLA TAXI",
    PROVINCIA: "BARCELONA",
    CP: "08820",
    lat: 41.297467,
    long: 2.083233,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 MEDINA DEL CAMPO",
    DOMICILIO: "Ctra de Medina a PeNaranda s/n. - Detras",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.300389,
    long: -4.907694,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.MEDINA DEL CAMPO",
    DOMICILIO: "CN-VI KM.157,300",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.301862,
    long: -4.909011,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 GAVA",
    DOMICILIO: "CALLE ENGINY, 30-32. PI LES MASSOTES",
    PROVINCIA: "BARCELONA",
    CP: "08850",
    lat: 41.302329,
    long: -2.014786,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "GASOLINERAS EL CANUELO",
    DOMICILIO: "AVDA. REGIMIENTO DE ARTILLERÍA, Nº 1",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.305056,
    long: -4.911134,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA BOBILA BP",
    DOMICILIO: "AVDA. JUAN CARLOS I,ESQ.CON CARRE ARGILA",
    PROVINCIA: "BARCELONA",
    CP: "08850",
    lat: 41.307394,
    long: 1.990921,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP VILADECANS",
    DOMICILIO: "CL AGRICULTURA 19",
    PROVINCIA: "BARCELONA",
    CP: "08840",
    lat: 41.310205,
    long: 2.018338,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MEDINA PUEBLO",
    DOMICILIO: "AVDA. DE LA CONSTITUCION, 54",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.318125,
    long: -4.916736,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LA RIERA",
    DOMICILIO: "C/FEDERICA MONTSENY ESQ.AVD.APEL.LES MES",
    PROVINCIA: "BARCELONA",
    CP: "08820",
    lat: 41.323805,
    long: 2.083022,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "FRESNOSOLAR",
    DOMICILIO: "P.I FRANCISTO LOBATO, C/ALARIFES, 3-5",
    PROVINCIA: "VALLADOLID",
    CP: 47400,
    lat: 41.32432,
    long: -4.91605,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CEDIPSA LA SENTIU",
    DOMICILIO: "CTRA. C-245, KM. 5",
    PROVINCIA: "BARCELONA",
    CP: "08850",
    lat: 41.325644,
    long: 1.989834,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "LA PAUSA',
    DOMICILIO: "CC-246 KM.7,500",
    PROVINCIA: "BARCELONA",
    CP: "08820",
    lat: 41.325656,
    long: 2.087453,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA -NOU PRAT-",
    DOMICILIO: "AVDA.REMOLAR, 37-39",
    PROVINCIA: "BARCELONA",
    CP: "08820",
    lat: 41.325841,
    long: 2.087136,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. EL PRAT, S.L.",
    DOMICILIO: "AVDA.JOSEP ANSELM CLAVE, 106",
    PROVINCIA: "BARCELONA",
    CP: "08820",
    lat: 41.33327,
    long: 2.09913,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "CARIÑENA',
    DOMICILIO: "CN-330 KM.449,6",
    PROVINCIA: "ZARAGOZA",
    CP: 50400,
    lat: 41.335589,
    long: 1.219681,
  },
  {
    BANDERA: "PETROMIRALLES",
    "NOMBRE TPV": "E.S. PETROMIRALLES, S.L.",
    DOMICILIO: "C/ LA MUNIA ESQ. C/LA RAPITA",
    PROVINCIA: "BARCELONA",
    CP: "08720",
    lat: 41.338939,
    long: 1.687689,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. VILAFRANCA",
    DOMICILIO: "AV. BARCELONA, 34-36",
    PROVINCIA: "BARCELONA",
    CP: "08720",
    lat: 41.34616,
    long: 1.70371,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. CORNELLA II",
    DOMICILIO: "CAMI DEL MIG, 23",
    PROVINCIA: "BARCELONA",
    CP: "08940",
    lat: 41.346987,
    long: 2.090223,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ECOBENZ",
    DOMICILIO: "AVDA. JOAN CARLES I, 16-20",
    PROVINCIA: "BARCELONA",
    CP: "08908",
    lat: 41.348436,
    long: 2.133676,
  },
  {
    BANDERA: "MEROIL",
    "NOMBRE TPV": "E.S.SERV. PENEDES,S.A.",
    DOMICILIO: "CTRA. IGUALADA S/N",
    PROVINCIA: "BARCELONA",
    CP: "08720",
    lat: 41.35055,
    long: 1.705561,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA FIRA",
    DOMICILIO: "C/ BOTANICA, 21-23",
    PROVINCIA: "BARCELONA",
    CP: "08908",
    lat: 41.352561,
    long: 2.133751,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. RONDA 15",
    DOMICILIO: "AVDA.DE LA JANA, 2-10",
    PROVINCIA: "BARCELONA",
    CP: "08940",
    lat: 41.353786,
    long: 2.080828,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ULTRA",
    DOMICILIO: "RAMBLA MARINA 428",
    PROVINCIA: "BARCELONA",
    CP: "08907",
    lat: 41.35431,
    long: 2.10876,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP EL CARRILET",
    DOMICILIO: "AVDA EL CARRILET 269",
    PROVINCIA: "BARCELONA",
    CP: "08907",
    lat: 41.358901,
    long: 2.106451,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. ALPS",
    DOMICILIO: "AVDA. JOSEP TARRADELLAS, 76",
    PROVINCIA: "BARCELONA",
    CP: "08901",
    lat: 41.366111,
    long: 2.113778,
  },
  {
    BANDERA: "MEROIL",
    "NOMBRE TPV": "E.S. FONTSANTA",
    DOMICILIO: "AVDA. BARCELONA, 41-43",
    PROVINCIA: "BARCELONA",
    CP: "08970",
    lat: 41.367614,
    long: 2.061723,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "BAIX LLOBREGAT",
    DOMICILIO: "AVDA.BAIX DE LLOBREGAT S/N",
    PROVINCIA: "BARCELONA",
    CP: "08970",
    lat: 41.371241,
    long: 2.077588,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "PETROCAT SANT JOAN DESPI",
    DOMICILIO: "CBU-2004 KM.2",
    PROVINCIA: "BARCELONA",
    CP: "08970",
    lat: 41.371426,
    long: 2.049857,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CAN FATJO",
    DOMICILIO: "CTRA. SANT JOAN DESPI- BV-2001 KM 0,19",
    PROVINCIA: "BARCELONA",
    CP: "08940",
    lat: 41.372229,
    long: 2.049551,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LA SARDANA",
    DOMICILIO: "PLAZA DE LA SARDANA, 3-4",
    PROVINCIA: "BARCELONA",
    CP: "08950",
    lat: 41.372269,
    long: 2.082944,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CAMPO SAGRADO",
    DOMICILIO: "AVDA. MARQUÉS DE CAMPO SAGRADO",
    PROVINCIA: "BARCELONA",
    CP: "08015",
    lat: 41.376273,
    long: 2.164353,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. ARAGON-CASANOVA",
    DOMICILIO: "CASANOVA, 89",
    PROVINCIA: "BARCELONA",
    CP: "08011",
    lat: 41.386226,
    long: 2.157486,
  },
  {
    BANDERA: "PETROMIRALLES",
    "NOMBRE TPV": "PETROMIRALLES-ST.FELIU",
    DOMICILIO: "CTRA. INDUSTRIA, 23, ESQ. SANT JOSEP",
    PROVINCIA: "BARCELONA",
    CP: "08980",
    lat: 41.386943,
    long: 2.037527,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "NORTE',
    DOMICILIO: "C/ROGER DE FLOR, 58 ESQUINA C/RIBAS",
    PROVINCIA: "BARCELONA",
    CP: "08013",
    lat: 41.392703,
    long: 2.180872,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. SERPESA",
    DOMICILIO: "C/ SANSON S/N",
    PROVINCIA: "BARCELONA",
    CP: "08980",
    lat: 41.394019,
    long: 2.050973,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.CUELLAR",
    DOMICILIO: "CN-601 KM.51",
    PROVINCIA: "SEGOVIA",
    CP: 40200,
    lat: 41.39921,
    long: -4.30865,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ALMOGAVARES SERVICIOS,SL",
    DOMICILIO: "C/ALMOGAVARES, 159",
    PROVINCIA: "BARCELONA",
    CP: "08018",
    lat: 41.39926,
    long: 2.19268,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CARRER VALENCIA",
    DOMICILIO: "C/VALENCIA, 503",
    PROVINCIA: "BARCELONA",
    CP: "08013",
    lat: 41.40568,
    long: 2.179609,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA ESTOP",
    DOMICILIO: "CALLE CLOT, N° 2",
    PROVINCIA: "BARCELONA",
    CP: "08018",
    lat: 41.405838,
    long: 2.187554,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MOLLET",
    DOMICILIO: "RONDA FARINERA SN",
    PROVINCIA: "BARCELONA",
    CP: "08100",
    lat: 41.405917,
    long: 2.176542,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. VIRGEN MONTSERRAT",
    DOMICILIO: "AVDA. MARE DE DEU DE MONTSERRAT, 87",
    PROVINCIA: "BARCELONA",
    CP: "08024",
    lat: 41.415479,
    long: 2.167065,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. P  VALLDAURA",
    DOMICILIO: "PASEO VALLDAURA, 112",
    PROVINCIA: "BARCELONA",
    CP: "08031",
    lat: 41.439489,
    long: 2.163118,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. LA MAQUINISTA",
    DOMICILIO: "C/ CIUDAD ASUNCIÓN, 69-71",
    PROVINCIA: "BARCELONA",
    CP: "08030",
    lat: 41.442457,
    long: 2.199427,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SANTA COLOMA II",
    DOMICILIO: "AVDA.GENERALITAT, 97",
    PROVINCIA: "BARCELONA",
    CP: "08921",
    lat: 41.44437,
    long: 2.21069,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA STA. COLOMA",
    DOMICILIO: "C/MOZART, 24 ESQUINA NÁPOLES Nº 63",
    PROVINCIA: "BARCELONA",
    CP: "08921",
    lat: 41.454324,
    long: 2.21732,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MONTCADA I REIXAC",
    DOMICILIO: "CTRA. C-17 KM.1,700",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.466907,
    long: 2.177753,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. PORTAL DEL VALLES",
    DOMICILIO: "AUTOP. C-58 KM.2,800",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.469474,
    long: 2.166914,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. PORTAL DEL VALLES",
    DOMICILIO: "AUTOP.A-18 KM.2,800",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.470574,
    long: 2.166063,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S MUEL 255",
    DOMICILIO: "AUTOVÍA A-23, KM. 255",
    PROVINCIA: "ZARAGOZA",
    CP: 50450,
    lat: 41.472487,
    long: -1.101457,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. RALLY 1",
    DOMICILIO: "AVD.ELECTRICIDAD,S/N EQ.COMPOSITOR CHAPI",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.474044,
    long: 2.035138,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP CAN SOLA",
    DOMICILIO: "AVENIDA CERDANYOLA, 108",
    PROVINCIA: "BARCELONA",
    CP: "08173",
    lat: 41.477274,
    long: 2.09694,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ALMAZAN",
    DOMICILIO: "CN-111 KM.192",
    PROVINCIA: "SORIA",
    CP: 42200,
    lat: 41.480851,
    long: -2.528548,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. RALLY, S.A.",
    DOMICILIO: "CTRA.MOLINS DE REY KM.12,8",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.481243,
    long: 2.031006,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. EL MASNOU",
    DOMICILIO: "CTRA. N-II, KM. 635,5",
    PROVINCIA: "BARCELONA",
    CP: "08320",
    lat: 41.482522,
    long: 2.333568,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP COLL FAVA",
    DOMICILIO: "RONDA NORT S/N",
    PROVINCIA: "BARCELONA",
    CP: "08173",
    lat: 41.482924,
    long: 2.086159,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 EL MASNOU",
    DOMICILIO: "Pol. Industrial Voramar. Antigua Ctra.N-",
    PROVINCIA: "BARCELONA",
    CP: "08320",
    lat: 41.483945,
    long: 2.336649,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. JULIA OIL",
    DOMICILIO: "CTRA. RUBI A SANT CUGAT KM 18,4",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.485022,
    long: 2.042318,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "BASEVA, S.A.-SUC.CARLIQ",
    DOMICILIO: "POLIG.LA FERRERIA-AVDA.CENTRAL S/N",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.485198,
    long: 2.178567,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. CERDANYOLA",
    DOMICILIO: "AVDA. BELLATERRA, 7",
    PROVINCIA: "BARCELONA",
    CP: "08290",
    lat: 41.485253,
    long: 2.131114,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CERDANYOLA II",
    DOMICILIO: "C/ SANTA ROSA, 47",
    PROVINCIA: "BARCELONA",
    CP: "08290",
    lat: 41.485376,
    long: 2.131464,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S POLIGONO HERMES",
    DOMICILIO: "POLIGONO INDUSTRIAL HERMES, PARCELA 4",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.489557,
    long: 2.16983,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES AP-7 BELLATERRA MI",
    DOMICILIO: "ES AP-7 BELLATERRA MI (DIR GERONA)",
    PROVINCIA: "BARCELONA",
    CP: "08290",
    lat: 41.492402,
    long: 2.101983,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. AP-7 BELLATERRA MD",
    DOMICILIO: "AP-7 DIR TARRAGONA PK 151",
    PROVINCIA: "BARCELONA",
    CP: "08290",
    lat: 41.49285,
    long: 2.099128,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. RECA, S.L.",
    DOMICILIO: "C/CALVARIO ESQ.RIO",
    PROVINCIA: "BARCELONA",
    CP: "08291",
    lat: 41.49397,
    long: 2.16223,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MONTCADA NORTE",
    DOMICILIO: "AUTOPISTA C-33 KM 82,5",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.4944,
    long: 2.1913,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA RAIGADA",
    DOMICILIO: "CALLE DE SALAMANCA, 46",
    PROVINCIA: "ZAMORA",
    CP: 49028,
    lat: 41.495051,
    long: -5.74158,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MONTCADA SUR",
    DOMICILIO: "AUTOPISTA C-33 KM 5,2",
    PROVINCIA: "BARCELONA",
    CP: "08110",
    lat: 41.495269,
    long: 2.191524,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RUBI II",
    DOMICILIO: "AUTOPISTA E-9 KM.14,7",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.496991,
    long: 2.04836,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RUBI",
    DOMICILIO: "AUTOPISTA E-9 KM.14,9",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.498317,
    long: 2.049294,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "SABATER NURI CARBURANTS",
    DOMICILIO: "C/SANTA ANA, 133",
    PROVINCIA: "BARCELONA",
    CP: "08290",
    lat: 41.500382,
    long: 2.124567,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. LA BASTIDA",
    DOMICILIO: "SECTOR W-LA BASTIDA-POL.IND.PARCELA 2",
    PROVINCIA: "BARCELONA",
    CP: "08191",
    lat: 41.50695,
    long: 2.03709,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL FRAGA",
    DOMICILIO: "CTRA. N-211 P.K. 330.8",
    PROVINCIA: "HUESCA",
    CP: 22520,
    lat: 41.514763,
    long: 0.328195,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. AP-2 FRAGA  MD",
    DOMICILIO: "CTRA A-P2 DIRECCION BARCELONA PK 119",
    PROVINCIA: "HUESCA",
    CP: 22520,
    lat: 41.520067,
    long: 0.334389,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES AP-2 FRAGA MD",
    DOMICILIO: "ES AP-2 FRAGA MI (DIR ZARAGOZA)",
    PROVINCIA: "HUESCA",
    CP: 22520,
    lat: 41.520211,
    long: 0.334178,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SAN JOSE",
    DOMICILIO: "CN-630 KM.274,800",
    PROVINCIA: "ZAMORA",
    CP: 49024,
    lat: 41.521173,
    long: -5.755288,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. AREA DEL BAJO CINCA",
    DOMICILIO: "CTA TORRENTE DE CINCA POL.88 PARC 22-25",
    PROVINCIA: "HUESCA",
    CP: 22520,
    lat: 41.524157,
    long: 0.361923,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL MATARO",
    DOMICILIO: "C DE LA TEIXIDORA, 37",
    PROVINCIA: "BARCELONA",
    CP: "08302",
    lat: 41.526183,
    long: 2.427978,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LES HORTES",
    DOMICILIO: "CTRA. BARCELONA 50 LES HORTES DEL CAMI R",
    PROVINCIA: "BARCELONA",
    CP: "08302",
    lat: 41.527059,
    long: 2.430934,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S.MATARO, S.L. (DCHO)",
    DOMICILIO: "AVDA. DEL MARESME, 1",
    PROVINCIA: "BARCELONA",
    CP: "08302",
    lat: 41.529931,
    long: 2.435916,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "ROCBLUME",
    DOMICILIO: "C/ ROCAFONT, 123",
    PROVINCIA: "BARCELONA",
    CP: "08205",
    lat: 41.533341,
    long: 2.100088,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA -MATARO-",
    DOMICILIO: "C/ CAMÍ RAL Nº 547",
    PROVINCIA: "BARCELONA",
    CP: "08302",
    lat: 41.534297,
    long: 2.440672,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 MOLLET",
    DOMICILIO: "Av. Rabassaires, s/n.",
    PROVINCIA: "BARCELONA",
    CP: "08100",
    lat: 41.539028,
    long: 2.221167,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "SABADELL I',
    DOMICILIO: "CTRA. MOLINS DE REY KM.135",
    PROVINCIA: "BARCELONA",
    CP: "08205",
    lat: 41.539075,
    long: 2.099505,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.MATARO I",
    DOMICILIO: "CTRA.MATARO-GRANOLLERS KM.1.4",
    PROVINCIA: "BARCELONA",
    CP: "08304",
    lat: 41.541211,
    long: 2.421197,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S COMTES D'URGELL II",
    DOMICILIO: "CTRA. A-2 KM. 463,5",
    PROVINCIA: "LLEIDA",
    CP: 25001,
    lat: 41.543232,
    long: 0.638181,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "ES.ENERGY EXPRESS,SL.-II",
    DOMICILIO: "AUTOPISTA A-18 KM.17,200",
    PROVINCIA: "BARCELONA",
    CP: "08223",
    lat: 41.543382,
    long: -2.050548,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. MATARO",
    DOMICILIO: "ALFONSO X EL SABIO, S/N",
    PROVINCIA: "BARCELONA",
    CP: "08302",
    lat: 41.545472,
    long: 2.449306,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 TERRASSA",
    DOMICILIO: "C/Colom, Parc Valles, Pol.Ind. Sta. Marg",
    PROVINCIA: "BARCELONA",
    CP: "08223",
    lat: 41.545722,
    long: 2.0275,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S TREVOL",
    DOMICILIO: "C/GUILLEM DE MONTANYONS, 208",
    PROVINCIA: "BARCELONA",
    CP: "08223",
    lat: 41.54978,
    long: 2.012196,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MATARO III",
    DOMICILIO: "C/ ENERGIA, 2-4(POLIGONO MATA ROCAFONDA)",
    PROVINCIA: "BARCELONA",
    CP: "08304",
    lat: 41.550555,
    long: 2.454229,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MATARO III",
    DOMICILIO: "C/ENERGIA 2-4 P.I. MATA ROCAFONDA",
    PROVINCIA: "BARCELONA",
    CP: "08304",
    lat: 41.550896,
    long: 2.454339,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. ARGENTONA",
    DOMICILIO: "CB-502, KM-4,30",
    PROVINCIA: "BARCELONA",
    CP: "08310",
    lat: 41.551483,
    long: 2.404478,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MONTMELO",
    DOMICILIO: "C/ VIC, 69",
    PROVINCIA: "BARCELONA",
    CP: "08160",
    lat: 41.55369,
    long: 2.25014,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. VILADECAVALLS",
    DOMICILIO: "CRTA. BP-1212 KM 12,50",
    PROVINCIA: "BARCELONA",
    CP: "08232",
    lat: 41.562,
    long: 1.96739,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "ES.ENERGY EXPRESS,S.L.-1",
    DOMICILIO: "AUTOPISTA A-18 KM.17,200",
    PROVINCIA: "BARCELONA",
    CP: "08223",
    lat: 41.56706,
    long: 2.03214,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. CAVASA",
    DOMICILIO: "C/PARDO BAZAN, 2",
    PROVINCIA: "BARCELONA",
    CP: "08225",
    lat: 41.57505,
    long: 2.00687,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "ES BP NAVISAL",
    DOMICILIO: "CR N-II,  KM 655,9",
    PROVINCIA: "BARCELONA",
    CP: "08350",
    lat: 41.57512,
    long: 2.54203,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "SHELL EXPRESS ARENYS",
    DOMICILIO: "CL DRAPER 4",
    PROVINCIA: "BARCELONA",
    CP: "08350",
    lat: 41.575193,
    long: 2.541991,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL CADRETE",
    DOMICILIO: "CTRA NACIONAL 330 KM 485,4",
    PROVINCIA: "ZARAGOZA",
    CP: 50019,
    lat: 41.575479,
    long: -0.960396,
  },
  {
    BANDERA: "PETROMIRALLES",
    "NOMBRE TPV": "E.S. PETROMIRALLES, S.L.",
    DOMICILIO: "C/ BELGICA S/N",
    PROVINCIA: "BARCELONA",
    CP: "08700",
    lat: 41.575766,
    long: 1.613531,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "EASYGAS LLICA",
    DOMICILIO: "CTRA. C-17 KM. 17,2",
    PROVINCIA: "BARCELONA",
    CP: "08185",
    lat: 41.583957,
    long: 2.256037,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA MUELA",
    DOMICILIO: "AUTOVIA A-2, KM-299,3",
    PROVINCIA: "ZARAGOZA",
    CP: 50196,
    lat: 41.585427,
    long: -1.099623,
  },
  {
    BANDERA: "PETROMIRALLES",
    "NOMBRE TPV": "E.S. PETROMIRALLES IGUAL",
    DOMICILIO: "CR DEL REL LANTONADA JOAN MERLADER",
    PROVINCIA: "BARCELONA",
    CP: "08700",
    lat: 41.588969,
    long: 1.622183,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LA BOTIGA PETROMIRALLES",
    DOMICILIO: "C/ GUIMERAES, S/N",
    PROVINCIA: "BARCELONA",
    CP: "08700",
    lat: 41.589199,
    long: 1.622522,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "STAR PETROLEUM CANET MAR",
    DOMICILIO: "RIERA D'ENMISER, 2-4",
    PROVINCIA: "BARCELONA",
    CP: "08360",
    lat: 41.596431,
    long: 2.569261,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA EL NAVARRO",
    DOMICILIO: "AUTOVIA A2 PK.283,114",
    PROVINCIA: "ZARAGOZA",
    CP: 50290,
    lat: 41.603055,
    long: -1.28486,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PRIMER CARB GRANOLLERS",
    DOMICILIO: "PASSEIG CONCA DEL BESOS, 26",
    PROVINCIA: "BARCELONA",
    CP: "08403",
    lat: 41.604263,
    long: 2.278618,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "E.S.EL PINAR I, S.A.",
    DOMICILIO: "CTRA.RUEDA KM.4,500",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.604386,
    long: -4.754065,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. EL CERRO",
    DOMICILIO: "CTRA. CL- 601 KM.4.800",
    PROVINCIA: "VALLADOLID",
    CP: 47012,
    lat: 41.608883,
    long: -4.709455,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LAS FUENTES",
    DOMICILIO: "CTRA. A-68 P.K. 5",
    PROVINCIA: "ZARAGOZA",
    CP: 50720,
    lat: 41.611367,
    long: -0.834588,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CALELLA",
    DOMICILIO: "CN II KM 667, S/N",
    PROVINCIA: "BARCELONA",
    CP: "08370",
    lat: 41.612553,
    long: 2.652114,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. CALELLA",
    DOMICILIO: "CN-II KM.667,20",
    PROVINCIA: "BARCELONA",
    CP: "08370",
    lat: 41.612637,
    long: 2.652174,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S.MAGRANERS",
    DOMICILIO: "CN-240 KM.89,400",
    PROVINCIA: "LLEIDA",
    CP: 25001,
    lat: 41.614715,
    long: 0.652579,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "LOW COST SAN CRISTOBAL",
    DOMICILIO: "P.I SAN CRISTOBAL; C/ PLATA, 74",
    PROVINCIA: "VALLADOLID",
    CP: 47012,
    lat: 41.615847,
    long: -4.710361,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E S  C I D Y",
    DOMICILIO: "AVDA. DE SORIA N-122, KM 3,2",
    PROVINCIA: "VALLADOLID",
    CP: 47012,
    lat: 41.61599,
    long: -4.6912,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "ES PISUERGA",
    DOMICILIO: "RONDA INTERIOR SUR",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.618325,
    long: -4.720688,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. RONDA ESTE",
    DOMICILIO: "AVDA.MADRID KM.187",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.618763,
    long: -4.72661,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.CNO.VIEJO SIMANCAS",
    DOMICILIO: "CAMINO VIEJO SIMANCAS, 46",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.621538,
    long: -4.756238,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ECO ZARAGOZA",
    DOMICILIO: "AVDA. CASABLANCA II",
    PROVINCIA: "ZARAGOZA",
    CP: 50013,
    lat: 41.622908,
    long: -0.939165,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "A.S. NUEVA CARTUJA",
    DOMICILIO: "CARRETERA CASTELLON A-68, KM 233,15",
    PROVINCIA: "ZARAGOZA",
    CP: 50720,
    lat: 41.623838,
    long: -0.846036,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "PINEDA',
    DOMICILIO: "C/ GARBI, 123",
    PROVINCIA: "BARCELONA",
    CP: "08397",
    lat: 41.62386,
    long: 2.6741,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP AVELLANAS",
    DOMICILIO: "CTRA CASTELLON KM 3,700",
    PROVINCIA: "ZARAGOZA",
    CP: 50013,
    lat: 41.623877,
    long: -0.846082,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ESSER PINEDA  S.L.",
    DOMICILIO: "C/LLABEIG, 1-7",
    PROVINCIA: "BARCELONA",
    CP: "08397",
    lat: 41.624502,
    long: 2.674586,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. EL CISNE (DCHO)",
    DOMICILIO: "CN-II KM.309",
    PROVINCIA: "ZARAGOZA",
    CP: 50012,
    lat: 41.625155,
    long: -1.009996,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S.FRAY JULIAN GARCES",
    DOMICILIO: "C/ FRAY JULIAN GARCES, 112",
    PROVINCIA: "ZARAGOZA",
    CP: 50007,
    lat: 41.625438,
    long: -0.888439,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "GASOLINERA TENCAR",
    DOMICILIO: "CROMO 50-52",
    PROVINCIA: "VALLADOLID",
    CP: 47012,
    lat: 41.625924,
    long: -4.707645,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. MONTECANAL",
    DOMICILIO: "AVDA. DE LA ILUSTRACIÓN",
    PROVINCIA: "ZARAGOZA",
    CP: 50012,
    lat: 41.626791,
    long: -0.922857,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "DST ARCAS REALES",
    DOMICILIO: "CALLE ARCA REAL 111",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.62682,
    long: -4.72834,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. ARGALES",
    DOMICILIO: "POLIGONO ARGALES - PARCELA 38",
    PROVINCIA: "VALLADOLID",
    CP: 47008,
    lat: 41.62811,
    long: -4.732754,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PINEDA DE MAR, S.L.",
    DOMICILIO: "CN-II KM.671",
    PROVINCIA: "BARCELONA",
    CP: "08397",
    lat: 41.629439,
    long: 2.689914,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "DST RIO SHOPING",
    DOMICILIO: "RAMON Y CAJAL S/N CR ENTRADA RIO SHOPING",
    PROVINCIA: "VALLADOLID",
    CP: 47610,
    lat: 41.631289,
    long: -4.790934,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CALDES",
    DOMICILIO: "CTRA. C-59 KM 17,2",
    PROVINCIA: "BARCELONA",
    CP: "08140",
    lat: 41.63202,
    long: 2.165903,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.BELLPUIG",
    DOMICILIO: "CN-II KM.495",
    PROVINCIA: "LLEIDA",
    CP: 25250,
    lat: 41.63226,
    long: 1.00712,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.MIGUEL SERVET",
    DOMICILIO: "C/ MIGUEL SERVET, 254",
    PROVINCIA: "ZARAGOZA",
    CP: 50013,
    lat: 41.634502,
    long: -0.861868,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MALGRAT MONTAÑA",
    DOMICILIO: "CN-II KM.674,2",
    PROVINCIA: "BARCELONA",
    CP: "08380",
    lat: 41.642318,
    long: 2.726139,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA MALGRAT MAR",
    DOMICILIO: "N-II P.K.674,3",
    PROVINCIA: "BARCELONA",
    CP: "08380",
    lat: 41.64235,
    long: 2.7258,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. COMPROMISO CASPE",
    DOMICILIO: "AVDA. COMPROMISO DE CASPE, 131 BAJO",
    PROVINCIA: "ZARAGOZA",
    CP: 50002,
    lat: 41.645017,
    long: -0.857729,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CASASOLA",
    DOMICILIO: 'VILLABAÑEZ "ESQUINA RONDA ESTE  S/N',
    PROVINCIA: "VALLADOLID",
    CP: 47012,
    lat: 41.646645,
    long: -4.694913,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "US CAMINO DE LAS TORRES",
    DOMICILIO: "CAMINO DE LAS TORRES, 2 DUPLICADO",
    PROVINCIA: "ZARAGOZA",
    CP: 50002,
    lat: 41.64857,
    long: -0.86823,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LAS FRANQUESAS",
    DOMICILIO: "CN-152 KM.33,0",
    PROVINCIA: "BARCELONA",
    CP: "08520",
    lat: 41.650254,
    long: 2.297398,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S LA ROMERA",
    DOMICILIO: "CRTA A-2  KM 285",
    PROVINCIA: "ZARAGOZA",
    CP: 50290,
    lat: 41.65191,
    long: -0.795004,
  },
  {
    BANDERA: "VALCARCE",
    "NOMBRE TPV": "E.S. WATERY ARANDA S.L.",
    DOMICILIO: "C/ VENDIMIA, 2 P.I. PRADO MARINA",
    PROVINCIA: "BURGOS",
    CP: "09400",
    lat: 41.654006,
    long: -3.700206,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "MALGRAT II',
    DOMICILIO: "AVDA. COSTA BRAVA Nº 163",
    PROVINCIA: "BARCELONA",
    CP: "08380",
    lat: 41.655183,
    long: 2.743811,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. ZARATÁN",
    DOMICILIO: "C/PARQUE OCIO EQUINOCIO, S/N",
    PROVINCIA: "VALLADOLID",
    CP: 47610,
    lat: 41.659669,
    long: -4.774396,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CIAL ANONIMA DAGO,SL",
    DOMICILIO: "AVDA. CATALUÑA, 44",
    PROVINCIA: "ZARAGOZA",
    CP: 50014,
    lat: 41.660225,
    long: -0.868832,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ZARATAN, S.L.",
    DOMICILIO: "AV. GIJON KM 195",
    PROVINCIA: "VALLADOLID",
    CP: 47610,
    lat: 41.661838,
    long: -4.768163,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. ARANDA DE DUERO",
    DOMICILIO: "AVDA.CASTILLA S/N",
    PROVINCIA: "BURGOS",
    CP: "09400",
    lat: 41.662397,
    long: -3.692069,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.TAMACA AUTOCENTRO, S",
    DOMICILIO: "AVDA.LUIS MATEOS, 16",
    PROVINCIA: "BURGOS",
    CP: "09400",
    lat: 41.664811,
    long: -3.703447,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "RENOVA OIL",
    DOMICILIO: "CTRA N230 KM 6,40",
    PROVINCIA: "LLEIDA",
    CP: 25123,
    lat: 41.6667,
    long: 0.6167,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL AVDA CATALUÑA",
    DOMICILIO: "AV. DE CATALUÑA Nº254-258",
    PROVINCIA: "ZARAGOZA",
    CP: 50014,
    lat: 41.667578,
    long: -0.8511,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. MKT OIL PALAFOLLS",
    DOMICILIO: "CTRA. B-682 KM 2.432",
    PROVINCIA: "BARCELONA",
    CP: "08389",
    lat: 41.6677,
    long: 2.75547,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "AREAS NORT',
    DOMICILIO: "C-32 PK 129,8",
    PROVINCIA: "BARCELONA",
    CP: "08389",
    lat: 41.668458,
    long: 2.715931,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA ARANDA DE DUERO",
    DOMICILIO: "C/BEMPOSTA PARC R-97",
    PROVINCIA: "BURGOS",
    CP: "09400",
    lat: 41.67025,
    long: -3.707139,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP ARANDA",
    DOMICILIO: "CALLE SAN FRANCISCO, 39",
    PROVINCIA: "BURGOS",
    CP: "09400",
    lat: 41.674913,
    long: -3.68613,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "MERCAZARAGOZA',
    DOMICILIO: "CTRA. DE COGOLLUDA S/N",
    PROVINCIA: "ZARAGOZA",
    CP: 50014,
    lat: 41.682386,
    long: -0.853624,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA PIRINEOS",
    DOMICILIO: "CRTA. NACIONAL-330, KM. 505,5",
    PROVINCIA: "ZARAGOZA",
    CP: 50015,
    lat: 41.699507,
    long: -0.867434,
  },
  {
    BANDERA: "PETROMIRALLES",
    "NOMBRE TPV": 'PETROMIRALLES "TARREGA',
    DOMICILIO: "POLIG. IND. LA CANALETA PARC. 2",
    PROVINCIA: "LLEIDA",
    CP: 25300,
    lat: 41.702781,
    long: 1.12745,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA UTEBO",
    DOMICILIO: "CN-232 KM.250,970",
    PROVINCIA: "ZARAGOZA",
    CP: 50180,
    lat: 41.706401,
    long: -1.010418,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. EL CID",
    DOMICILIO: "CTRA. A23 PK 506,5",
    PROVINCIA: "ZARAGOZA",
    CP: 50015,
    lat: 41.708566,
    long: -0.862563,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.CABEZON",
    DOMICILIO: "CN-620 KM.112",
    PROVINCIA: "VALLADOLID",
    CP: 47260,
    lat: 41.744072,
    long: -4.659829,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PINEDA",
    DOMICILIO: "CTRA. CR-L-4501 KM.3,5",
    PROVINCIA: "BARCELONA",
    CP: "08272",
    lat: 41.758178,
    long: 1.840006,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "B ENERGIAS",
    DOMICILIO: "AVDA EUROPA, 28",
    PROVINCIA: "SORIA",
    CP: 42005,
    lat: 41.759348,
    long: -2.486417,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LA JAREA S.L.",
    DOMICILIO: "AVDA LA JAREA",
    PROVINCIA: "ZARAGOZA",
    CP: 50630,
    lat: 41.768174,
    long: -1.277075,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CEDIPSA BONAVIA",
    DOMICILIO: "CRTA N-232 KM 271.2 (DIR. ZARAGOZA)",
    PROVINCIA: "ZARAGOZA",
    CP: 50690,
    lat: 41.772909,
    long: -1.2169,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PINEDA",
    DOMICILIO: "CRTA. MANRESA SANT PEDOR",
    PROVINCIA: "BARCELONA",
    CP: "08272",
    lat: 41.773044,
    long: 1.881462,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.CEDIPSA BONAVIA",
    DOMICILIO: "CRTA N-232 KM 271.2 (DIR. LOGROÑO)",
    PROVINCIA: "ZARAGOZA",
    CP: 50690,
    lat: 41.773561,
    long: -1.216318,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEPSA MOIA",
    DOMICILIO: "CTRA VIC N 141 KM 27,3",
    PROVINCIA: "BARCELONA",
    CP: "08181",
    lat: 41.809312,
    long: 2.102128,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LLAGOSTERA",
    DOMICILIO: "CC-250 KM.19",
    PROVINCIA: "GIRONA",
    CP: 17240,
    lat: 41.82878,
    long: 2.89647,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. JAIME I",
    DOMICILIO: "AVD DE LERIDA 34",
    PROVINCIA: "HUESCA",
    CP: 22400,
    lat: 41.900967,
    long: 0.206125,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. VIC II",
    DOMICILIO: "PASEO DE LA GENERALITAT Nº51",
    PROVINCIA: "BARCELONA",
    CP: "08500",
    lat: 41.920725,
    long: 2.252636,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SUCRE",
    DOMICILIO: "C/ ONZE DE SETEMBRE, 12",
    PROVINCIA: "BARCELONA",
    CP: "08500",
    lat: 41.9281,
    long: 2.24538,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BOIRA",
    DOMICILIO: "C/ ONZE DE SETEMBRE, 13",
    PROVINCIA: "BARCELONA",
    CP: "08500",
    lat: 41.92813,
    long: 2.24466,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CALLDETENES",
    DOMICILIO: "CRTA. N.141 KM 3,325",
    PROVINCIA: "BARCELONA",
    CP: "08500",
    lat: 41.928975,
    long: 2.298792,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "AREAS DELS PRADALS,S.L.",
    DOMICILIO: "C/SABADELL, Nº4-6",
    PROVINCIA: "BARCELONA",
    CP: "08500",
    lat: 41.941098,
    long: 2.252919,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP LA CREUETA",
    DOMICILIO: "CL EMILI GRAHIT, 106",
    PROVINCIA: "GIRONA",
    CP: 17003,
    lat: 41.966888,
    long: -2.838539,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. TRADISNA-VALVERDE",
    DOMICILIO: "CTRA. NAC. 113 KM 64",
    PROVINCIA: "LA RIOJA",
    CP: 26520,
    lat: 41.982753,
    long: -1.862272,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. EL PASTOR",
    DOMICILIO: "AVDA. ANDALUCIA, 180, ESQ. CTRA.MAGAZ",
    PROVINCIA: "PALENCIA",
    CP: 34004,
    lat: 41.991199,
    long: -4.510344,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL PALENCIA",
    DOMICILIO: "C/ANDALUCIA ESQ.AVDA.COMUNIDAD EUROPEA",
    PROVINCIA: "PALENCIA",
    CP: 34004,
    lat: 41.997441,
    long: -4.509421,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "EE.SS.HERRERO, S.L.",
    DOMICILIO: "POL.IND. VALLE DEL CINCA",
    PROVINCIA: "HUESCA",
    CP: 22300,
    lat: 42.005663,
    long: 0.140086,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. NOVA SARRIA",
    DOMICILIO: "CN-II KM.719,476",
    PROVINCIA: "GIRONA",
    CP: 17840,
    lat: 42.009801,
    long: 2.817424,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL CELRA",
    DOMICILIO: "CRTA DE PALAMÓS C-255 P.K 5",
    PROVINCIA: "GIRONA",
    CP: 17460,
    lat: 42.024801,
    long: 2.841143,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CELRA",
    DOMICILIO: "CC-255 KM.4,5",
    PROVINCIA: "GIRONA",
    CP: 17460,
    lat: 42.025233,
    long: 2.865117,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. TUDELA",
    DOMICILIO: "A-68 KM.223",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.03552,
    long: -1.58855,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. SAN RAFAEL GESTION",
    DOMICILIO: "CARRETERA ZARAGOZA, Nº73",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.052475,
    long: -1.596923,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.SEGURA-CASADO, S.L.",
    DOMICILIO: "AVDA. DE ZARAGOZA, 48",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.05821,
    long: -1.60005,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. ZONA AUTO TUDELA",
    DOMICILIO: "CTRA. CORELLA KM 28",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.06278,
    long: -1.61814,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BALLUS, S.A.",
    DOMICILIO: "C/ ROSER, 44",
    PROVINCIA: "BARCELONA",
    CP: "08600",
    lat: 42.09983,
    long: 1.84243,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.BAÑOLAS",
    DOMICILIO: "CC-150 KM.19",
    PROVINCIA: "GIRONA",
    CP: 17820,
    lat: 42.101253,
    long: 2.791642,
  },
  {
    BANDERA: "VALCARCE",
    "NOMBRE TPV": "VALCARCE TUDELA",
    DOMICILIO: "POL. IND. MONTES DEL CIERZO, SALIDA 86",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.121708,
    long: -1.675041,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BAÑOLAS",
    DOMICILIO: "C/ALFONSO XII, 161 - 163",
    PROVINCIA: "GIRONA",
    CP: 17820,
    lat: 42.12326,
    long: 2.76329,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "TRADISNA ENERGIAS",
    DOMICILIO: "PG.MONTES DEL CIERZO, POL.24 PA 67",
    PROVINCIA: "NAVARRA",
    CP: 31500,
    lat: 42.123572,
    long: -1.667017,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EJEA",
    DOMICILIO: "POLIGONO VALDEFERRIN, R-61, R-62",
    PROVINCIA: "ZARAGOZA",
    CP: 50600,
    lat: 42.127169,
    long: -1.138254,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S. CEDIPSA "BAÑERA',
    DOMICILIO: "CTRA DE SADABA, 2",
    PROVINCIA: "ZARAGOZA",
    CP: 50600,
    lat: 42.129242,
    long: -1.14769,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.MARTINEZ VELASCO",
    DOMICILIO: "AVD MARTINEZ VELASCO 53",
    PROVINCIA: "HUESCA",
    CP: 22004,
    lat: 42.131442,
    long: -0.419186,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SAN JORGE",
    DOMICILIO: "PROLONGACIÓN AVDA. MARTÍNEZ VELASCO, S/N",
    PROVINCIA: "HUESCA",
    CP: 22004,
    lat: 42.131512,
    long: -0.419212,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S RAMON Y CAJAL",
    DOMICILIO: "PASEO RAMON Y CAJAL, 91",
    PROVINCIA: "HUESCA",
    CP: 22006,
    lat: 42.140557,
    long: -0.38796,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. FEGOBLAN, S.L.",
    DOMICILIO: "AVDA. SANGUIÑEDA, 118",
    PROVINCIA: "PONTEVEDRA",
    CP: 36419,
    lat: 42.168105,
    long: -8.625914,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PARQUE",
    DOMICILIO: "AVDA. STA. COLOMA S/N",
    PROVINCIA: "GIRONA",
    CP: 17800,
    lat: 42.169761,
    long: 2.47645,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP OLOT",
    DOMICILIO: "CTRA.SANTA PAU,ESQ.AVDA.SANT JORDI, S/N",
    PROVINCIA: "GIRONA",
    CP: 17800,
    lat: 42.17605,
    long: 2.49194,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA RIPOLL II",
    DOMICILIO: "C-17 KM:92,7",
    PROVINCIA: "GIRONA",
    CP: 17500,
    lat: 42.186343,
    long: 2.196288,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA OLOT",
    DOMICILIO: "C/ DR. ZAMENHOFF S/N",
    PROVINCIA: "GIRONA",
    CP: 17800,
    lat: 42.187865,
    long: 2.499374,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S. LES TRIES",
    DOMICILIO: "CTRA.LES TRIES, 70-78",
    PROVINCIA: "GIRONA",
    CP: 17800,
    lat: 42.18945,
    long: 2.50424,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. OLOT",
    DOMICILIO: "AVDA.EUROPA, Nº7 P.I.PLA DE BAIX II",
    PROVINCIA: "GIRONA",
    CP: 17800,
    lat: 42.192786,
    long: 2.482583,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.PUXEIROS M.DERECHA",
    DOMICILIO: "CTRA. A-55, KM. 7,20A",
    PROVINCIA: "PONTEVEDRA",
    CP: 36214,
    lat: 42.211166,
    long: -8.668808,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PUXEIROS M.IZQ.",
    DOMICILIO: "A 55 P.K. 7,20 A MARGEN IZQUIERDO",
    PROVINCIA: "PONTEVEDRA",
    CP: 36214,
    lat: 42.211592,
    long: -8.668842,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 RIPOLL I",
    DOMICILIO: "Pl. Casa Nova del Baix, 8",
    PROVINCIA: "GIRONA",
    CP: 17500,
    lat: 42.215652,
    long: 2.168855,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "BOULLOSA Y ESTEVEZ, S.L.",
    DOMICILIO: "ALCALDE LAVADORES, 64",
    PROVINCIA: "PONTEVEDRA",
    CP: 36214,
    lat: 42.221413,
    long: -8.696773,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "AVENIDA DE MADRID",
    DOMICILIO: "AVDA. DE MADRID S/N",
    PROVINCIA: "PONTEVEDRA",
    CP: 36214,
    lat: 42.221793,
    long: -8.707983,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. EXGA, S.L.",
    DOMICILIO: "AVDA. MADRID, 73",
    PROVINCIA: "PONTEVEDRA",
    CP: 36214,
    lat: 42.22286,
    long: -8.70834,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. RALLY MOLL",
    DOMICILIO: "AVDA. DE RODA S/N",
    PROVINCIA: "GIRONA",
    CP: 17480,
    lat: 42.25559,
    long: 3.18235,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. GALP FIGUERES",
    DOMICILIO: "RONDA SUR S/N",
    PROVINCIA: "GIRONA",
    CP: 17600,
    lat: 42.26124,
    long: 2.975463,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL FIGUERAS",
    DOMICILIO: "CTRA. DE ROSES, P.K. 31",
    PROVINCIA: "GIRONA",
    CP: 17600,
    lat: 42.267268,
    long: 2.984379,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LABOIL, S.L.",
    DOMICILIO: "C/ JOAQUIN COSTA, Nº19",
    PROVINCIA: "HUESCA",
    CP: 22800,
    lat: 42.27484,
    long: -0.68601,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. FIGUERAS",
    DOMICILIO: "CC-260 KM.27,550",
    PROVINCIA: "GIRONA",
    CP: 17600,
    lat: 42.27932,
    long: 2.95117,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "ADELFAS OIL-TEJERIAS",
    DOMICILIO: "SAN LAZARO-TEJERIAS S/N",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.28914,
    long: -1.94316,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "ADELFAS OIL-TEJERIAS",
    DOMICILIO: "SAN LAZARO-TEJERIAS S/N",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.28914,
    long: -1.94316,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "E.S.LA ABADESA VILLARCE",
    DOMICILIO: "CN-I KM.232",
    PROVINCIA: "BURGOS",
    CP: "09195",
    lat: 42.290118,
    long: -3.70453,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S. CALAHORRA -I-",
    DOMICILIO: "A-68 KM.177",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.291945,
    long: -1.971284,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S. CALAHORRA -D-",
    DOMICILIO: "A-68 KM.177",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.292779,
    long: -1.969428,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "LOPEZ ARAQUISTAIN NAVAJA",
    DOMICILIO: "BERBRICIO, 71",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.306515,
    long: -1.971145,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "A.S. VILLAGONZALO, S.L.",
    DOMICILIO: "C/ LLANILLO, Nº 29",
    PROVINCIA: "BURGOS",
    CP: "09195",
    lat: 42.306819,
    long: -3.732438,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. HERMELA, S.A.",
    DOMICILIO: "CN-620 KM.5,7",
    PROVINCIA: "BURGOS",
    CP: "09195",
    lat: 42.309678,
    long: -3.723161,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S CEDIPSA LANDA",
    DOMICILIO: "CARRETERA MADRID-IRUN N-I KM.236",
    PROVINCIA: "BURGOS",
    CP: "09001",
    lat: 42.310252,
    long: -3.702931,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S.LAS ADELFAS, S.A.",
    DOMICILIO: "CTRA.SAN ADRIAN S/N",
    PROVINCIA: "LA RIOJA",
    CP: 26500,
    lat: 42.312302,
    long: -1.953655,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": 'E.S."AUTOSERVICIO" VILLA',
    DOMICILIO: "C/ MADRID 58",
    PROVINCIA: "BURGOS",
    CP: "09003",
    lat: 42.330275,
    long: -3.700468,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": 'E.S."LA CASTELLANA" VILL',
    DOMICILIO: "CTRA VALLADOLID",
    PROVINCIA: "BURGOS",
    CP: "09003",
    lat: 42.33752,
    long: -3.711778,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "E.S.VILLARCE, S.L.",
    DOMICILIO: "C/ GRAL. SANZ PASTOR, S/N",
    PROVINCIA: "BURGOS",
    CP: "09003",
    lat: 42.344611,
    long: -3.698843,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.VILLARCE LA VENTILLA",
    DOMICILIO: "CN-120, KM-108,3",
    PROVINCIA: "BURGOS",
    CP: "09007",
    lat: 42.344706,
    long: -3.648003,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA BURGOS",
    DOMICILIO: "C/ PÁRAMO 15, P.IND. VILLAYUDA - LA VENT",
    PROVINCIA: "BURGOS",
    CP: "09007",
    lat: 42.347111,
    long: -3.650194,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "VILLARCE, S.L.-AUTOESTAC",
    DOMICILIO: "GLORIETA DE LOGROÑO S/N",
    PROVINCIA: "BURGOS",
    CP: "09007",
    lat: 42.348711,
    long: -3.677861,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA VILLARCE",
    DOMICILIO: "ALCALDE MARTÍN COBOS,18. ESQ C/ VITORIA",
    PROVINCIA: "BURGOS",
    CP: "09007",
    lat: 42.358732,
    long: -3.651081,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.A. LAS TERRAZAS",
    DOMICILIO: "CTRA.BURGOS-AGUILAR, KM-3",
    PROVINCIA: "BURGOS",
    CP: "09001",
    lat: 42.364421,
    long: -3.711935,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA JUNQUERA",
    DOMICILIO: "CTRA.GI-601 A CANTALLOPS,ESQ.CN-II",
    PROVINCIA: "GIRONA",
    CP: 17600,
    lat: 42.403858,
    long: 2.880081,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. ADELFAS NAJERA",
    DOMICILIO: "SAN FERNANDO 120",
    PROVINCIA: "LA RIOJA",
    CP: 26300,
    lat: 42.416294,
    long: -2.71851,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "PONTEVEDRA',
    DOMICILIO: "AVDA. DE LUGO, 46",
    PROVINCIA: "PONTEVEDRA",
    CP: 36004,
    lat: 42.43041,
    long: -8.62521,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LAS GAUNAS",
    DOMICILIO: "AVENIDA DE MADRID, 25",
    PROVINCIA: "LA RIOJA",
    CP: 26006,
    lat: 42.453069,
    long: -2.448404,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.LAS TEJERAS",
    DOMICILIO: "PRADO VIEJO",
    PROVINCIA: "LA RIOJA",
    CP: 26006,
    lat: 42.454217,
    long: -2.466372,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.GRAJERA",
    DOMICILIO: "CN-120 KM.413",
    PROVINCIA: "LA RIOJA",
    CP: 26006,
    lat: 42.454249,
    long: -2.504132,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S CASCAJOS",
    DOMICILIO: "C/ PIQUERAS, 22",
    PROVINCIA: "LA RIOJA",
    CP: 26006,
    lat: 42.456465,
    long: -2.432041,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "BENES PRODUCTOS PETROLIF",
    DOMICILIO: "AVD DE ARAGON 7",
    PROVINCIA: "LA RIOJA",
    CP: 26006,
    lat: 42.460145,
    long: -2.407114,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. SABIÑANIGO",
    DOMICILIO: "AVDA. HUESCA, 22-24",
    PROVINCIA: "HUESCA",
    CP: 22600,
    lat: 42.514798,
    long: -0.361361,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BAL DE TENA",
    DOMICILIO: "CTRA. N-260 KM. 516",
    PROVINCIA: "HUESCA",
    CP: 22600,
    lat: 42.539795,
    long: -0.355554,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "E.S. RUIZ, S.A. (DCHO)",
    DOMICILIO: "CN-124 KM.41,6",
    PROVINCIA: "LA RIOJA",
    CP: 26200,
    lat: 42.566349,
    long: -2.838893,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "E.S.RUIZ, S.A.",
    DOMICILIO: "AVD DE LA RIOJA 4",
    PROVINCIA: "LA RIOJA",
    CP: 26200,
    lat: 42.575117,
    long: -2.851204,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. Q8 - VILAGARCIA",
    DOMICILIO: "AVDA.PONTEVEDRA, Nº69",
    PROVINCIA: "PONTEVEDRA",
    CP: 36600,
    lat: 42.58736,
    long: -8.7446,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'E.S.CEDIPSA "VILLAGARCIA',
    DOMICILIO: "AVDA. DE PONTEVEDRA, 47",
    PROVINCIA: "PONTEVEDRA",
    CP: 36600,
    lat: 42.589806,
    long: -8.747349,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TEJA",
    DOMICILIO: "AVDA. DE  LA LIBERTAD, Nº 40",
    PROVINCIA: "LEON",
    CP: 24193,
    lat: 42.62237,
    long: -5.5638,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ALTO LEON",
    DOMICILIO: "CN-630 KM. 140",
    PROVINCIA: "LEON",
    CP: 24193,
    lat: 42.62581,
    long: -5.58663,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E .S. VILLAQUILAMBRE",
    DOMICILIO: "CTRA. LE-311, KM. 3,500",
    PROVINCIA: "LEON",
    CP: 24193,
    lat: 42.63323,
    long: -5.55976,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "SERVICIOS PUENTE LINARES",
    DOMICILIO: "CN-640 KM.201,7",
    PROVINCIA: "PONTEVEDRA",
    CP: 36687,
    lat: 42.698211,
    long: -8.459129,
  },
  {
    BANDERA: "PETROCAT",
    "NOMBRE TPV": "E.S. PETROCAT VIELHA",
    DOMICILIO: "CC-142, CR. DE BAQUIRA",
    PROVINCIA: "LLEIDA",
    CP: 25530,
    lat: 42.698633,
    long: 0.802528,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. VIELLA",
    DOMICILIO: "CN-230 KM.165,200",
    PROVINCIA: "LLEIDA",
    CP: 25530,
    lat: 42.701858,
    long: 0.793069,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SIERRA DE BARBANZA",
    DOMICILIO: "POL.INDUSTRIAL LALIN 2000-PARCELA C-26",
    PROVINCIA: "PONTEVEDRA",
    CP: 36500,
    lat: 42.707594,
    long: -8.729311,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. MIJARAN",
    DOMICILIO: "CN-230 KM-164,1",
    PROVINCIA: "LLEIDA",
    CP: 25530,
    lat: 42.711131,
    long: 0.797186,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S MG TALLUNTXE",
    DOMICILIO: "POLIGONO TALLUNTXE II C/Q Nº1",
    PROVINCIA: "NAVARRA",
    CP: 31192,
    lat: 42.774341,
    long: -1.634244,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MUTILVA I",
    DOMICILIO: "CTRA. PA-30 KM 5,2 (DIRECCION ZARAGOZA)",
    PROVINCIA: "NAVARRA",
    CP: 31192,
    lat: 42.80076,
    long: -1.61159,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "MUTILVA II",
    DOMICILIO: "N-135; PK 5.6",
    PROVINCIA: "NAVARRA",
    CP: 31192,
    lat: 42.801779,
    long: -1.612351,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. MUTILVA II",
    DOMICILIO: "CTRA. PA-30 KM 5,2 (DIRECCION FRANCIA)",
    PROVINCIA: "NAVARRA",
    CP: 31192,
    lat: 42.801779,
    long: -1.612351,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. SUBIJANA",
    DOMICILIO: "CN-1 KM. 341,000",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.81897,
    long: -2.77891,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. LA ROTXA-ANSOAIN",
    DOMICILIO: "C/ BERRIO PLANO Nº 2",
    PROVINCIA: "NAVARRA",
    CP: 31013,
    lat: 42.830671,
    long: -1.647001,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. ANSOAIN ARTICA",
    DOMICILIO: "CTRA. ARTICA ESQUINA C/AIZOAIN",
    PROVINCIA: "NAVARRA",
    CP: 31013,
    lat: 42.83194,
    long: -1.65104,
  },
  {
    BANDERA: "GALP",
    "NOMBRE TPV": "E.S. BERRIOPLANO",
    DOMICILIO: "CALLE MARINA BICARRET 3",
    PROVINCIA: "NAVARRA",
    CP: 31195,
    lat: 42.8332,
    long: -1.664532,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ARTICA",
    DOMICILIO: "CTRA. ARTICA S/N",
    PROVINCIA: "NAVARRA",
    CP: 31013,
    lat: 42.835508,
    long: -1.653073,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. BERRIOZAR",
    DOMICILIO: "AV DE GIPUZKOA ETORBIDEA",
    PROVINCIA: "NAVARRA",
    CP: 31013,
    lat: 42.8368,
    long: -1.67274,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.ARALAR, S.A.(IZDO)",
    DOMICILIO: "CTRA. GUIPUZCOA, KM. 5,5",
    PROVINCIA: "NAVARRA",
    CP: 31195,
    lat: 42.842957,
    long: -1.68179,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. TRADISNA-ARALAR I",
    DOMICILIO: "CTRA. GUIPUZCOA, KM. 5,5",
    PROVINCIA: "NAVARRA",
    CP: 31195,
    lat: 42.842957,
    long: -1.68179,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "ES. ARALAR, S.A.",
    DOMICILIO: "AVDA. GUIPUZCOA KM.5,5",
    PROVINCIA: "NAVARRA",
    CP: 31195,
    lat: 42.843325,
    long: -1.681589,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. TRADISNA-ARALLAR D",
    DOMICILIO: "AVDA. GUIPUZCOA KM.5,5",
    PROVINCIA: "NAVARRA",
    CP: 31195,
    lat: 42.843325,
    long: -1.681589,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S NOVOMILLADOIRO",
    DOMICILIO: "RUA DAS HEDRAS, 13",
    PROVINCIA: "LA CORUÑA",
    CP: 15895,
    lat: 42.849658,
    long: -8.582276,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA ESASA",
    DOMICILIO: "C/ REYES CATOLICOS, 2",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.85507,
    long: -2.67328,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP SALBURUA",
    DOMICILIO: "C/CUENCA DEL DEBA S/N",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.85612,
    long: -2.65703,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL BETOÑO",
    DOMICILIO: "C/ PORTAL DE BERGARA, Nº2",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.86497,
    long: -2.65297,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S PORTAL DE GAMARRA",
    DOMICILIO: "AVDA DEL CANTABRICO Nº 8",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.86539,
    long: -2.66126,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "E.S. BP GAMARRA",
    DOMICILIO: "PORTAL DE GAMARRA 7",
    PROVINCIA: "ALAVA",
    CP: "01013",
    lat: 42.8654,
    long: -5.66098,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. LOS ANGELES GAS",
    DOMICILIO: "RUA AMIO-P.G.COSTA VELLA, P-32",
    PROVINCIA: "LA CORUÑA",
    CP: 15890,
    lat: 42.90187,
    long: -8.51207,
  },
  {
    BANDERA: "VALCARCE",
    "NOMBRE TPV": "E.S. VALCARCE SANTIAGO",
    DOMICILIO: "P.I. DEL TAMBRE, VIA PASTEUR, 21-27",
    PROVINCIA: "LA CORUÑA",
    CP: 15890,
    lat: 42.910521,
    long: -8.518211,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. GALURESA TAMBRE",
    DOMICILIO: "VIA EDISON S/N (POL. IND. TAMBRE)",
    PROVINCIA: "LA CORUÑA",
    CP: 15890,
    lat: 42.911653,
    long: -8.513747,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA REINOSA",
    DOMICILIO: "A-67 SALIDA 133 -P.INDUSTRIAL DE LA VEGA",
    PROVINCIA: "CANTABRIA",
    CP: 39200,
    lat: 42.995194,
    long: -4.131333,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA REINOSA II",
    DOMICILIO: "AV. CANTABRIA 95",
    PROVINCIA: "CANTABRIA",
    CP: 39200,
    lat: 43.007078,
    long: -4.12888,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.ENEKURI",
    DOMICILIO: "CTRA.BILBAO-PLENCIA KM.5,5",
    PROVINCIA: "VIZCAYA",
    CP: 48950,
    lat: 43.01134,
    long: -2.94819,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CRED SAN RAFAEL",
    DOMICILIO: "AVDA.DE LA CORUÑA 310",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.0251,
    long: -7.56679,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "LOS CLAVELES',
    DOMICILIO: "CN-VI KM.514",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.034479,
    long: -7.574553,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "CEDIPSA LOS CLAVELES II",
    DOMICILIO: "CTRA. N-VI, PK 504 (MADRID-LA CORUÑA)",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.034626,
    long: -7.574191,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LUGO",
    DOMICILIO: "AVDA.BENIGNO RIVERA, 36B",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.038502,
    long: -7.573553,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "NEUMATICOS SOLEDAD S.L.",
    DOMICILIO: "PG. CEAO-RUA MERCADURIAS, 216",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.04548,
    long: -7.56141,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "A.S. LA CAMPIÑA S.L.",
    DOMICILIO: "RUA CANTEIROS, 20",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.05057,
    long: -7.57061,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S.GASOLEOS CEAO,S.L-II",
    DOMICILIO: "POL.IND. O CEAO, ACCESO AUTOVIA PARC.1-2",
    PROVINCIA: "LUGO",
    CP: 27003,
    lat: 43.052726,
    long: -7.564144,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. BERGARA",
    DOMICILIO: "C/TELLERIA 16/P.I. LABEGARAIETA PARC P48",
    PROVINCIA: "GUIPUZCOA",
    CP: 20570,
    lat: 43.099784,
    long: -2.427512,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. BERGARA",
    DOMICILIO: "POLG.INDUSTRIAL SAN LORENZO",
    PROVINCIA: "GUIPUZCOA",
    CP: 20570,
    lat: 43.12651,
    long: -2.42394,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. ABADIANO",
    DOMICILIO: "CTRA.FORAL BI-663 KM-33,2 -BARRIO EREÑA-",
    PROVINCIA: "VIZCAYA",
    CP: 48220,
    lat: 43.170575,
    long: -2.597528,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S IRURA",
    DOMICILIO: "CN I KM. 441",
    PROVINCIA: "GUIPUZCOA",
    CP: 20150,
    lat: 43.177223,
    long: -2.055122,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.BILLABONA, S.A.",
    DOMICILIO: "CN-I KM.445",
    PROVINCIA: "GUIPUZCOA",
    CP: 20150,
    lat: 43.179704,
    long: -2.05436,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. AZPEITIA",
    DOMICILIO: "PASEO DE ESKUSTA, S/N",
    PROVINCIA: "GUIPUZCOA",
    CP: 20730,
    lat: 43.189702,
    long: -2.258829,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. PARAYAS",
    DOMICILIO: "AVENIDA PARAYAS, KM 3",
    PROVINCIA: "CANTABRIA",
    CP: 39600,
    lat: 43.219419,
    long: -3.944129,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S.AMOREBIETA",
    DOMICILIO: "BARRIO BOROA S/N",
    PROVINCIA: "VIZCAYA",
    CP: 48340,
    lat: 43.228956,
    long: -2.753265,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.AMOREBIETA A-8 IZQ",
    DOMICILIO: "A-8 KM.100",
    PROVINCIA: "VIZCAYA",
    CP: 48340,
    lat: 43.230215,
    long: -2.753744,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S.AMOREBIETA A-8 DCH",
    DOMICILIO: "A-8 KM.100",
    PROVINCIA: "VIZCAYA",
    CP: 48340,
    lat: 43.231462,
    long: -2.756506,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. CRED MIERES",
    DOMICILIO: "POL. IND. VEGA DE ARRIBA, S/N",
    PROVINCIA: "ASTURIAS",
    CP: 33600,
    lat: 43.23474,
    long: -5.77913,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. ZUBIETA",
    DOMICILIO: "CTR A-1 KM 451",
    PROVINCIA: "GUIPUZCOA",
    CP: 20170,
    lat: 43.25291,
    long: -2.02193,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. MIERES",
    DOMICILIO: "BARRO ARROXO X/N",
    PROVINCIA: "ASTURIAS",
    CP: 33600,
    lat: 43.257887,
    long: -5.777186,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LASARTE-ORIA, S.A.",
    DOMICILIO: "ZIRKUITU IBILBIDEA, 8",
    PROVINCIA: "GUIPUZCOA",
    CP: 20160,
    lat: 43.258037,
    long: -2.018284,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. AVIA DE VEGA",
    DOMICILIO: "CA-142 KM 19,5",
    PROVINCIA: "CANTABRIA",
    CP: 39630,
    lat: 43.26355,
    long: -3.84985,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. JUAN MANTEROLA",
    DOMICILIO: "CN-634 KM.5,700",
    PROVINCIA: "GUIPUZCOA",
    CP: 20170,
    lat: 43.271461,
    long: -2.07049,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA OZAGAS",
    DOMICILIO: "AVENIDA FRAGA IRIBARNE, S/N",
    PROVINCIA: "LA CORUÑA",
    CP: 15300,
    lat: 43.275245,
    long: -8.216885,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. PIADELA FASE 3 S.L.",
    DOMICILIO: "PARCELA 19.2 P.I. PIADELA FASE 3",
    PROVINCIA: "LA CORUÑA",
    CP: 15300,
    lat: 43.275575,
    long: -8.239276,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.LAS CASCAS, S.L.",
    DOMICILIO: "AVDA.JESUS GARCIA NAVEIRA, 62",
    PROVINCIA: "LA CORUÑA",
    CP: 15300,
    lat: 43.275944,
    long: -8.213522,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. BUGALLO, S.L.",
    DOMICILIO: "CN-550 KM.10,800",
    PROVINCIA: "LA CORUÑA",
    CP: 15181,
    lat: 43.285003,
    long: -8.367491,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ERANDIO",
    DOMICILIO: "ALTO DE ENEKURI KM.5",
    PROVINCIA: "VIZCAYA",
    CP: 48950,
    lat: 43.286609,
    long: -2.958489,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. LA TELVA, S.L.",
    DOMICILIO: "CONFLUENCIA CTRAS. AC-220 Y AC-213",
    PROVINCIA: "LA CORUÑA",
    CP: 15181,
    lat: 43.294329,
    long: -8.360403,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL BILBAO",
    DOMICILIO: "CR FORAL BI-1035 LUTXANA-AXUA 36",
    PROVINCIA: "VIZCAYA",
    CP: 48950,
    lat: 43.303293,
    long: -2.952613,
  },
  {
    BANDERA: "PETRONOR",
    "NOMBRE TPV": "C.E.S. LOIU",
    DOMICILIO: "CTRA.ASUA-LOIU, 57",
    PROVINCIA: "VIZCAYA",
    CP: 48950,
    lat: 43.308022,
    long: -2.94369,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. LARRAMENDI S.L.",
    DOMICILIO: "NAFARRUA HIRIBIDEA, 5",
    PROVINCIA: "GUIPUZCOA",
    CP: 20013,
    lat: 43.326045,
    long: -1.970801,
  },
  {
    BANDERA: "MEROIL",
    "NOMBRE TPV": "E.S. CANDECAR, S.L.",
    DOMICILIO: "CTRA. DE BURGOS, KM.28-BAJO",
    PROVINCIA: "CANTABRIA",
    CP: 39679,
    lat: 43.327875,
    long: -3.967381,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. ELVIÑA",
    DOMICILIO: "AVDA.ALFONSO MOLINA S/N",
    PROVINCIA: "LA CORUÑA",
    CP: 15008,
    lat: 43.33355,
    long: -8.40218,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. LOS OCHOS",
    DOMICILIO: "AVDA. BILBAO, 117 SIERRA PANDO",
    PROVINCIA: "CANTABRIA",
    CP: 39300,
    lat: 43.341064,
    long: -4.029513,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. EL HAYA (DCHO)",
    DOMICILIO: "BARRIO DE ONTON S/N",
    PROVINCIA: "CANTABRIA",
    CP: 39700,
    lat: 43.341397,
    long: -3.154763,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EASYGAS CORUNA",
    DOMICILIO: "CAMINO MARTINETE, 52",
    PROVINCIA: "LA CORUÑA",
    CP: 15008,
    lat: 43.344077,
    long: -8.425911,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S.SAN CRISTOBAL",
    DOMICILIO: "AVENIDA DO FERROCARRIL, 123",
    PROVINCIA: "LA CORUÑA",
    CP: 15008,
    lat: 43.347316,
    long: -8.418826,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL A CORUÑA",
    DOMICILIO: "CI NEWTON P.I. LA GIRELA",
    PROVINCIA: "LA CORUÑA",
    CP: 15008,
    lat: 43.351699,
    long: -8.431832,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. CEDIPSA TORRELAVEGA",
    DOMICILIO: "C/ PABLO GARNICA 15",
    PROVINCIA: "CANTABRIA",
    CP: 39300,
    lat: 43.352139,
    long: -4.050667,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "A.S. TORRELAVEGA, S.L.",
    DOMICILIO: "AVDA. JULIO HAUZEUR, 6",
    PROVINCIA: "CANTABRIA",
    CP: 39300,
    lat: 43.352173,
    long: -4.057797,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "EE.SS. TORRES QUEVEDO",
    DOMICILIO: "TORRES QUEVEDO 30,31-LA GRELA",
    PROVINCIA: "LA CORUÑA",
    CP: 15008,
    lat: 43.354002,
    long: -8.425834,
  },
  {
    BANDERA: "BP",
    "NOMBRE TPV": "BP CASTRO URDIALES",
    DOMICILIO: "BARRIO BRAZOMAR S/N, S/N",
    PROVINCIA: "CANTABRIA",
    CP: 39700,
    lat: 43.368897,
    long: -3.212879,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. BARREDA, S.L.",
    DOMICILIO: "AVDA. SOLVAY, 85-BARREDA",
    PROVINCIA: "CANTABRIA",
    CP: 39300,
    lat: 43.369581,
    long: -4.042743,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": 'CEDIPSA "SAN CRISTOBAL',
    DOMICILIO: "PZA.RUIZ DE LA PEÑA,1",
    PROVINCIA: "ASTURIAS",
    CP: 33011,
    lat: 43.37107,
    long: -5.83753,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "ADELMA ESTACION",
    DOMICILIO: "Bº DE LAS BARRERAS, 17",
    PROVINCIA: "CANTABRIA",
    CP: 39716,
    lat: 43.388351,
    long: -3.687155,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "AREA DE SERVICIO TERMINO",
    DOMICILIO: "CN-634 KM.196",
    PROVINCIA: "CANTABRIA",
    CP: 39716,
    lat: 43.390001,
    long: -3.704503,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "PETRONORA",
    DOMICILIO: "AVDA DE LANGREO, S/N",
    PROVINCIA: "ASTURIAS",
    CP: 33180,
    lat: 43.390333,
    long: -5.70191,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL LA CORREDORIA",
    DOMICILIO: "CTRA. AS-18 OVIEDO-GIJON KM 2",
    PROVINCIA: "ASTURIAS",
    CP: 33011,
    lat: 43.392724,
    long: -5.818521,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "C.E.S. PUCHAR",
    DOMICILIO: "LA MAR S/N",
    PROVINCIA: "CANTABRIA",
    CP: 39750,
    lat: 43.39412,
    long: -3.459612,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LA CERRADA",
    DOMICILIO: "POL.IND. LA CERRADA, Nº32",
    PROVINCIA: "CANTABRIA",
    CP: 39600,
    lat: 43.412384,
    long: -3.836614,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S.CARBURANTES CUDON,SL",
    DOMICILIO: "AVDA. DE CANTABRIA 1495",
    PROVINCIA: "CANTABRIA",
    CP: 39318,
    lat: 43.413899,
    long: -4.011282,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. MURIEDAS",
    DOMICILIO: "AVDA. BURGOS, 56",
    PROVINCIA: "CANTABRIA",
    CP: 39600,
    lat: 43.419868,
    long: -3.858722,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S MURIEDAS",
    DOMICILIO: "AVDA. BILBAO, 8",
    PROVINCIA: "CANTABRIA",
    CP: 39600,
    lat: 43.422824,
    long: -3.85273,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL SANTANDER",
    DOMICILIO: "P.I. DE CROSS PARCELAS E-4 A E-25",
    PROVINCIA: "CANTABRIA",
    CP: 39600,
    lat: 43.425776,
    long: -3.841842,
  },
  {
    BANDERA: "AVIA",
    "NOMBRE TPV": "E.S. EL MOLINO",
    DOMICILIO: "POLIGONO INDUSTRIAL, Nº5",
    PROVINCIA: "CANTABRIA",
    CP: 39740,
    lat: 43.445597,
    long: -3.46022,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. -MERUELO-",
    DOMICILIO: "CTRA.BERANGA-NOJA KM.4,700",
    PROVINCIA: "CANTABRIA",
    CP: 39192,
    lat: 43.449394,
    long: -3.559267,
  },
  {
    BANDERA: "CAMPSA",
    "NOMBRE TPV": "E.S. SANTOÑA",
    DOMICILIO: "CTRA. CA-141 KM. 38",
    PROVINCIA: "CANTABRIA",
    CP: 39740,
    lat: 43.449963,
    long: -3.457029,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. CALLE CASTILLA",
    DOMICILIO: "CALLE CASTILLA, Nº91",
    PROVINCIA: "CANTABRIA",
    CP: 39009,
    lat: 43.453561,
    long: -3.823932,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S.VILLAVICIOSA ENERGIA",
    DOMICILIO: "CN-632, KM-43,05",
    PROVINCIA: "ASTURIAS",
    CP: 33300,
    lat: 43.488885,
    long: -5.444694,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EASYGAS GIJON",
    DOMICILIO: "NICOLAS COPERNICO 311 POLIG ROCES",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.508466,
    long: -5.689275,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. PUMARIN",
    DOMICILIO: "AVDA.CONSTITUCION, Nº220",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.52217,
    long: -5.68347,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S IDS-Q8 LLOREDA-GIJON",
    DOMICILIO: "CALLE JOAQUIN ALONSO DIAZ Nº 55",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.522444,
    long: -5.717528,
  },
  {
    BANDERA: "Q8",
    "NOMBRE TPV": "E.S. TREMAÑES",
    DOMICILIO: "AVDA. DE LA METALURGIA",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.525361,
    long: -5.711889,
  },
  {
    BANDERA: "LIBRE",
    "NOMBRE TPV": "E.S. EMPRESOIL, S.L.",
    DOMICILIO: 'POLIGONO INDUSTRIAL "I 3" PARC.I',
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.53166,
    long: -5.70308,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL TREMAÑES",
    DOMICILIO: "AVDA. LOS CAMPONES,  13 TREMAÑES",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.533833,
    long: -5.691288,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "C.E.S. LOS CAMPONES",
    DOMICILIO: "P.I.LOS CAMPONES PARC.1 INTERSECC. AS-19",
    PROVINCIA: "ASTURIAS",
    CP: 33211,
    lat: 43.53388,
    long: -5.70398,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. SHELL VILLALEGRE",
    DOMICILIO: "CL PEDRO VALDIVIA-C/C COLON S/N",
    PROVINCIA: "ASTURIAS",
    CP: 33400,
    lat: 43.537598,
    long: -5.906578,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. JOVE",
    DOMICILIO: "AVDA.PRINCIPE DE ASTURIAS S/N",
    PROVINCIA: "ASTURIAS",
    CP: 33212,
    lat: 43.543213,
    long: -5.695424,
  },
  {
    BANDERA: "SHELL",
    "NOMBRE TPV": "E.S. VAZQUEZ Y CASTRO",
    DOMICILIO: "BUENAVISTA (GRADO-AVILES) KM.25 Nº100",
    PROVINCIA: "ASTURIAS",
    CP: 33400,
    lat: 43.543375,
    long: -5.938108,
  },
  {
    BANDERA: "REPSOL",
    "NOMBRE TPV": "E.S. SALINAS",
    DOMICILIO: "AVDA. CAMPON-SALINAS, 16",
    PROVINCIA: "ASTURIAS",
    CP: 33400,
    lat: 43.571216,
    long: -5.959273,
  },
  {
    BANDERA: "CEPSA",
    "NOMBRE TPV": "E.S. PLAYA DE AREA",
    DOMICILIO: "CTRA LU-862 KM 79,8 - C/ ILLA GAVEIRA 51",
    PROVINCIA: "LUGO",
    CP: 27863,
    lat: 43.684884,
    long: -7.576911,
  },
];

export const favicon_size = {
  SHELL: { width: 40, height: 40 },
  AVIA: { width: 40, height: 80 },
  BP: { width: 50, height: 50 },
  CAMPSA: { width: 40, height: 80 },
  CEPSA: { width: 80, height: 40 },
  DISA: { width: 40, height: 80 },
  GALP: { width: 50, height: 50 },
  MEROIL: { width: 40, height: 80 },
  PCAN: { width: 40, height: 80 },
  PETROCAT: { width: 40, height: 80 },
  PETROMIRALLES: { width: 40, height: 80 },
  PETRONOR: { width: 40, height: 80 },
  Q8: { width: 40, height: 80 },
  REPSOL: { width: 50, height: 50 },
  TAMOIL: { width: 40, height: 80 },
  VALCARCE: { width: 40, height: 80 },
  LIBRE: { width: 40, height: 40 },
};

export const favicon = {
  SHELL:
    "https://cdn.icon-icons.com/icons2/2699/PNG/512/shell_logo_icon_168832.png",
  AVIA: "https://upload.wikimedia.org/wikipedia/commons/c/c0/AVIA_International_logo.svg",
  BP: "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/4ad36332-03b9-4804-aad7-acc8455a1109/d48a7vb-f8f3ad03-d9a4-4302-a47a-7d05b4ed9d54.png",
  CAMPSA: "./imgs/campsa.png",
  CEPSA: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Cepsa_Logo.png",
  DISA: "https://www.talantia.net/wp-content/uploads/2018/03/disa.png",
  GALP: "https://galp.com/es/img/galp-logo.png",
  MEROIL:
    "http://poolestaciones.es/wp-content/themes/bootstrap-basic-child/img/logo.png",
  PCAN: "https://freepngimg.com/download/map/66931-map-google-icons-maps-computer-marker-maker.png",
  PETROCAT: "https://www.baricentro.es/wp-content/uploads/sites/8/Petrocat.png",
  PETROMIRALLES:
    "https://freepngimg.com/download/map/66931-map-google-icons-maps-computer-marker-maker.png",
  PETRONOR: "https://petronor.eus/wp-content/uploads/2015/05/FondoVer1.jpg",
  Q8: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Q8_logo.png",
  REPSOL:
    "https://www.liderlogo.es/wp-content/uploads/2022/12/pasted-image-0-5-4.png",
  TAMOIL:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Tamoil.svg/1200px-Tamoil.svg.png",
  VALCARCE:
    "https://freepngimg.com/download/map/66931-map-google-icons-maps-computer-marker-maker.png",
  LIBRE:
    "https://freepngimg.com/download/map/66931-map-google-icons-maps-computer-marker-maker.png",
};
